import { io } from 'socket.io-client';
import JwtService from '../auth/services/jwtService';

/**
 * @typedef {('dashboard-room' | 'tracking-room' | 'bulk-loads-room')} RoomType
 * @typedef {('connect' | 'disconnect' | 'reconnect' | 'error' | 'message' | 'workspace-updated' | 'user-joined' | 'user-left' | 'assignment-done' | 'checkinout-change' | 'client-served' | 'location-update' | 'total-stats-updated' | 'csv-load-update')} SocketEventType
 */

/**
 * Socket class to manage the socket connection
 */
class Socket {
  /**
   * @type {Socket}
   * static instance of the socket
   */
  static instance = new Socket();

  socket;

  /** create the socket instance */
  constructor() {
    if (Socket.instance) {
      return Socket.instance;
    }
    Socket.instance = this;
  }

  /**
   * connect from the socket, to the data of a workspace
   * @param {string} shortid the workspace id
   * @param {string} userId the user id
   * @returns {Promise} the promise of the connection
   * */
  connectWorkspace(shortid, userId) {
    return new Promise((resolve, reject) => {
      this.disconnectWorkspace();

      const token = JwtService.getCredentials().access;

      this.socket = io(`${process.env.REACT_APP_WEBSOCKED_HOST}/ws-${shortid}`, {
        auth: {
          userId,
          token,
        },
        transports: ['websocket'],
      });
      this.socket.connect();

      this.socket.on('connect', resolve);
      this.socket.on('connect_error', reject);
      this.socket.on('disconnect', () => console.log('Disconnect Socket'));
    });
  }

  /** disconnect the socket of a workspace */
  disconnectWorkspace() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket.off('disconnect', () => console.log('Disconnect Socket'));

      this.socket.off('connect', () => console.log('Connect Socket'));

      this.socket = null;
    }
  }

  /**
   * join a room
   * @param {(RoomType} roomType the type of the room to join
   * */
  joinRoom(roomType) {
    this.socket.emit('join-room', roomType);
  }

  /** leave a room
   * @param {RoomType} roomType the type of the room to leave
   */
  leaveRoom(roomType) {
    this.socket.emit('leave-room', roomType);
  }

  /** add a listener to the socket
   * @param {SocketEventType} listener the type of the listener
   * @param {Function} callback the callback to be executed
   */
  addListener(listener, callback = () => {}) {
    this.socket.on(listener, callback);
  }

  /** remove a listener from the socket
   * @param {SocketEventType} listener the type of the listener
   * @param {Function} callback the callback to be removed
   */
  removeListener(listener, callback = () => {}) {
    this.socket.off(listener, callback);
  }

  /**
   * @returns {boolean} true if the socket is connected, false otherwise
   */
  isConnected() {
    return this.socket?.connected;
  }
}

/**
 * Socket instance
 * @module
 */
export default Socket.instance;
