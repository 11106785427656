const mainApi = process.env.REACT_APP_API_HOST;

const ApiRoutes = {
  login: {
    getToken: `${mainApi}/api/auth/user/`,
    getUserInfo: `${mainApi}/api/v1/users/my-data/`,
    registerUser: `${mainApi}/api/auth/register/`,
  },

  syncHistoryEndPoint: (ws, params, v = 1) => {
    const url = `${mainApi}/api/v${v}/workspaces/${ws}/sync-history/`;
    let urlParams = '';
    if (params) {
      urlParams += '?';

      if (params?.page) {
        urlParams += `page=${params.page}&`;
      }

      if (params.user) {
        urlParams += `user=${params.user}&`;
      }

      if (params.version) {
        urlParams += `version=${params.version}&`;
      }

      if (params.date_sync__gte) {
        urlParams += `date_sync__gte=${params.date_sync__gte}&`;
      }

      if (params.date_sync__lte) {
        urlParams += `date_sync__lte=${params.date_sync__lte}&`;
      }
    }

    return url + urlParams;
  },

  notificationsEndPoint: (ws, params, v = 1) => {
    const url = `${mainApi}/api/v${v}/workspaces/${ws}/notifications/`;
    let urlParams = '';
    if (params) {
      urlParams += '?';

      if (params.date_created__gte) {
        urlParams += `date_created__gte=${params.date_created__gte}&`;
      }

      if (params.date_created__lte) {
        urlParams += `date_created__lte=${params.date_created__lte}&`;
      }
      if (params.type) {
        urlParams += `type=${params.type}&`;
      }

      if (params.level) {
        urlParams += `level=${params.level}&`;
      }
    }

    return url + urlParams;
  },
  applicationsEndPoint: (ws, params, v = 1) => {
    const url = `${mainApi}/api/v${v}/workspaces/${ws}/applications/`;
    let urlParams = '';
    if (params) {
      urlParams += '?';

      if (params?.page) {
        urlParams += `page=${params.page}&`;
      }

      if (params.user) {
        urlParams += `user=${params.user}&`;
      }

      if (params.version) {
        urlParams += `version=${params.version}&`;
      }

      if (params.date_created__gte) {
        urlParams += `date_created__gte=${params.date_created__gte}&`;
      }

      if (params.date_created__lte) {
        urlParams += `date_created__lte=${params.date_created__lte}&`;
      }
    }

    return url + urlParams;
  },
  assignmentsEndPoint: (ws, params, v = 1, fileType) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/assignments/`;
    let urlParams = '';

    if (params) {
      urlParams += '?';

      if (params?.page) {
        urlParams += `&page=${params.page}`;
      }
      if (params?.search) {
        urlParams += `&search=${params.search}`;
      }

      if (params.date_created_gte) {
        urlParams += `&date_created__gte=${params.date_created_gte}`;
      }

      if (params.date_created_lte) {
        urlParams += `&date_created__lte=${params.date_created_lte}`;
      }

      if (params.date_status_gte) {
        urlParams += `&date_status__gte=${params.date_status_gte}`;
      }

      if (params.date_status_lte) {
        urlParams += `&date_status__lte=${params.date_status_lte}`;
      }

      if (params.date_due_lte) {
        urlParams += `&date_due__lte=${params.date_due_lte}`;
      }

      if (params.date_status_lte) {
        urlParams += `&date_status__lte=${params.date_status_lte}`;
      }

      if (params?.status) {
        urlParams += `&status=${params.status}`;
      }

      if (params?.client) {
        urlParams += `&client=${params.client}`;
      }

      if (params?.project) {
        urlParams += `&project=${params.project}`;
      }

      if (params?.user) {
        urlParams += `&user=${params.user}`;
      }

      if (params?.team) {
        urlParams += `&team=${params.team}`;
      }

      if (params?.bookmark) {
        urlParams += `&bookmark=${params.bookmark}`;
      }

      if (params?.tags) {
        params?.tags.split(',').forEach((tag) => {
          urlParams += `&tags=${tag}`;
        });
      }

      if (params?.forms) {
        params?.forms.split(',').forEach((forms) => {
          urlParams += `&forms=${forms}`;
        });
      }
    }

    if (fileType) {
      url += `download/${fileType}/`;
    }

    return url + urlParams;
  },
  usersLocationEndPoint: (ws, params, v = 1, suffixType) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/user-location/`;

    const urlParams = '';

    // if (params) {

    // }

    if (suffixType) {
      if (suffixType.listMap) {
        url += `list-for-map/`;
      }
      if (suffixType.fileType) {
        url += `download/${suffixType.fileType}/`;
      }
    }

    return url + urlParams;
  },
  getAssignmentsLocation: (
    ws,
    { dateType = 'date_created', start, end, user, client, tags }
  ) => {
    let url = `${mainApi}/api/v1/workspaces/${ws}/assignments/list-for-map/?status=done`;

    if (start) {
      url += `&${dateType}__gte=${start.toISOString()}`;
    }

    if (end) {
      url += `&${dateType}__lte=${end.toISOString()}`;
    }

    if (client) {
      url += `&client=${client}`;
    }

    if (user) {
      url += `&user=${user}`;
    }

    if (tags?.length > 0) {
      tags.forEach((tagId) => {
        url += `&tags=${tagId}`;
      });
    }

    return url;
  },
  getUserLocationDetail: (ws, user) =>
    `${mainApi}/api/v1/workspaces/${ws}/user-location/${user}/detail-for-map/`,
  userLocationTrackingEndPoint: (ws, params, v = 1, suffixType) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/user-location-register/`;

    let urlParams = '';

    if (params) {
      urlParams += '?';

      if (params.user) {
        urlParams += `&user=${params.user}`;
      }

      if (params.start) {
        urlParams += `&date_coords__gte=${params.start.toISOString()}`;
      }

      if (params.end) {
        urlParams += `&date_coords__lte=${params.end.toISOString()}`;
      }
    }

    if (suffixType) {
      if (suffixType.listMap) {
        url += `list-for-map/`;
      }
      if (suffixType.fileType) {
        url += `download/${suffixType.fileType}/`;
      }
    }

    return url + urlParams;
  },
  checkInOutLocationsEndPoint: (ws, params, v = 1, suffixType) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/checkinout/`;

    let urlParams = '';

    if (params) {
      urlParams += '?';
      if (params.start) {
        urlParams += `&date_created__gte=${params.start.toISOString()}`;
      }

      if (params.end) {
        urlParams += `&date_created__lte=${params.end.toISOString()}`;
      }

      if (params.user) {
        urlParams += `&user=${params.user}`;
      }

      if (params.tags?.length > 0) {
        params.tags.forEach((tagId) => {
          urlParams += `&tags=${tagId}`;
        });
      }
    }

    if (suffixType) {
      if (suffixType.listMap) {
        url += `list-for-map/`;
      }
      if (suffixType.fileType) {
        url += `download/${suffixType.fileType}/`;
      }
    }

    return url + urlParams;
  },
  getVehiclesLocation: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/gps-devices/all/`,
  getGeofencesLocation: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/geo-fence/all/`,

  getAllGeofencesBasicInfo: (ws) =>
    `${mainApi}/api/v2/workspaces/${ws}/geofences/all-basic-info/`,
  geofenceEndPoint: (ws, params, v = 1, fileType) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/geofences/`;
    let urlParams = '';

    if (params) {
      urlParams += '?';

      if (params?.page) {
        urlParams += `&page=${params.page}`;
      }
    }

    if (fileType) {
      url += `download/${fileType}/`;
    }

    return url + urlParams;
  },
  getGeofenceAlerts: (ws, geofence) =>
    `${mainApi}/api/v2/workspaces/${ws}/alerts/all-by-geofence/?geofences=${geofence}`,
  getMyPermissions: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/user-permissions/mine/`,
  searchGeofence: (ws, search) =>
    `${mainApi}/api/v2/workspaces/${ws}/geofence/all/?search=${search.replace(
      ' ',
      '+'
    )}`,
  searchUsers: (ws, search) =>
    `${mainApi}/api/v1/workspaces/${ws}/user-location/all-search/?search=${search.replace(
      ' ',
      '+'
    )}`,
  projectsEndPoint: (ws, params) => {
    let urlParams = '';
    if (params?.page || params?.search) {
      urlParams += '?';
    }

    if (params?.page) {
      urlParams += `page=${params.page}&`;
    }
    if (params?.search) {
      urlParams += `search=${params.search}`;
    }
    return `${mainApi}/api/v1/workspaces/${ws}/projects/${urlParams}`;
  },
  getAllAlertsByType: (ws, type) =>
    `${mainApi}/api/v2/workspaces/${ws}/alerts/all-by-type/?type=${type}`,
  alertsEndPoint: (ws, params) => {
    let urlParams = '';

    if (params) {
      urlParams += '?';

      if (params.page) {
        urlParams += `page=${params.page}&`;
      }
      if (params.search) {
        urlParams += `search=${params.search}&`;
      }

      if (params.date_created__gte) {
        urlParams += `date_created__gte=${params.date_created__gte}&`;
      }

      if (params.date_created__lte) {
        urlParams += `date_created__lte=${params.date_created__lte}&`;
      }
      if (params.type) {
        urlParams += `type=${params.type}&`;
      }

      if (params?.active !== null && params?.active !== undefined) {
        urlParams += `active=${params.active}&`;
      }

      if (
        params.apply_all_users !== null &&
        params.apply_all_users !== undefined
      ) {
        urlParams += `apply_all_users=${params.apply_all_users}&`;
      }

      if (params?.users) {
        params?.users.split(',').forEach((user) => {
          urlParams += `&users=${user}`;
        });
      }
    }
    return `${mainApi}/api/v2/workspaces/${ws}/alerts/${urlParams}`;
  },
  usersEndPoint: (ws, params, v = 1, fileType) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/users/`;
    let urlParams = '';

    if (params) {
      urlParams += '?';
      if (params.client_id) {
        urlParams += `&client_id=${params.client_id}`;
      }
    }

    if (fileType) {
      url += `download/${fileType}/`;
    }

    return url + urlParams;
  },
  teamsEndPoint: (ws, params, v = 1, fileType) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/teams/`;
    const urlParams = '';

    // if (params) {
    // }

    if (fileType) {
      url += `download/${fileType}/`;
    }

    return url + urlParams;
  },
  tagsEndPoint: (ws, params) => {
    let urlParams = '';

    if (params?.page) {
      urlParams += `?page=${params.page}&`;
    }

    return `${mainApi}/api/v1/workspaces/${ws}/tags/${urlParams}`;
  },
  clientsEndPoint: (ws, params, v = 1, fileType) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/clients/`;
    let urlParams = '';

    if (params) {
      if (params?.seller) {
        return `${url}for-seller/?seller=${params.seller}`;
      }

      urlParams = '?approved=true&web-version=2.0.0';

      if (params?.page) {
        urlParams += `&page=${params.page}`;
      }
      if (params?.search) {
        urlParams += `&search=${params.search}`;
      }

      if (params.date_created__gte) {
        urlParams += `&date_created__gte=${params.date_created__gte}`;
      }

      if (params.date_created__lte) {
        urlParams += `&date_created__lte=${params.date_created__lte}`;
      }

      if (params?.teams) {
        params?.teams.split(',').forEach((team) => {
          urlParams += `&teams=${team}`;
        });
      }

      if (params?.sellers) {
        params?.sellers.split(',').forEach((seller) => {
          urlParams += `&seller=${seller}`;
        });
      }
    }

    if (fileType) {
      url += `download/${fileType}/`;
    }

    return url + urlParams;
  },
  csvLoadsEndPoint: (ws, params, csvLoadId) => {
    if (csvLoadId) {
      return `${mainApi}/api/v1/workspaces/${ws}/csv-load/${csvLoadId}/`;
    }

    let urlParams = '?';

    if (params?.page) {
      urlParams += `&page=${params.page}`;
    }

    return `${mainApi}/api/v1/workspaces/${ws}/csv-load/${urlParams}`;
  },
  csvLoadFile: (ws) => {
    return `${mainApi}/api/v1/workspaces/${ws}/load-csv-file/`;
  },
  clientValidateUniqueFields: (ws, client) => {
    let urlParams = `?name=${client.name}`;
    if (client.internal_code) {
      urlParams += `&internal_code=${client.internal_code}`;
    }

    if (client.invoice_tax_identifier) {
      urlParams += `&invoice_tax_identifier=${client.invoice_tax_identifier}`;
    }

    if (client.id) {
      urlParams += `&client_id=${client.id}`;
    }
    return `${mainApi}/api/v1/workspaces/${ws}/clients/exists-name/${urlParams}`;
  },
  getClientsLocation: (ws, params) => {
    let urlParams = '?';
    if (params.withCoords !== undefined) {
      urlParams += `&with-coords=${params.withCoords}`;
    }

    if (params.page !== undefined) {
      urlParams += `&page=${params.page}`;
    }

    return `${mainApi}/api/v1/workspaces/${ws}/clients/list-for-map/${urlParams}`;
  },

  getClientsCount: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/clients/location-count/`,
  countriesEndPoint: () => `${mainApi}/api/v1/country/all/`,
  timeZonesEndPoint: () => `${mainApi}/api/v1/timezones/all/`,
  rolesListAll: (ws) => `${mainApi}/api/v1/workspaces/${ws}/roles/`,
  workspaceEndPoint: () => `${mainApi}/api/v1/workspaces/`,
  getWorkspaceSettings: (ws) => `${mainApi}/api/v1/workspaces/${ws}/settings/`,
  updateWorkspaceSettings: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/settings-update/`,
  organizationEndPoint: () => `${mainApi}/api/v1/organizations/`,
  getOrganizationBilling: (org) =>
    `${mainApi}/api/v1/organizations/${org}/get-billing/`,
  getOrganizationContacts: (org) =>
    `${mainApi}/api/v1/organizations/${org}/get-contacts/`,
  getOrganizationUsers: (org) =>
    `${mainApi}/api/v1/organizations/${org}/users/`,
  setOrganizationBilling: (org, billing) =>
    `${mainApi}/api/v1/organizations/${org}/billing-info/${billing}/`,
  setOrganizationContact: (org, contact) =>
    `${mainApi}/api/v1/organizations/${org}/contacts/${contact}/`,
  getUserRole: (ws, id) =>
    `${mainApi}/api/v1/workspaces/${ws}/roles/user-roles/?user=${id}`,
  setUserRole: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/roles/update-user-roles/`,
  updateUser: (ws, id) =>
    `${mainApi}/api/v1/workspaces/${ws}/user-update/${id}/`,
  checkUser: (ws, email) =>
    `${mainApi}/api/v1/workspaces/${ws}/user-exists/?email=${email}`,
  AddUser: (ws) => `${mainApi}/api/v1/workspaces/${ws}/add-user-to-workspace/`,
  inviteUser: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/invite-user-to-workspace/`,
  removeUser: (ws) => `${mainApi}/api/v1/workspaces/${ws}/user-remove/`,
  rolesEndPoint: (ws) => `${mainApi}/api/v1/workspaces/${ws}/roles/`,
  permissionsEndPoint: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/permissions/`,
  setSuperUser: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/user-permissions/set-superuser/`,
  getSuperUsers: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/user-permissions/all-superusers/`,
  changePassword: (ws) =>
    `${mainApi}/api/v1/workspaces/${ws}/change-other-user-password/`,
  changeMyPassword: () => `${mainApi}/api/v1/users/change-password/`,
  confirmCodeWS: () => `${mainApi}/api/v1/confirm-invite-code/`,
  setLanguaje: () => `${mainApi}/api/v1/users/set-language/`,
  eventsEndPoint: (ws, params, v = 1, { all, fileType } = {}) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/events/`;
    let urlParams = '';

    if (params) {
      urlParams += '?';

      if (params?.page) {
        urlParams += `&page=${params.page}`;
      }

      if (params.type) {
        urlParams += `&type=${params.type}`;
      }

      if (params.user) {
        urlParams += `&user=${params.user}`;
      }

      if (params.team) {
        urlParams += `&team=${params.team}`;
      }

      if (params.date_created__gte) {
        urlParams += `&date_created__gte=${params.date_created__gte}`;
      }

      if (params.date_created__lte) {
        urlParams += `&date_created__lte=${params.date_created__lte}`;
      }

      if (params.user) {
        urlParams += `&user=${params.user}`;
      }
    }

    if (all) {
      url += 'all/';
    }

    if (fileType) {
      url += `download/${fileType}/`;
    }

    return url + urlParams;
  },
  dailyRecordEndPoint: (ws, params, v = 1, fileType) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/user-location-daily-record/`;
    let urlParams = '';

    if (params) {
      urlParams += '?';

      if (params?.page) {
        urlParams += `&page=${params.page}`;
      }
      if (params.end) {
        urlParams += `&date__lte=${params.end.toISOString()}`;
      }

      if (params.start) {
        urlParams += `&date__gte=${params.start.toISOString()}`;
      }

      if (params.user) {
        urlParams += `&user=${params.user}`;
      }

      if (params.team) {
        urlParams += `&team=${params.team}`;
      }

      if (params.geofence) {
        urlParams += `&geofence=${params.geofence}`;
      }
    }

    if (fileType) {
      url += `download/${fileType}/`;
    }

    return url + urlParams;
  },
  geofenceRecordEndPoint: (ws, params, v = 1, fileType) => {
    let url = `${mainApi}/api/v${v}/workspaces/${ws}/geofence-record/`;
    let urlParams = '';

    if (params) {
      urlParams += '?';

      if (params?.page) {
        urlParams += `&page=${params.page}`;
      }
      if (params.end) {
        urlParams += `&date_created__lte=${params.end.toISOString()}`;
      }

      if (params.start) {
        urlParams += `&date_created__gte=${params.start.toISOString()}`;
      }

      if (params.user) {
        urlParams += `&user=${params.user}`;
      }

      if (params.team) {
        urlParams += `&team=${params.team}`;
      }

      if (params.geofence) {
        urlParams += `&geofence=${params.geofence}`;
      }
    }

    if (fileType) {
      url += `download/${fileType}/`;
    }

    return url + urlParams;
  },
  createGeofence: (ws) => `${mainApi}/api/v2/workspaces/${ws}/geofence-create/`,
  updateGeofence: (ws, geofenceId) =>
    `${mainApi}/api/v2/workspaces/${ws}/geofence-update/${geofenceId}/`,
  placesEndPoint: (ws) => `${mainApi}/api/v1/workspaces/${ws}/places/`,
  dashboardData: (ws, params) => {
    if (params?.date) {
      return `${mainApi}/api/v1/workspaces/${ws}/statistics/${params.date}/date/`;
    }
    return `${mainApi}/api/v1/workspaces/${ws}/statistics/current/`;
  },
  dashboardTimeline: (ws, params) => {
    let url = `${mainApi}/api/v1/workspaces/${ws}/statistics/`;
    if (params?.page) {
      url += `?page=${params.page}`;
    }
    return url;
  },

  assignmentsListForMap: (ws, params) => {
    if (params?.date)
      return `${mainApi}/api/v1/workspaces/${ws}/assignments/list-for-map-given-day/?date=${params.date}`;
    return `${mainApi}/api/v1/workspaces/${ws}/assignments/list-for-map-current-day/`;
  },
  checkInOutListForMap: (ws, params) => {
    if (params?.date)
      return `${mainApi}/api/v1/workspaces/${ws}/checkinout/list-for-map-given-day/?date=${params.date}`;
    return `${mainApi}/api/v1/workspaces/${ws}/checkinout/list-for-map-current-day/`;
  },
  clientsListForMap: (ws, params) => {
    if (params?.date)
      return `${mainApi}/api/v1/workspaces/${ws}/clients/list-for-map-given-day/?date=${params.date}`;
    return `${mainApi}/api/v1/workspaces/${ws}/clients/list-for-map-current-day/`;
  },
  formsEndPoint: (ws, params) => {
    let urlParams = '';

    if (params) {
      urlParams += '?';

      if (params.noPagination) {
        return `${mainApi}/api/v1/workspaces/${ws}/forms/all-list`;
      }

      if (params.page) {
        urlParams += `page=${params.page}&`;
      }
      if (params.search) {
        urlParams += `search=${params.search}&`;
      }
    }

    return `${mainApi}/api/v1/workspaces/${ws}/forms/${urlParams}`;
  },
};

export default ApiRoutes;
