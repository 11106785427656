/* eslint-disable camelcase */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

const csvLoadsAdapter = createEntityAdapter({});

export const { selectAll: selectCsvLoads, selectById: selectCsvLoadById } =
  csvLoadsAdapter.getSelectors(({ managementApp }) => managementApp.csvLoads);

export const selectCsvLoadData = ({ managementApp }) => managementApp.csvLoads;

export const selectCsvLoadErrorsProps = ({ managementApp }) =>
  managementApp.csvLoads.csvLoadErrorsModal;

export const selectCsvLoadDocsProps = ({ managementApp }) =>
  managementApp.csvLoads.csvLoadDocsModal;

const initialState = csvLoadsAdapter.getInitialState({
  numOfPages: 0,
  count: 0,
  page: 1,
  isLoading: true,
  csvLoadErrorsModal: {
    open: false,
  },
  csvLoadDocsModal: {
    open: false,
  },
});

/**
 * get csv loads from the server
 */
export const getCsvLoads = createAsyncThunk(
  'managementApp/csvLoads/getCsvLoads',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.paramsApi - the api params
   */
  async ({ wsId, paramsApi }, { dispatch, getState }) => {
    dispatch(setIsLoading(true));

    const response = await axios.get(ApiRoutes.csvLoadsEndPoint(wsId, paramsApi));

    dispatch(setIsLoading(false));
    dispatch(setNumOfPages(Math.ceil(response.data.count / 100 || 0)));
    dispatch(setCount(response.data.count));

    const data = await response.data.results;

    return data;
  }
);

/**
 * get the csv load errors
 * @param {object} payload
 * @param {object} payload.csvLoad - the csv load object
 */
export const getCsvLoadErrors = createAsyncThunk(
  'managementApp/csvLoads/getCsvLoadErrors',
  async ({ csvLoad }, { rejectWithValue, dispatch, getState }) => {
    const response = await axios.get(csvLoad.file_error_url, {
      transformRequest: (data, headers) => {
        delete headers.Authorization;
        return data;
      },
    });

    return { id: csvLoad.id, changes: { errors: response.data } };
  }
);

/**
 * set the csv load
 */
export const setCsvLoad = createAsyncThunk(
  'managementApp/csvLoads/setCsvLoad',
  /**
   * @param {object} data - the csv load object
   */
  async (data) => {
    return { id: data.id, changes: data };
  }
);

/**
 * load a csv file
 */
export const addCsvLoad = createAsyncThunk(
  'managementApp/csvLoads/addCsvLoads',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.csvLoad - the csv load object
   */
  async ({ wsId, csvLoad }, { rejectWithValue, dispatch, getState }) => {
    const csvData = new FormData();
    csvData.append('id', csvLoad.id);
    csvData.append('type', csvLoad.type);
    csvData.append('file', csvLoad.file_data.file);
    try {
      const { data } = await axios({
        method: 'post',
        url: ApiRoutes.csvLoadFile(wsId),
        data: csvData,
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });

      const { entities } = getState().managementApp.csvLoads;
      const CsvLoads = Object.keys(entities).map((key) => entities[key]);

      return [data, ...CsvLoads];
    } catch (e) {
      if (e.response?.data?.error) {
        const strErrors = Object.entries(e.response.data.error)
          .map(([key, text]) => `${key}:${text} \n`)
          .toString();
        await dispatch(showMessage({ message: strErrors, variant: 'error' }));
      }

      throw rejectWithValue(e);
    }
  }
);

const csvLoadsSlice = createSlice({
  name: 'managementApp/csvLoads',
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCsvLoadErrorsModalProps: (state, action) => {
      state.csvLoadErrorsModal = { ...state.csvLoadErrorsModal, ...action.payload };
    },
    setCsvLoadDocsModalProps: (state, action) => {
      state.csvLoadDocsModal = { ...state.csvLoadDocsModal, ...action.payload };
    },
    resetCsvLoadsState: () => initialState,
  },
  extraReducers: {
    [getCsvLoads.fulfilled]: csvLoadsAdapter.setAll,
    [getCsvLoadErrors.fulfilled]: csvLoadsAdapter.updateOne,
    [addCsvLoad.fulfilled]: csvLoadsAdapter.setAll,
    [setCsvLoad.fulfilled]: csvLoadsAdapter.updateOne,
  },
});

export const selectSelectedCsvLoad = ({ managementApp }) =>
  managementApp.csvLoads.selectedCsvLoadId;

export const {
  setNumOfPages,
  setIsLoading,
  setPage,
  resetCsvLoadsState,
  setCount,
  setCsvLoadErrorsModalProps,
  setCsvLoadDocsModalProps,
} = csvLoadsSlice.actions;

export default csvLoadsSlice.reducer;
