import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  formSelector,
  removeForm,
  selectFormById,
  setDeleteModalProps,
} from '../../store/formsSlice';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

/**
 * modal to confirm the deletion of a form
 */
function DeleteForm() {
  /**
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {function} navigate - the function to navigate to another page
   * @constant {object} formIdURL - the form id from the url
   * @constant {object} wsId - the workspace id
   * @constant {function} t - the translation function
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId: formIdURL, wsId } = useParams();
  const { t } = useTranslation('assignmentsApp');

  /**
   * @constant {object} deleteModalProps - the props of the modal
   */
  const {
    deleteModalProps: { formId, open },
  } = useSelector(formSelector);

  /** form to delete */
  const form = useSelector((state) => selectFormById(state, formId));

  /** close the modal */
  const closeDeleteModal = () => dispatch(setDeleteModalProps({ open: false, formId: null }));

  /** delete the form and close the modal */
  const deleteForm = async () => {
    await dispatch(removeForm({ wsId, form }));
    closeDeleteModal();
    if (formIdURL) {
      navigate(`/ws/${wsId}/forms${window.location.search}`, { replace: true });
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <Typography variant="subtitle1" className="mb-5 text-justify">
          {t('ARE_SURE_DELETE_FORM', { name: form?.name })}
        </Typography>
        <div className="flex justify-end">
          <Button onClick={closeDeleteModal} className="flex-auto mx-2">
            {t('CANCEL')}
          </Button>

          <Button
            className="flex-auto mx-2"
            color="secondary"
            variant="contained"
            onClick={deleteForm}
          >
            {t('DELETE')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default DeleteForm;
