import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import { selectProjectById } from '../projectsSlice';

/**
 * ProjectChip component to display a project chip from select data
 * @param {object} props
 * @param { String } props.id - the id of the project chip
 * @param { ('small'|'medium'|'large') } props.size - the size of the chip
 * @param { Object } props.chipProps - the props of the chip (MuiChip)
 * @param { String } props.className - the class name of the chip(TailwindCss)
 */
const ProjectChip = ({ id, chipProps, size = 'medium' }) => {
  const project = useSelector((state) => selectProjectById(state, id));

  return (
    <Chip
      {...chipProps}
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        '& .MuiChip-deleteIcon': {
          color: theme.palette.secondary.contrastText,
          fontSize: 15,
        },
      })}
      variant="outlined"
      label={project?.name}
      id={id}
      key={id}
      size={size}
    />
  );
};

export default ProjectChip;
