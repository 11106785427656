import ApiRoutes from 'src/app/services/ApiRoutes';
import axios from 'axios';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';

const AssignmentsAdapter = createEntityAdapter({});

export const selectAssignmentsData = ({ LocationApp }) => LocationApp.Assignments;

export const { selectAll: selectAssignmentLists } =
  AssignmentsAdapter.getSelectors(selectAssignmentsData);

const initialState = AssignmentsAdapter.getInitialState({});

/**
 * get the list of assignments to show in the map
 */
export const getAssignmentLocationList = createAsyncThunk(
  'LocationApp/MapData/Assignments/getAssignmentLocationList',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   * @param {object} payload.params - the params to filter the assignments
   */
  async ({ ws, params }, { dispatch, getState }) => {
    const { data } = await axios.get(ApiRoutes.getAssignmentsLocation(ws, params));

    if (data.length === 0)
      dispatch(showMessage({ message: t('locationApp:NO_ASSIGNMENTS_FOUND'), variant: 'warning' }));

    return data.map((e) => ({ ...e, typeMarker: 'Assignment', visible: true }));
  }
);

/** delete an assignment from the map */
export const deleteAssignment = createAsyncThunk(
  'LocationApp/MapData/Assignments/deleteAssignment',
  /**
   * @param {string} id - the id of the assignment to delete
   */
  async (id, { dispatch, getState }) => id
);

const AssignmentSlice = createSlice({
  name: 'LocationApp/MapData/Assignments',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVisibleAssignment: (state, action) => {
      const { assignmentId, visible } = action.payload;
      state.entities[assignmentId].visible = visible;
    },
    logicalDeleteAssignment: (state, action) => {
      state.entities[action.payload].delete = true;
    },
    resetAssignmentState: () => initialState,
  },
  extraReducers: {
    [getAssignmentLocationList.fulfilled]: AssignmentsAdapter.upsertMany,
    [deleteAssignment.fulfilled]: AssignmentsAdapter.removeOne,
  },
});

export const { setLoading, logicalDeleteAssignment, setVisibleAssignment, resetAssignmentState } =
  AssignmentSlice.actions;

const { reducer } = AssignmentSlice;

export default reducer;
