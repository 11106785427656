/** this file will contain the translations in spanish for the organizationsApp page */
const locale = {
  ADDRESS: 'Dirección',
  ADDRESS_LINE_ONE: 'Calle, avenida, zona, región',
  ADDRESS_LINE_TWO: 'Colonia, kilometro, etc',
  ADMINISTRATOR_CONTACT: 'Contacto del administrador',
  BILLING: 'Facturación',
  BILLING_CONTACT: 'Contacto de facturación',
  CHANGES_SAVED: 'Cambios guardados',
  CITY: 'Ciudad',
  COMPANY_LEGAL_NAME: 'Nombre legal',
  CONTACT_LAST_NAME: 'Apellido',
  CONTACT_NAME: 'Nombre',
  CONTACTS: 'Contactos',
  COUNTRY: 'País',
  CREATE_WORKSPACE: 'Crear Escritorio',
  CREATION_DATE: 'Fecha de creación',
  DESCRIPTION: 'Descripción',
  EDITION_DATE: 'Fecha de edición',
  EMAIL: 'E-mail',
  EMAIL_CANNOT_EMPTY: 'El campo e-mail no puede estar vació',
  EMAIL_VALID: 'Ingrese un correo electrónico válido',
  HAVE_LEFT_ORG: 'Has abandonado "{{name}}"',
  IMAGE_URL: 'URL del logo',
  INFORMATION: 'Información',
  INVALID_IMAGEN_URL: 'URL de la imagen no valida',
  LAST_NAME_CANNOT_EMPTY: 'El campo apellido no puede estar vació',
  LEGAL_ADDRESS: 'Dirección legal',
  NAME: 'Nombre',
  NAME_CANNOT_EMPTY: 'El campo nombre no puede estar vació',
  NO_ORG_FOUND: 'No se encontraron organizaciones',
  NO_WS_FOUND: 'Crea un Escritorio',
  PHONE: 'Teléfono',
  ROLE_ORG_ADMIN: 'Administrador',
  ROLE_ORG_READ: 'Lector',
  ROLE_ORG_SUPER_ADMIN: 'Director',
  ROLE_PLATFORM_ADMIN: 'Platform Admin',
  ROLE_PLATFORM_SUPPORT: 'Soporte',
  ROLE_WORKSPACE: '',
  SAVE: 'Guardar',
  SELECT: 'Seleccionar',
  SELECT_COUNTRY: 'Seleccione un país',
  SELECT_ORGANIZATION: 'Seleccione una Organización',
  SELECT_WORKSPACE: 'Seleccione un Escritorio',
  SETTINGS: 'Configuración',
  SIGN_OFF: 'Cerrar sesión',
  STATE_OR_PROVINCE: 'Departamento, Estado, Provincia',
  TAX_IDENTIFICATION: 'Número de identificación fiscal',
  TAX_INFORMATION: 'Información fiscal',
  USER_TAB_HELP:
    'Estos son los usuarios con roles de de Director y Administradores, pare agregar usuarios operadores puede hacerlo en cada Escritorio',
  USERS: 'Usuarios',
  WORKSPACES: 'Escritorios',
  ZIP_CODE: 'Código postal',
};

export default locale;
