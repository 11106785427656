/** this file will contain the translations in english for the organizations page */
const locale = {
  ADDRESS: 'Address',
  ADDRESS_LINE_ONE: 'Address line #1',
  ADDRESS_LINE_TWO: 'Address line #2',
  ADMINISTRATOR_CONTACT: 'Administrator contact',
  BILLING: 'Billing',
  BILLING_CONTACT: 'Billing contact',
  CHANGES_SAVED: 'Changes saved',
  CITY: 'City',
  COMPANY_LEGAL_NAME: 'Legal name',
  CONTACT_LAST_NAME: 'Last name',
  CONTACT_NAME: 'Name',
  CONTACTS: 'Contacts',
  COUNTRY: 'Country',
  CREATE_WORKSPACE: 'Create a Workspace',
  CREATION_DATE: 'Creation date',
  DESCRIPTION: 'Description',
  EDITION_DATE: 'Edition date',
  EMAIL: 'Email',
  EMAIL_CANNOT_EMPTY: 'The email field cannot be empty',
  EMAIL_VALID: 'Enter a valid email address',
  HAVE_LEFT_ORG: 'You have left "{{name}}"',
  IMAGE_URL: 'Image logo URL',
  INFORMATION: 'Information',
  INVALID_IMAGEN_URL: 'Invalid image URL',
  LAST_NAME_CANNOT_EMPTY: 'The last name field cannot be empty',
  LEGAL_ADDRESS: 'Legal address',
  NAME: 'Name',
  NAME_CANNOT_EMPTY: 'The name field cannot be empty',
  NO_ORG_FOUND: ' No organizations found',
  NO_WS_FOUND: ' Create a new Workspace',
  PHONE: 'Telephone',
  ROLE_ORG_ADMIN: 'Administrator',
  ROLE_ORG_READ: 'Reader',
  ROLE_ORG_SUPER_ADMIN: 'Director',
  ROLE_PLATFORM_ADMIN: 'Platform Admin',
  ROLE_PLATFORM_SUPPORT: 'Support',
  ROLE_WORKSPACE: '',
  SAVE: 'Save',
  SELECT: 'Select',
  SELECT_COUNTRY: 'Select a country',
  SELECT_ORGANIZATION: 'Select an Organization',
  SELECT_WORKSPACE: 'Select a Workspace',
  SETTINGS: 'Settings',
  SIGN_OFF: 'Sign out',
  STATE_OR_PROVINCE: 'State or province',
  TAX_IDENTIFICATION: 'Tax identification number (SSN, EIN)',
  TAX_INFORMATION: 'Tax information',
  USER_TAB_HELP:
    'These are the users with the roles of Director and Administrators, to add operator users do it on each Workspace',
  USERS: 'Users',
  WORKSPACES: 'Workspaces',
  ZIP_CODE: 'ZIP Code',
};

export default locale;
