/** this file contains the english translation for the workspacesApp module */
const locale = {
  ACCEPT: 'Accept',
  CANCEL: 'Cancel',
  CODE: 'Code',
  CODE_ERROR: 'An error occurred while processing your code',
  CODE_NOT_FOUND: 'No invite code found for your user',
  CONFIRM: 'Confirm',
  COUNTRY: 'Country',
  COUNTRY_DESC: 'Select the country where Workspace operate',
  CREATE_WORKSPACE: 'Create a Workspace',
  DESCRIPTION: 'Description',
  ERROR_CREATING_WS: 'Error creating the Workspace',
  HAS_BEEN_JOINED_WORKSPACE: 'You have been joined to Workspace "{{wsName}}"',
  HAVE_LEFT_WS: 'You have left "{{name}}"',
  JOIN_WORKSPACE: 'Join a Workspace',
  JOIN_WORKSPACE_DESC: "Enter the code shared by the Workspace's admin",
  MODULE_DESC: 'Select the modules that your workspace will have',
  MODULES: 'Modules',
  NAME: 'Name',
  NAME_ALREADY_IN_USE: 'The name "{{wsName}}" is already in use',
  NAME_CANNOT_EMPTY: 'The name field cannot be empty',
  NAME_IN_USE: 'That name is already in use',
  NEW_WORKSPACE_TITLE: 'New Workspace',
  NEXT: 'Next',
  NO_WS_FOUND: ' No Workspaces found',
  PREVIOUS: 'Previous',
  ROLE_ORG_ADMIN: 'Administrator',
  ROLE_ORG_READ: 'Reader',
  ROLE_ORG_SUPER_ADMIN: 'Director',
  ROLE_PLATFORM_ADMIN: 'Platform Admin',
  ROLE_PLATFORM_SUPPORT: 'Support',
  ROLE_WORKSPACE: '',
  SELECT: 'Select',
  SELECT_WORKSPACE: 'Select a Workspace',
  SIGN_OFF: 'Sign out',
  TASK_MODULE: 'Task module',
  TIME_ZONE: 'Time zone',
  TIME_ZONE_DESC: 'Select which time zone applies to your Workspace',
  TRACKING_MODULE: 'Location module',
  VERIFY: 'Verify',
  WORKSPACE_DESC:
    'A Workspace is an independent work environment. Tasks, visits and user are different on each Workspace',
  WORKSPACE_HAS_CREATED: 'The Workspace "{{wsName}}" has been created',
  WORKSPACE_WILL_CREATED: 'The Workspace "{{wsName}}" will be created',
};

export default locale;
