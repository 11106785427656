import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { t } from 'i18next';
import { showMessage } from './fuse/messageSlice';

import ApiRoutes from '../services/ApiRoutes';

/** organization slice */

const initialState = { billing: {}, contacts: {}, users: [] };

export const selectOrganization = ({ organization }) => organization;

/** get the organization data */
export const getOrganization = createAsyncThunk(
  'organization/getOrganization',
  /** @param {string} orgId the organization id */
  async (orgId, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.organizationEndPoint() + orgId);

    const data = await response.data;

    return { ...initialState, ...data };
  }
);
/** change the organization data */
export const setOrganization = createAsyncThunk(
  'organization/setOrganization',
  /**
   * @param
   */
  async (org, { dispatch, getState }) => {
    const response = await axios.put(`${ApiRoutes.organizationEndPoint() + org.id}/`, org);

    const data = await response.data;

    dispatch(
      showMessage({
        message: t('organizationApp:CHANGES_SAVED'),
        variant: 'success',
      })
    );

    return { ...org, ...data };
  }
);

/**  change the billing data of the organization */
export const setOrgBilling = createAsyncThunk(
  'organization/setOrgBilling',
  /**
   * @param {Object} org the organization object
   */
  async (org, { dispatch, getState }) => {
    const response = await axios.put(
      ApiRoutes.setOrganizationBilling(org.id, org.billing.id),
      org.billing
    );

    const billing = await response.data;

    dispatch(
      showMessage({
        message: t('organizationApp:CHANGES_SAVED'),
        variant: 'success',
      })
    );

    return { ...org, billing };
  }
);

/** change the contact data of the organization */
export const setOrgContact = createAsyncThunk(
  'organization/setOrgContact',
  /**
   * @param {Object} org the organization object
   * @param {Object} contact the contact object
   */
  async ({ org, contact }, { dispatch, getState }) => {
    const response = await axios.put(ApiRoutes.setOrganizationContact(org.id, contact.id), contact);

    dispatch(
      showMessage({
        message: t('organizationApp:CHANGES_SAVED'),
        variant: 'success',
      })
    );

    return { ...org, contacts: { ...org.contacts, [contact.id]: response.data } };
  }
);
/** get all workspaces of the organization */
export const getOrgWorkspaces = createAsyncThunk(
  'organization/getOrgWorkspaces',
  /**
   * @param {Object} org the organization object
   */
  async (org, { dispatch, getState }) => {
    const response = await axios.get(`${ApiRoutes.organizationEndPoint() + org.id}/workspaces`);
    const workspaces = await response.data;

    return { ...org, workspaces };
  }
);

/** add a workspace to the organization */
export const addOrgWorkspace = createAsyncThunk(
  'organization/addOrgWorkspace',
  /**
   * @param {Object} workspace the workspace object to be added
   */
  async (workspace, { rejectWithValue, getState }) => {
    const { organization } = getState();
    try {
      const response = await axios.post(ApiRoutes.workspaceEndPoint(), workspace);
      const data = await response.data;
      return { ...organization, workspaces: [data, ...organization.workspaces] };
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

/** get the billing data of the organization */
export const getOrgBilling = createAsyncThunk(
  'organization/getOrgBilling',
  /**  @param {Object} org the organization object */
  async (org, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.getOrganizationBilling(org.id));

    const billing = await response.data;

    return { ...org, billing };
  }
);

/** get the contacts of the organization */
export const getOrgContacts = createAsyncThunk(
  'organization/getOrgContacts',
  /** @param {Object} org the organization object */
  async (org, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.getOrganizationContacts(org.id));

    const contacts = await response.data.reduce((a, v) => ({ ...a, [v.id]: v }), {});

    return { ...org, contacts };
  }
);

/** get the users of the organization */
export const getOrgUsers = createAsyncThunk(
  'organization/getOrgUsers',
  /** @param {Object} org the organization object */
  async (org, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.getOrganizationUsers(org.id));

    const users = await response.data;

    return { ...org, users };
  }
);

const organizationsSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    /** set the organization data */
    setDataOrganization: (state, action) => ({ ...initialState, ...action.payload }),
    /** reset the organization state */
    organizationReset: (state, action) => initialState,
  },
  extraReducers: {
    [getOrganization.fulfilled]: (state, action) => action.payload,
    [getOrgBilling.fulfilled]: (state, action) => action.payload,
    [getOrgContacts.fulfilled]: (state, action) => action.payload,
    [getOrgWorkspaces.fulfilled]: (state, action) => action.payload,
    [addOrgWorkspace.fulfilled]: (state, action) => action.payload,
    [getOrgUsers.fulfilled]: (state, action) => action.payload,
    [setOrganization.fulfilled]: (state, action) => action.payload,
    [setOrgBilling.fulfilled]: (state, action) => action.payload,
    [setOrgContact.fulfilled]: (state, action) => action.payload,
  },
});

export const { organizationReset, setDataOrganization } = organizationsSlice.actions;

export default organizationsSlice.reducer;
