import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import _ from '@lodash';
import { Controller, useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { getOrgContacts, selectOrganization, setOrgContact } from 'app/store/organizationSlice';
import { roleChecker } from 'app/store/userSlice';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  height: '90%',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  overflowY: 'auto',
}));

const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  gap: 8,
  paddingLeft: 8,
  paddingRight: 8,
  width: '100%',
  height: '10%',
}));

const Outlinebox = styled(TextField)(() => ({
  '.MuiOutlinedInput-input': {
    width: 0,
  },
}));

/** component to display the contacts information of a  organization */
function ContactsTab() {
  /**
   * @constant {boolean} isLoading - boolean to check if the data is loading
   * @constant {function} checkRole - function to check the user role
   * @constant {function} dispatch - redux dispatch function
   * @constant {object} organization - organization object
   * @constant {function} t - i18n translation function
   */
  const [isLoading, setIsLoading] = useState(true);
  const checkRole = useSelector(roleChecker);
  const dispatch = useDispatch();
  const organization = useSelector(selectOrganization);
  const { t } = useTranslation('organizationApp');

  const methods = useForm({
    mode: 'onChange',
  });
  const { control, formState, register, getValues, reset } = methods;
  const { isValid, errors } = formState;

  /** function to save the organization billing information */
  const saveOrganization = () => {
    Object.values(organization.contacts).forEach(async (contact) => {
      const newContact = getValues(contact.id);
      if (!_.isEqual(newContact, contact)) {
        const {
          payload: { contacts },
        } = await dispatch(
          setOrgContact({
            org: organization,
            contact: newContact,
          })
        );
      }
    });
    reset(organization.contacts);
  };

  /** effect to load the data in the form, and get the contact information if is nessesary */
  useEffect(() => {
    const loadData = async () => {
      if (_.isEmpty(organization.contacts)) {
        const {
          payload: { contacts },
        } = await dispatch(getOrgContacts(organization));
        reset(contacts);
      } else {
        reset(organization.contacts);
      }
      setIsLoading(false);
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reset]);

  if (isLoading) {
    return (
      <div className="file-details flex flex-col flex-auto  h-fullVH w-fullVW sm:h-full sm:w-full">
        <FuseLoading />
      </div>
    );
  }

  return (
    <>
      <Content>
        <form
          name="organizationBillingForm"
          noValidate
          className="flex flex-col justify-center w-full pb-24 px-12"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {Object.keys(organization.contacts).map((contactKey) => {
            register(`${contactKey}.contact_last_name`, { required: t('LAST_NAME_CANNOT_EMPTY') });
            register(`${contactKey}.contact_name`, { required: t('NAME_CANNOT_EMPTY') });
            register(`${contactKey}.email`, {
              required: t('EMAIL_CANNOT_EMPTY'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('EMAIL_VALID'),
              },
            });
            return (
              <div key={contactKey}>
                <Divider className="my-20" spacing={1}>
                  <Typography variant="subtitle1">
                    {t(
                      organization.contacts[contactKey].type === 'administrator'
                        ? 'ADMINISTRATOR_CONTACT'
                        : 'BILLING_CONTACT'
                    )}
                  </Typography>
                </Divider>
                <Controller
                  name={`${contactKey}.contact_name`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      className="pb-20"
                      label={t('CONTACT_NAME')}
                      autoFocus
                      variant="outlined"
                      required
                      error={!!errors[contactKey]?.contact_name}
                      helperText={errors[contactKey]?.contact_name?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name={`${contactKey}.contact_last_name`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      className="pb-20"
                      label={t('CONTACT_LAST_NAME')}
                      autoFocus
                      variant="outlined"
                      required
                      error={!!errors[contactKey]?.contact_last_name}
                      helperText={errors[contactKey]?.contact_last_name?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name={`${contactKey}.email`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      className="pb-20"
                      label={t('EMAIL')}
                      autoFocus
                      variant="outlined"
                      required
                      error={!!errors[contactKey]?.email}
                      helperText={errors[contactKey]?.email?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name={`${contactKey}.phone`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      className="pb-20"
                      label={t('PHONE')}
                      autoFocus
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name={`${contactKey}.address`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label={t('ADDRESS')}
                      autoFocus
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                {checkRole('platform_support') && (
                  <>
                    <div className="py-8">
                      {organization.contacts[contactKey]?.date_created && (
                        <div>
                          <Typography variant="caption" className="font-medium">
                            {t('CREATION_DATE')}:
                          </Typography>
                          <Typography variant="caption">
                            {format(
                              parseISO(organization.contacts[contactKey]?.date_created),
                              'dd/MM/y hh:mm aaaa '
                            )}
                          </Typography>
                        </div>
                      )}
                      {organization.contacts[contactKey]?.date_edited && (
                        <div>
                          <Typography variant="caption" className="font-medium">
                            {t('EDITION_DATE')}:
                          </Typography>
                          <Typography variant="caption">
                            {format(
                              parseISO(organization.contacts[contactKey]?.date_edited),
                              'dd/MM/y hh:mm aaaa '
                            )}
                          </Typography>
                        </div>
                      )}
                      <div>
                        <Typography variant="caption" className="font-medium">
                          ID:
                        </Typography>
                        <Typography variant="caption">
                          {organization.contacts[contactKey]?.id}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </form>
      </Content>
      <Footer>
        <Button
          className="flex-auto "
          color="secondary"
          variant="contained"
          disabled={!isValid}
          onClick={saveOrganization}
        >
          {t('SAVE')}
        </Button>
      </Footer>
    </>
  );
}

export default ContactsTab;
