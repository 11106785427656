/* eslint-disable camelcase */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ProjectChip from 'app/store/selectsData/Components/ProjectChip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import _ from '@lodash';
import TeamChip from 'app/store/selectsData/Components/TeamChip';
import { Controller, useFormContext } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { getUsersAll, selectUsers } from 'app/store/selectsData/usersSlice';
import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { roleChecker } from 'app/store/userSlice';
import {
  selectProjectById,
  selectProjects,
} from 'app/store/selectsData/projectsSlice';
import { selectTeamById, selectTeams } from 'app/store/selectsData/teamsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  selectClientById,
  selectClientData,
  setClient,
  setDeleteModalProps,
  setRightSidebarProps,
  syncClientPlaces,
} from '../../../store/clientsSlice';

const labelStyle = (theme) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  borderRadius: '15px',
  textAlign: 'center',
  paddingX: 1,
  paddingY: 0.25,
  marginX: 1,
  marginY: 0.25,
  maxWidth: 'min-content',
  minWidth: 'max-content',
  width: '100%',
  height: 1,
});

/**
 * component to show the project label
 * @param {object} props - the props
 * @param {string} props.id - the id of the project
 * @returns {JSX.Element}
 */
const ProjectLabel = ({ id }) => {
  const project = useSelector((state) => selectProjectById(state, id));
  return (
    <Typography variant="caption" className="font-medium" sx={labelStyle}>
      {project?.name}
    </Typography>
  );
};

/**
 * component to show the team label
 * @param {object} props - the props
 * @param {string} props.id - the id of the team
 */
const TeamLabel = ({ id }) => {
  const team = useSelector((state) => selectTeamById(state, id));
  return (
    <Typography variant="caption" className="font-medium" sx={labelStyle}>
      {team?.name}
    </Typography>
  );
};

/**
 * show the details of the client
 * @returns {JSX.Element}
 */
function DetailsClient() {
  /**
   * @constant {function} checkPermission - check the permission of the user
   * @constant {function} checkRole - check the role of the user
   * @constant {object} client - the client object
   * @constant {function} dispatch - the function to dispatch an action
   * @constant {object[]} projects - the list of projects
   * @constant {object} scrollRef - the reference of the scroll
   * @constant {object[]} teams - the list of teams
   * @constant {object[]} users - the list of users
   * @constant {object} useFormContext - the context of the form
   * @constant {boolean} editMode - the edit mode of the client
   * @constant {boolean} IsLoading - if the client is loading
   * @constant {function} t - the translation function
   * @constant {object} wsId - the workspace id
   * @constant {object} clientId - the client id
   */
  const checkPermission = useSelector(permissionChecker);
  const checkRole = useSelector(roleChecker);
  const { wsId, clientId } = useParams();
  const client = useSelector((state) => selectClientById(state, clientId));
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  const scrollRef = useRef(null);
  const teams = useSelector(selectTeams);
  const users = useSelector(selectUsers);
  const { control, formState, getValues, reset, watch, setValue, setError } =
    useFormContext();
  const { editMode, IsLoading } = useSelector(selectClientData).rightSiderbar;
  const { t } = useTranslation('managementApp');

  /**
   * activate the edit mode
   */
  const onEdit = async () => {
    dispatch(setRightSidebarProps({ IsLoading: true }));
    await dispatch(getUsersAll(wsId));

    reset(client);
    dispatch(setRightSidebarProps({ editMode: true, IsLoading: false }));
  };

  /**
   * check if the data is repeated only applies to the fields that are unique
   */
  const onRepeatedData = (repeatedFields, clienr) => {
    if (repeatedFields.exists_name)
      setError('name', {
        type: 'customName',
        message: t('ALREADY_USE_NAME', { name: clienr.name }),
      });

    if (repeatedFields.exists_internal_code)
      setError('internal_code', {
        type: 'customInternal_code',
        message: t('ALREADY_USE_INTERNAL_CODE', {
          internal_code: clienr.internal_code,
        }),
      });

    if (repeatedFields.exists_invoice_tax_identifier)
      setError('invoice_tax_identifier', {
        type: 'customInvoice_tax_identifier',
        message: t('ALREADY_USE_INVOICE_TAX_IDENTIFIER', {
          invoice_tax_identifier: clienr.invoice_tax_identifier,
        }),
      });
  };

  /**
   * save the client
   */
  const saveClient = async () => {
    dispatch(setRightSidebarProps({ IsLoading: true }));

    const clientData = getValues();

    try {
      await dispatch(setClient({ wsId, client: clientData })).unwrap();
      if (clientData.places_data)
        await dispatch(
          syncClientPlaces({
            wsId,
            clientId: clientData.id,
            places_data: clientData.places_data,
          })
        );
    } catch (error) {
      onRepeatedData(error, clientData);
    }

    dispatch(setRightSidebarProps({ IsLoading: false, editMode: false }));
  };

  /**
   * open the confirm delete modal
   */
  const openDeleteModal = () =>
    dispatch(setDeleteModalProps({ open: true, clientId }));

  const { isValid, errors } = formState;

  /**
   * add a new field in the client
   */
  const addField = () =>
    setValue('data.fields', [
      ...getValues('data.fields'),
      { title: '', value: '' },
    ]);

  /**
   * remove a field in the client
   */
  const removeField = (index) => {
    const data = getValues('data.fields');
    data.splice(index, 1);
    setValue('data.fields', data);
  };

  /**
   * change the tab to the through-client-coordinate, to indicate the location of the client
   * @param {object} e - the event
   * @param {number} newTab - the new tab
   */
  const onChangeTabMap = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'map' }));
    setValue('location_mode', 'through-client-coordinate');
  };

  /**
   * change the tab to the through-client-places, to indicate the location of many places
   * @param {object} e - the event
   * @param {number} newTab - the new tab
   */
  const onChangeTabPlaces = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'places' }));
    setValue('location_mode', 'through-client-places');
  };

  /**
   * tab to  client with unknown location
   */
  const onChangeTabUnknown = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'no_location' }));
    setValue('location_mode', '');
  };

  /**
   * tab to  client with info
   * @param {object} e - the event
   * @param {number} newTab - the new tab
   */
  const onChangeTabInfo = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'location_info' }));
  };

  /**
   * effect to get the users
   */
  useEffect(() => {
    if (editMode) {
      const getUserList = async () => {
        dispatch(setRightSidebarProps({ IsLoading: true }));
        await dispatch(getUsersAll(wsId));

        dispatch(setRightSidebarProps({ IsLoading: false }));
      };
      getUserList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (IsLoading) {
    return (
      <div className="h-full flex-1 flex items-center" ref={scrollRef}>
        <FuseLoading />
      </div>
    );
  }

  if (editMode) {
    return (
      <div className="flex flex-col flex-1 h-full w-full ">
        <div className="overflow-scroll flex-auto h-0 px-12 pb-28">
          <div className=" my-16">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="client-name"
                  disabled={!checkPermission('edit_client')}
                  label={t('NAME')}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
          </div>

          <div className=" my-16">
            <Controller
              name="internal_code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!checkPermission('edit_client')}
                  label={t('INTERNAL_CODE')}
                  error={!!errors.internal_code}
                  helperText={errors?.internal_code?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div className=" my-16">
            <Controller
              name="contact_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!checkPermission('edit_client')}
                  value={field.value || ''}
                  label={t('CONTACT_NAME')}
                  error={!!errors.contact_name}
                  helperText={errors?.contact_name?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div className=" my-16">
            <Controller
              name="contact_phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!checkPermission('edit_client')}
                  label={t('CONTACT_PHONE')}
                  error={!!errors.contact_phone}
                  helperText={errors?.contact_phone?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div className=" my-16">
            <Controller
              name="contact_email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!checkPermission('edit_client')}
                  label={t('CONTACT_EMAIL')}
                  value={field.value || ''}
                  error={!!errors.contact_email}
                  helperText={errors?.contact_email?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div className=" my-16">
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!checkPermission('edit_client')}
                  label={t('DESCRIPTION')}
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div className="w-full py-4">
            <Divider />
          </div>

          <div className="my-16">
            <Controller
              name="invoice_tax_identifier"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!checkPermission('edit_client')}
                  label={t('INVOICE_TAX')}
                  placeholder={t('INVOICE_TAX_EXAMPLE')}
                  error={!!errors.invoice_tax_identifier}
                  helperText={errors?.invoice_tax_identifier?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div className=" my-16">
            <Controller
              name="invoice_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!checkPermission('edit_client')}
                  label={t('INVOICE_NAME')}
                  error={!!errors.invoice_name}
                  helperText={errors?.invoice_name?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div className=" my-16">
            <Controller
              name="sales_goal"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!checkPermission('edit_client')}
                  label={t('SALES_GOAL')}
                  placeholder={t('SALES_GOAL_EXAMPLE')}
                  error={!!errors.sales_goal}
                  helperText={errors?.sales_goal?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div className="my-16">
            <Controller
              name="projects"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <Autocomplete
                  className="mt-8 mb-16"
                  multiple
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '10px!important',
                    },
                  }}
                  openOnFocus
                  disableClearable
                  popupIcon={false}
                  noOptionsText={t('NO_OPTION')}
                  options={projects}
                  value={value}
                  disabled={!checkPermission('edit_alert')}
                  isOptionEqualToValue={(option, projectID) =>
                    option.id === projectID
                  }
                  getOptionLabel={(option) => option.name}
                  renderOption={(propsOption, option) => (
                    <Box {...propsOption} component="li" key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  renderTags={(projectsID, getTagProps) =>
                    projectsID.map((id, index) => (
                      <ProjectChip
                        id={id}
                        chipProps={getTagProps({ index })}
                        key={id}
                      />
                    ))
                  }
                  onChange={(event, newValue) =>
                    onChange(
                      newValue.map((projectID) => {
                        if (
                          typeof projectID === 'string' ||
                          projectID instanceof String
                        ) {
                          return projectID;
                        }
                        return projectID.id;
                      })
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={!checkPermission('edit_alert')}
                      variant="outlined"
                      label={t('PROJECTS')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  )}
                />
              )}
            />
          </div>
          <div className="my-16">
            <Controller
              name="teams"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <Autocomplete
                  className="mt-8 mb-16"
                  multiple
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '10px!important',
                    },
                  }}
                  openOnFocus
                  disableClearable
                  popupIcon={false}
                  noOptionsText={t('NO_OPTION')}
                  options={teams}
                  value={value}
                  disabled={!checkPermission('edit_alert')}
                  isOptionEqualToValue={(option, teamID) =>
                    option.id === teamID
                  }
                  getOptionLabel={(option) => option.name}
                  renderOption={(propsOption, option) => (
                    <Box {...propsOption} component="li" key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  renderTags={(teamsID, getTagProps) =>
                    teamsID.map((id, index) => (
                      <TeamChip
                        id={id}
                        chipProps={getTagProps({ index })}
                        key={id}
                      />
                    ))
                  }
                  onChange={(event, newValue) =>
                    onChange(
                      newValue.map((teamID) => {
                        if (
                          typeof teamID === 'string' ||
                          teamID instanceof String
                        ) {
                          return teamID;
                        }
                        return teamID.id;
                      })
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={!checkPermission('edit_alert')}
                      variant="outlined"
                      label={t('TEAM_IN_CHARGE')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  )}
                />
              )}
            />
          </div>
          <div className="my-16">
            <Controller
              name="seller_data"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <Autocomplete
                  className="mt-8 mb-16"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '10px!important',
                    },
                  }}
                  openOnFocus
                  clearOnBlur
                  // disableClearable
                  popupIcon={false}
                  disabled={!checkPermission('edit_client')}
                  noOptionsText={t('NO_OPTION')}
                  options={users}
                  value={_.isEmpty(value) ? null : value}
                  isOptionEqualToValue={(option, user) => option.id === user.id}
                  getOptionLabel={(option) => `${option.name}(${option.email})`}
                  onChange={(event, user) => {
                    if (user) {
                      setValue('seller', user.id);
                      onChange({
                        id: user.id,
                        shortid: user.shortid,
                        email: user.email,
                        name: user.name,
                        last_name: user.last_name,
                      });
                    } else {
                      setValue('seller', null);
                      onChange({});
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={!checkPermission('edit_client')}
                      label={t('SALESPERSON_CHARGE')}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  )}
                />
              )}
            />
          </div>
          <div className="w-full py-4">
            <Divider />
          </div>
          <div>
            {checkPermission('edit_client') && (
              <div className="flex flex-col  items-start w-full pt-16">
                <Typography
                  variant="body1"
                  className="font-medium flex-1 px-8"
                  display="inline"
                >
                  {t('CAN_ADD_FIELDS')}:
                </Typography>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<AddCircleIcon />}
                  size="small"
                  onClick={addField}
                >
                  {t('ADD_FIELD')}
                </Button>
              </div>
            )}
            {watch('data')?.fields?.map((e, index) => (
              <div className="flex flex-col items-center" key={index}>
                <div className="w-full py-16 px-8">
                  <Divider />
                </div>
                <div className="flex w-full items-center justify-around">
                  <div className="px-4  flex-1">
                    <Controller
                      name={`data.fields.${index}.title`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('TITLE')}
                          variant="outlined"
                          disabled={!checkPermission('edit_client')}
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="px-4 flex-1">
                    <Controller
                      name={`data.fields.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('VALUE')}
                          variant="outlined"
                          disabled={!checkPermission('edit_client')}
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  {checkPermission('edit_client') && (
                    <IconButton
                      className=""
                      size="medium"
                      onClick={() => removeField(index)}
                    >
                      <FuseSvgIcon
                        className="text-48 w-1/12"
                        size={24}
                        color="error"
                      >
                        heroicons-solid:x-circle
                      </FuseSvgIcon>
                    </IconButton>
                  )}
                </div>
              </div>
            ))}

            {clientId !== 'new' ? (
              <div className="my-16">
                {watch('location_mode') === 'through-client-places' ? (
                  <div className="flex justify-end py-10">
                    <Button
                      onClick={onChangeTabPlaces}
                      color="secondary"
                      variant="contained"
                      endIcon={<LocationCityIcon />}
                    >
                      {t('ASSIGN_PLACES_THIS_CLIENT')}
                    </Button>
                  </div>
                ) : (
                  <div className="flex justify-end py-10">
                    <Button
                      onClick={onChangeTabMap}
                      color="secondary"
                      variant="contained"
                      endIcon={<LocationOnIcon />}
                    >
                      {t('ASSIGN_LOCATION_THIS_CLIENT')}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col py-10 gap-5">
                <div className="flex justify-end">
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    className="gap-5 flex justify-start"
                    disabled={!isValid}
                    onClick={onChangeTabInfo}
                  >
                    <Typography className="text-12">
                      {t('CONTINUE_LOCATION')}
                    </Typography>
                    <FuseSvgIcon size={18}>
                      material-solid:arrow_forward
                    </FuseSvgIcon>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        {clientId !== 'new' && (
          <div className="absolute right-24 bottom-24">
            <Tooltip title={t('SAVE')} placement="top">
              <Fab
                color="secondary"
                size="small"
                aria-label="edit"
                onClick={saveClient}
              >
                <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
              </Fab>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="overflow-scroll flex-auto h-0 p-12 pb-28">
      <div className="absolute right-24 bottom-24 flex gap-10">
        {checkPermission('edit_client') && (
          <Tooltip title={t('EDIT')} placement="top">
            <Fab
              onClick={onEdit}
              size="small"
              color="secondary"
              aria-label="add"
            >
              <FuseSvgIcon className="text-white" size={20}>
                heroicons-solid:pencil
              </FuseSvgIcon>
            </Fab>
          </Tooltip>
        )}
        {checkPermission('delete_client') && (
          <Tooltip title={t('DELETE')} placement="top">
            <Fab
              color="error"
              size="small"
              aria-label="remove"
              onClick={openDeleteModal}
            >
              <FuseSvgIcon size={20}>material-solid:delete</FuseSvgIcon>
            </Fab>
          </Tooltip>
        )}
      </div>

      <Typography className="text-18 font-medium">{client?.name}</Typography>

      <div className="w-full py-4">
        <Divider />
      </div>

      <div className="flex items-center py-8">
        <Typography variant="body2">
          <strong>{t('INTERNAL_CODE')}: </strong>
          {client.internal_code}
        </Typography>
      </div>

      <div className="flex items-center py-8">
        <Typography variant="body2">
          <strong>{t('CONTACT_NAME')}: </strong>
          {client.contact_name}
        </Typography>
      </div>

      <div className="flex items-center py-8">
        <Typography variant="body2">
          <strong>{t('CONTACT_EMAIL')}: </strong>
          {client.contact_email}
        </Typography>
      </div>

      <div className="flex items-center py-8">
        <Typography variant="body2">
          <strong>{t('CONTACT_PHONE')}: </strong>
          {client.contact_phone}
        </Typography>
      </div>

      <div className="flex items-center py-8">
        <Typography variant="body2">
          <strong>{t('DESCRIPTION')}: </strong>
          {client.description}
        </Typography>
      </div>

      <div className="flex items-center flex-wrap w-full py-8">
        <Typography variant="body" className="font-700">
          {t('PROJECTS')}:
        </Typography>
        {client.projects.map((id) => (
          <ProjectLabel id={id} key={id} />
        ))}
      </div>

      <div className="w-full py-4">
        <Divider />
      </div>

      <div className="flex items-center py-8">
        <Typography variant="body2">
          <strong>{t('INVOICE_TAX')}: </strong>
          {client.invoice_tax_identifier}
        </Typography>
      </div>

      <div className="flex items-center py-8">
        <Typography variant="body2">
          <strong>{t('INVOICE_NAME')}: </strong>
          {client.invoice_name}
        </Typography>
      </div>

      <div className="flex items-center py-8">
        <Typography variant="body2">
          <strong>{t('SALES_GOAL')}: </strong>
          {client.sales_goal}
        </Typography>
      </div>

      <div className="flex items-center flex-wrap w-full">
        <Typography variant="body" className="font-700">
          {t('TEAM_IN_CHARGE')}:
        </Typography>
        {client.teams.map((id) => (
          <TeamLabel id={id} key={id} />
        ))}
      </div>

      <div className="flex items-center py-8">
        <Typography variant="body2">
          <strong>{t('SALESPERSON_CHARGE')}: </strong>
          {client.seller
            ? `${client.seller_data?.name} ${client.seller_data?.last_name}(${client.seller_data?.email})`
            : ''}
        </Typography>
      </div>

      {client.data?.fields?.length > 0 && (
        <>
          <div className="w-full py-4">
            <Divider />
          </div>
          {client.data.fields.map((field, index) => (
            <div className="flex items-center py-8" key={index}>
              <Typography variant="body2">
                <strong>{field.title}: </strong>
                {field.value}
              </Typography>
            </div>
          ))}
        </>
      )}
      {checkRole('platform_support') && (
        <>
          <div className="w-full py-4">
            <Divider />
          </div>
          <div>
            <Typography variant="caption" className="font-medium">
              {t('CREATION_DATE')}:
            </Typography>
            <Typography variant="caption">
              {format(parseISO(client.date_created), 'dd/MM/y hh:mm aaaa ')}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" className="font-medium">
              {t('EDITION_DATE')}:
            </Typography>
            <Typography variant="caption">
              {format(parseISO(client.date_edited), 'dd/MM/y hh:mm aaaa ')}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" className="font-medium">
              ID:
            </Typography>
            <Typography variant="caption">{client.id}</Typography>
          </div>
        </>
      )}
    </div>
  );
}

export default DetailsClient;
