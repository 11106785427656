import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

/** list of geofences to be used by select forms */

const geofencesAdapter = createEntityAdapter({});

export const { selectAll: selectGeofences, selectById: selectGeofenceById } =
  geofencesAdapter.getSelectors((state) => state.selectsData.geofences);

const initialState = geofencesAdapter.getInitialState({
  isGeofencesLoaded: false,
});

/** get geofences list to be used by select forms */
export const getGeofencesAll = createAsyncThunk(
  'selectsData/geofences/getGeofencesAll',
  /**
   * @param {string} wsId the workspace id
   */
  async (wsId, { dispatch, getState }) => {
    const { isGeofencesLoaded } = getState().selectsData.geofences;
    if (!isGeofencesLoaded) {
      const response = await axios.get(ApiRoutes.getAllGeofencesBasicInfo(wsId));
      const data = await response.data;
      dispatch(setIsGeofencesLoaded(true));

      return data;
    }
    return [];
  }
);

const geofenceSlice = createSlice({
  name: 'geofences',
  initialState,
  reducers: {
    /** change the indicator to know if the `geofences` are loaded */
    setIsGeofencesLoaded: (state, action) => {
      state.isGeofencesLoaded = action.payload;
    },
    /** reset the `geofences` state */
    resetGeofenceState: () => initialState,
  },
  extraReducers: {
    [getGeofencesAll.fulfilled]: geofencesAdapter.addMany,
  },
});

export const { setIsGeofencesLoaded, resetGeofenceState } = geofenceSlice.actions;

export default geofenceSlice.reducer;
