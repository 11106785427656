/* eslint-disable camelcase */
import ApiRoutes from 'src/app/services/ApiRoutes';
import axios from 'axios';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getUsersAll } from 'app/store/selectsData/usersSlice';

const SynchronizationssAdapter = createEntityAdapter({});

export const { selectAll: selectSynchronizations, selectById: selectSynchronizationById } =
  SynchronizationssAdapter.getSelectors(({ managementApp }) => managementApp.syncHistory);

export const selectSynchronizationData = ({ managementApp }) => managementApp.syncHistory;

const initialState = SynchronizationssAdapter.getInitialState({
  numOfPages: 0,
  count: 0,
  page: 1,
  isLoading: true,
  FiltersDialogProps: {
    open: false,
  },
});

/**
 * get the synchronizations
 */
export const getSynchronizations = createAsyncThunk(
  'managementApp/syncHistory/getSynchronizations',
  /**
   * @param {object} payload - the props
   * @param {string} props.wsId - the workspace id
   * @param {object} props.params - the params
   */
  async ({ wsId, params }, { dispatch, getState }) => {
    dispatch(setIsLoading(true));

    const response = await axios.get(ApiRoutes.syncHistoryEndPoint(wsId, params));
    await dispatch(getUsersAll(wsId));

    dispatch(setIsLoading(false));
    dispatch(setPage(params.page));

    dispatch(setNumOfPages(Math.ceil(response.data.count / 100 || 0)));
    dispatch(setCount(response.data.count));

    const data = await response.data.results;

    return data;
  }
);

/**
 * get a synchronization
 */
export const getSynchronization = createAsyncThunk(
  'managementApp/syncHistory/getSynchronization',
  /**
   * @param {object} payload
   * @param {string} props.wsId - the workspace id
   * @param {string} props.id - the synchronization id
   *
   */
  async ({ wsId, id }, { getState, dispatch }) => {
    const { entities } = getState().managementApp.Synchronizations;

    if (entities[id]) return entities[id];

    const { data } = await axios.get(ApiRoutes.syncHistoryEndPoint(wsId, null) + id);

    return data;
  }
);

const SynchronizationssSlice = createSlice({
  name: 'managementApp/syncHistory',
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    openFiltersDialog: (state, action) => {
      state.FiltersDialogProps = {
        open: true,
      };
    },
    closeFiltersDialog: (state, action) => {
      state.FiltersDialogProps = {
        open: false,
      };
    },
    resetSynchronizationsState: () => initialState,
  },
  extraReducers: {
    [getSynchronizations.fulfilled]: SynchronizationssAdapter.setAll,
    [getSynchronization.fulfilled]: SynchronizationssAdapter.upsertOne,
  },
});

export const selectSelectedSynchronization = ({ managementApp }) =>
  managementApp.syncHistory.selectedSynchronizationId;

export const {
  setNumOfPages,
  setIsLoading,
  setPage,
  resetSynchronizationsState,
  setCount,
  openFiltersDialog,
  closeFiltersDialog,
} = SynchronizationssSlice.actions;

export default SynchronizationssSlice.reducer;
