import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import Slide from '@mui/material/Slide';
import { selectWorkspace } from 'app/store/workspaceSlice';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useTranslation } from 'react-i18next';

/* user menu in the header */

const Root = styled('div')(({ theme }) => ({
  '& .username': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function WorkspaceNavbarHeaderResposive(props) {
  const workspace = useSelector(selectWorkspace);
  const { t } = useTranslation('navigation');

  const { foldedOpen } = useSelector((state) => state.fuse.navbar);

  return (
    <Root className="user relative flex flex-col items-center justify-center pb-8 shadow-0 w-full ">
      {workspace?.aux_data?.brand_image && (
        <Avatar
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.secondary',
          }}
          className="logo-icon text-32 font-bold w-96 h-96"
          src={workspace.aux_data.brand_image.url}
        >
          <Tooltip title={t('COMPANY_LOGO_CAN_HERE')}>
            <FuseSvgIcon>material-solid:perm_media</FuseSvgIcon>
          </Tooltip>
        </Avatar>
      )}
      <Slide direction="left" in={foldedOpen}>
        <Typography className="username whitespace-nowrap  font-medium ml-2">
          {workspace?.name}
        </Typography>
      </Slide>
    </Root>
  );
}

export default WorkspaceNavbarHeaderResposive;
