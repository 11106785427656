/* eslint-disable camelcase */
import Divider from '@mui/material/Divider';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { motion } from 'framer-motion';
import { roleChecker } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectApplicationById } from '../../store/applicationsSlice';

/**
 * sidebar content of the application
 */
function ApplicationSidebarContent() {
  /**
   * @constant {string} applicationId - the id of the application
   * @constant {object} application - the application object
   * @constant {function} checkRole - the function to check the role of the user
   * @constant {function} navigate - the function to navigate to another page
   * @constant {function} t - the function to translate text
   */
  const { applicationId } = useParams();
  const application = useSelector((state) => selectApplicationById(state, applicationId));
  const checkRole = useSelector(roleChecker);
  const navigate = useNavigate();
  const { t } = useTranslation('managementApp');

  /**
   * close the sidebar
   */
  const onClose = () => navigate(`..${window.location.search}`);

  if (!applicationId || !application) {
    return null;
  }

  return (
    <motion.div
      initial={{ y: 50, opacity: 0.8 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex flex-col flex-1 h-full w-full"
    >
      <div className="overflow-scroll flex-auto h-0">
        <div className="flex items-center justify-end w-full">
          <IconButton size="medium" onClick={onClose}>
            <FuseSvgIcon>heroicons-solid:x</FuseSvgIcon>
          </IconButton>
        </div>
        <div className="mt-6 px-12">
          <Typography variant="subtitle1">{t('APPLICATION_DETAILS')}</Typography>
          <div className="w-full py-4">
            <Divider />
          </div>
          <div className="flex items-center py-4">
            <Typography variant="caption" className="overflow-hidden text-ellipsis">
              <strong>{t('USER')}: </strong>
              {`${application.user_data.name} ${application.user_data.last_name}(${application.user_data.email})`}
            </Typography>
          </div>
          <div className="flex items-center py-4">
            <Typography variant="caption" className="overflow-hidden text-ellipsis">
              <strong>{t('VERSION')}: </strong>
              {application.version}
            </Typography>
          </div>
          <div className="flex items-center py-4">
            <Typography variant="caption" className="overflow-hidden text-ellipsis">
              <strong>{t('DEVICE_TYPE')}: </strong>
              {application.device_type}
            </Typography>
          </div>
          <div className="flex items-center py-4">
            <Typography variant="caption" className="overflow-hidden text-ellipsis">
              <strong>{t('MANUFACTURER')}: </strong>
              {application.manufacturer}
            </Typography>
          </div>
          <div className="flex items-center py-4">
            <Typography variant="caption" className="overflow-hidden text-ellipsis">
              <strong>{t('CARRIER')}: </strong>
              {application.carrier}
            </Typography>
          </div>
          <div className="flex items-center py-4">
            <Typography variant="caption" className="overflow-hidden text-ellipsis">
              <strong>{t('FIRST_INSTALLATION')}: </strong>
              {format(parseISO(application.first_installation), 'dd/MM/y hh:mm aaaa ')}
            </Typography>
          </div>
          <div className="flex items-center py-4">
            <Typography variant="caption" className="overflow-hidden text-ellipsis">
              <strong>{t('LAST_INSTALLATION')}: </strong>
              {format(parseISO(application.last_installation), 'dd/MM/y hh:mm aaaa ')}
            </Typography>
          </div>
          <div className="w-full py-4">
            <Divider />
          </div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="caption" className="overflow-hidden text-ellipsis">
                {t('MORE_DATA')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object.entries(application.device_data).map(([key, value]) => (
                <div className="flex items-center py-4">
                  <Typography variant="caption" className="overflow-hidden text-ellipsis">
                    <strong>{key}: </strong>
                    {value}
                  </Typography>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>

          {checkRole('platform_support') && (
            <>
              <div className="flex">
                <Typography className="font-medium text-10">{t('CREATION_DATE')}:</Typography>
                <Typography className="text-10">
                  {format(parseISO(application?.date_created), 'dd/MM/y hh:mm aaaa ')}
                </Typography>
              </div>
              <div className="flex">
                <Typography variant="caption" className="font-medium text-10">
                  ID:
                </Typography>
                <Typography className="text-10">{application?.id}</Typography>
              </div>
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default ApplicationSidebarContent;
