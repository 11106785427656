import { useParams } from 'react-router-dom';

import DetailSidebarSuperusers from './RolesSidebarSuperusers';
import DetailSidebarRoles from './RolesSidebar';

/**
 * Sidebar content for the roles
 */
function RolesSidebarContent() {
  const { roleId } = useParams();

  if (roleId === 'superusers') return <DetailSidebarSuperusers />;
  return <DetailSidebarRoles />;
}
export default RolesSidebarContent;
