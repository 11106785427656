/* eslint-disable camelcase */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

/** list of users to be used by select users */

const usersAdapter = createEntityAdapter({});

export const selectUserSelector = (state) => state.selectsData.users;

export const { selectAll: selectUsers, selectById: selectUserById } =
  usersAdapter.getSelectors(selectUserSelector);

const initialState = usersAdapter.getInitialState({
  isUsersLoaded: false,
  client: null,
});

/** get all the users to select data */
export const getUsersAll = createAsyncThunk(
  'selectsData/users/getUsersAll',
  /**
   * @param {string} wsId the workspace id
   */
  async (wsId, { dispatch, getState }) => {
    const { isUsersLoaded, client } = getState().selectsData.users;
    if (!isUsersLoaded || client !== null) {
      const response = await axios.get(ApiRoutes.usersEndPoint(wsId));
      const data = await response.data;
      dispatch(setUsersProps({ client: null }));

      dispatch(setIsUsersLoaded(true));
      return data;
    }
    return [];
  }
);

/** get all the users to select data per client */
export const getUsersAllPerClient = createAsyncThunk(
  'selectsData/users/getUsersAllPerClient',
  /**
   * @param {Object} param0
   * @param {string} wsId the workspace id
   * @param {string} client_id the client id
   */
  async ({ wsId, client_id }, { dispatch }) => {
    await dispatch(setIsUsersLoaded(false));

    const { data } = await axios.get(ApiRoutes.usersEndPoint(wsId, { client_id }));
    dispatch(setUsersProps({ client: client_id }));

    await dispatch(setIsUsersLoaded(true));
    return data;
  }
);

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    /** change the indicator to know if the `users` are loaded */
    setIsUsersLoaded: (state, action) => {
      state.isUsersLoaded = action.payload;
    },
    /** set the client id */
    setUsersProps: (state, action) => {
      const { client = null } = action.payload;
      state.client = client;
    },
    /** reset the `users` state */
    resetUserState: () => initialState,
  },
  extraReducers: {
    [getUsersAll.fulfilled]: usersAdapter.addMany,
    [getUsersAllPerClient.fulfilled]: usersAdapter.setAll,
  },
});

export const { setIsUsersLoaded, resetUserState, setUsersProps } = userSlice.actions;

export default userSlice.reducer;
