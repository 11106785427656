/* eslint-disable camelcase */
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import FuseUtils from '@fuse/utils';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { roleChecker } from 'app/store/userSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectEventById } from '../../store/eventsSlice';

/**
 * content of the right sidebar of the events page
 * @returns {JSX.Element}
 */
function EventsSidebarContent() {
  /**
   * @constant {string} eventId - the id of the event
   * @constant {function} checkRole - the role checker function
   * @constant {object} event - the event object
   * @constant {function} navigate - the navigate function from the react-router-dom
   * @constant {function} t - the translation function
   * @constant {string} wsId - the id of the workspace
   */
  const { eventId } = useParams();
  const checkRole = useSelector(roleChecker);
  const event = useSelector((state) => selectEventById(state, eventId));
  const navigate = useNavigate();
  const { t } = useTranslation('managementApp');
  const { wsId } = useParams();

  /**
   * close the right sidebar
   */
  const onClose = () => navigate(`..${window.location.search}`);

  if (!eventId || !event) {
    return null;
  }

  return (
    <motion.div
      initial={{ y: 50, opacity: 0.8 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex flex-col flex-1 h-full w-full"
    >
      <div className="flex items-center justify-end w-full h-full">
        <IconButton size="medium" onClick={onClose}>
          <FuseSvgIcon>heroicons-solid:x</FuseSvgIcon>
        </IconButton>
      </div>
      <div className="mt-6 px-12">
        <div>
          <div className="flex items-center justify-start w-full gap-5">
            <FuseSvgIcon className="text-48" size={20}>
              {FuseUtils.eventsEnum[event.type].icon}
            </FuseSvgIcon>
            <Typography variant="subtitle1">{event.name}</Typography>
          </div>
          <Typography className=" text-grey-600">{event.description}</Typography>
        </div>
        <div className="w-full py-4">
          <Divider />
        </div>
        <div className="flex gap-5 my-5">
          <Typography variant="caption" className="font-medium">
            {t('USER')}:
          </Typography>
          <Chip
            variant="filled"
            label={
              <div className="flex items-center justify-center gap-5 h-14 py-4 text-10">
                <FuseSvgIcon color="secondary" size={15}>
                  material-solid:account_circle
                </FuseSvgIcon>
                <div>
                  {event.user_data.name || event.user_data.last_name
                    ? `${event.user_data.name} ${event.user_data.last_name}`
                    : event.user_data.email}
                </div>
              </div>
            }
          />
        </div>

        <div className="flex items-center gap-5 my-5">
          <Typography variant="caption" className="font-medium">
            {t('ALERT')}:
          </Typography>
          <Chip
            variant="filled"
            component="a"
            href={`/ws/${wsId}/alerts/${event.alert_data.id}`}
            target="_blank"
            clickable
            label={
              <div className="flex items-center justify-center gap-5 h-14 py-4 text-10">
                <FuseSvgIcon color="secondary" size={15}>
                  {FuseUtils.alertsTypeEnum[event.alert_data.type].icon}
                </FuseSvgIcon>
                <div>{event.alert_data.name}</div>
              </div>
            }
          />
        </div>
        {event.client && (
          <div
            className="rounded overflow-hidden shadow-lg p-4 pb-6"
            style={{ backgroundColor: '#f1f5f9' }}
          >
            <Typography variant="caption" className="font-medium">
              {t('CLIENT')}
            </Typography>
            <div className="flex justify-center items-center gap-5">
              <FuseSvgIcon className="text-48" size={15} color="inherit">
                material-solid:open_in_new
              </FuseSvgIcon>
              <Typography className="text-center font-semibold" color="InfoText" variant="body2">
                <Link href={`/ws/${wsId}/clients/${event.client_data.id}`} target="_blank">
                  {event.client_data.name}
                </Link>
              </Typography>
            </div>

            <div className="flex flex-col ">
              <Typography color="InfoText" variant="caption">
                <b>{t('DESCRIPTION')}</b>:{event.client_data.description}
              </Typography>
              {event.client_data.location_mode !== 'through-client-places' && (
                <Typography color="InfoText" variant="caption">
                  <b>{t('ADDRESS')}</b>:{event.client_data.address}
                </Typography>
              )}

              <Typography color="InfoText" variant="caption">
                <b>{t('INVOICE_NAME')}</b>:{event.client_data.invoice_name}
              </Typography>
              <Typography color="InfoText" variant="caption">
                <b>{t('INVOICE_TAX')}</b>:{event.client_data.invoice_tax_identifier}
              </Typography>

              {event.client_data.teams_data.length > 0 && (
                <div className="flex items-center flex-wrap gap-2">
                  <Typography color="InfoText" variant="caption">
                    <b>{t('TEAMS')}</b>:
                  </Typography>
                  {event.client_data.teams_data.map((team) => (
                    <Chip
                      key={team.id}
                      color="secondary"
                      size="small"
                      label={
                        <div className="flex justify-center items-center gap-5">
                          <FuseSvgIcon className="text-48" size={12} color="inherit">
                            material-solid:open_in_new
                          </FuseSvgIcon>
                          <Link
                            className="font-bold !border-b-0"
                            href={`/ws/${wsId}/teams/${team.id}`}
                            target="_blank"
                          >
                            {team.name}
                          </Link>
                        </div>
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {/* <Typography variant="caption">{event.message}</Typography>

        <div className="w-full py-4">
          <Divider />
        </div>
        <div className="flex">
          <Typography className="font-medium text-10">{t('CREATION_DATE')}:</Typography>
          <Typography className="text-10">
            {format(parseISO(event?.date_created), 'dd/MM/y hh:mm aaaa ')}
          </Typography>
        </div>
        <div className="flex">
          <Typography variant="caption" className="font-medium text-10">
            {t('CHECKED_DATE')}:
          </Typography>
          <Typography className="text-10">
            {format(parseISO(event?.date_checked), 'dd/MM/y hh:mm aaaa ')}
          </Typography>
        </div> */}
        {checkRole('platform_support') && (
          <>
            <div className="flex">
              <Typography variant="caption" className="font-medium text-10">
                ID:
              </Typography>
              <Typography className="text-10">{event?.id}</Typography>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
}

export default EventsSidebarContent;
