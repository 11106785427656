import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import { selectGeofenceById } from '../geofenceSlice';

/**
 * GeofenceChip component to display a geofence chip from select data
 * @param {object} props
 * @param { String } props.id - the id of the geofence chip
 * @param { ('small'|'medium'|'large') } props.size - the size of the chip
 * @param { Object } props.chipProps - the props of the chip (MuiChip)
 * @param { String } props.className - the class name of the chip(TailwindCss)
 */
const GeofenceChip = ({ id, chipProps, size = 'medium' }) => {
  const geofence = useSelector((state) => selectGeofenceById(state, id));

  return (
    <Chip
      {...chipProps}
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        '& .MuiChip-deleteIcon': {
          color: theme.palette.secondary.contrastText,
          fontSize: 15,
        },
      })}
      variant="outlined"
      label={geofence?.name}
      id={id}
      key={id}
      size={size}
    />
  );
};

export default GeofenceChip;
