/* eslint-disable camelcase */
import Fab from '@mui/material/Fab';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import MapFieldPosition from '@fuse/core/FuseMaps/MapFieldPosition';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { selectWorkspace } from 'app/store/workspaceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  addClient,
  selectClientById,
  selectClientData,
  setClient,
  setRightSidebarProps,
  syncClientPlaces,
} from '../../../store/clientsSlice';

/**
 * component to show the location of the client
 * @returns {JSX.Element}
 */
function LocationClient() {
  /**
   * @constant {object} wsId - the workspace id
   * @constant {object} clientId - the client id
   * @constant {object} positionMap - the position of the map
   * @constant {function} checkPermission - the permission checker function
   * @constant {object} client - the client object
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {function} navigate - the navigate function from the react-router-dom
   * @constant {object} aux_data - the aux data of the workspace
   * @constant {object} useFormContext - the useFormContext from the react-hook-form
   * @constant {boolean} editMode - if the user is in edit mode
   * @constant {boolean} IsLoading - if the component is loading
   * @constant {object} errors - the errors of the form
   * @constant {boolean} isValid - if the form is valid
   * @constant {function} t - the translation function
   */
  const { wsId, clientId } = useParams();
  const [positionMap, setPositionMap] = useState({ coords: [0, 0], zoom: 18 });
  const checkPermission = useSelector(permissionChecker);
  const client = useSelector((state) => selectClientById(state, clientId));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { aux_data } = useSelector(selectWorkspace);
  const { control, getValues, reset, setValue, formState } = useFormContext();
  const { editMode, IsLoading } = useSelector(selectClientData).rightSiderbar;
  const { errors, isValid } = formState;
  const { t } = useTranslation('managementApp');

  /**
   * open the edit mode
   */
  const onEdit = () => {
    reset(client);
    dispatch(setRightSidebarProps({ editMode: true }));
  };

  /**
   * change the position of the map of the client
   */
  const onChangePosition = (data) => {
    setPositionMap(data);
    setValue('latitude', data.coords[0]);
    setValue('longitude', data.coords[1]);
    setValue('location_mode', 'through-client-coordinate');
  };

  /**
   * change the location mode of the client
   */
  const onChangeLocationMode = () => {
    dispatch(setRightSidebarProps({ tab: 'places' }));

    return 'through-client-places';
  };

  /**
   * save the client
   */
  const saveClient = async () => {
    dispatch(setRightSidebarProps({ IsLoading: true }));

    const clientData = getValues();

    await dispatch(setClient({ wsId, client: clientData })).unwrap();
    if (clientData.places_data)
      await dispatch(
        syncClientPlaces({ wsId, clientId: clientData.id, places_data: clientData.places_data })
      );

    dispatch(setRightSidebarProps({ IsLoading: false, editMode: false }));
  };

  /**
   * create a client
   */
  const createClient = async () => {
    dispatch(setRightSidebarProps({ IsLoading: true }));

    const clientData = getValues();

    try {
      const [{ id }] = await dispatch(addClient({ wsId, client: clientData })).unwrap();
      if (clientData.places_data)
        await dispatch(
          syncClientPlaces({ wsId, clientId: id, places_data: clientData.places_data })
        );
      navigate(`../${id}${window.location.search}`, { replace: true });
      dispatch(setRightSidebarProps({ IsLoading: false, editMode: false }));
    } catch (error) {
      dispatch(setRightSidebarProps({ IsLoading: false, editMode: true }));
    }
  };

  /**
   * change the tab to map
   * @param {object} e - the event
   * @param {string} newTab - the new tab
   */
  const onChangeTabMap = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'map' }));
    setValue('location_mode', 'through-client-coordinate');
  };

  /**
   * change the tab to places
   * @param {object} e - the event
   * @param {string} newTab - the new tab
   */
  const onChangeTabPlaces = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'places' }));
    setValue('location_mode', 'through-client-places');
  };

  /**
   * change the tab to unknown
   * @param {object} e - the event
   * @param {string} newTab - the new tab
   */
  const onChangeTabUnknown = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'no_location' }));
    setValue('location_mode', '');
  };

  /**
   * set the position of the map
   */
  useEffect(() => {
    if (clientId === 'new') {
      setPositionMap({
        coords: [aux_data.coordinates.latitude, aux_data.coordinates.longitude],
        zoom: aux_data.coordinates.zoom,
        setPosition: false,
      });
    } else if (client?.longitude !== undefined && client?.latitude !== undefined) {
      setPositionMap({
        coords: [client.latitude, client.longitude],
        zoom: positionMap.zoom,
        setPosition: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, clientId]);

  if (IsLoading) {
    return (
      <div className="h-full flex-1 flex items-center">
        <FuseLoading />
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1 w-full h-full">
      {editMode ? (
        <div className=" my-8 mx-4">
          {/* <Controller
            name="location_mode"
            control={control}
            render={({ field: { onChange, value, onBlur, ref } }) => (
              <FormControl>
                <FormControlLabel
                  label={t('CLIENT_MORE_ONE_LOCATION')}
                  control={
                    <Checkbox
                      checked={value === 'through-client-places'}
                      onBlur={onBlur}
                      onChange={(ev) => onChange(onChangeLocationMode())}
                      inputRef={ref}
                    />
                  }
                />
                <FormHelperText>{t('IF_CLIENT_MULTIPLE_LOCATION')}</FormHelperText>
              </FormControl>
            )}
          /> */}

          <div className="flex justify-end py-5">
            <ButtonGroup disableElevationcolor="secondary" size="small">
              <Button
                variant="outlined"
                className="gap-5 flex justify-start"
                onClick={onChangeTabUnknown}
              >
                <FuseSvgIcon size={18}>material-solid:location_off</FuseSvgIcon>
                <Typography className="text-12">{t('UNKNOWN')}</Typography>
              </Button>

              <Button
                variant="contained"
                className="gap-5 flex justify-start"
                onClick={onChangeTabMap}
              >
                <FuseSvgIcon size={18}>material-solid:location_on</FuseSvgIcon>
                <Typography className="text-12">{t('SINGLE_LOCATION')}</Typography>
              </Button>

              <Button
                variant="outlined"
                className="gap-5 flex justify-start"
                onClick={onChangeTabPlaces}
              >
                <FuseSvgIcon size={18}>material-solid:location_city</FuseSvgIcon>
                <Typography className="text-12">{t('MANY_LOCATION')}</Typography>
              </Button>
            </ButtonGroup>
          </div>
          <div className="flex items-center justify-between gap-5 ">
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!checkPermission('edit_client')}
                  label={t('ADDRESS')}
                  error={!!errors.address}
                  helperText={errors?.address?.message}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
            />
            {checkPermission('edit_client') && (
              <Fab
                onClick={clientId === 'new' ? createClient : saveClient}
                size="small"
                color="secondary"
                aria-label="save"
                disabled={!isValid}
              >
                <FuseSvgIcon className="text-white" size={20}>
                  material-solid:save
                </FuseSvgIcon>
              </Fab>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between py-8">
          <Typography variant="body2">
            <strong>{t('ADDRESS')}: </strong>
            {client.address}
          </Typography>
          {checkPermission('edit_client') && (
            <Fab onClick={onEdit} size="small" color="secondary" aria-label="add">
              <FuseSvgIcon className="text-white" size={20}>
                heroicons-solid:pencil
              </FuseSvgIcon>
            </Fab>
          )}
        </div>
      )}

      <div className="flex-auto h-0">
        <MapFieldPosition
          positionMap={positionMap}
          showPlacesMakers
          setPositionMap={onChangePosition}
          locationMarker={client ? [client.latitude, client.longitude] : [0, 0]}
          isEditing={editMode}
          iconType="client"
          scrollable
        />
      </div>
    </div>
  );
}

export default LocationClient;
