/**
 * this file will contain all the translations in English
 */
const locale = {
  ACCEPT: 'Accept',
  ACCURACY: 'Accuracy',
  ACCURACY_MEASUREMENT: 'Accuracy: ~ {{accuracy}} meters',
  ACTIVE_TODAY: 'With activity today',
  ADD_FILTER_TO_SHOW_FILTERS: 'Add filters by date range, tags or user',
  ADD_ROUTE: 'Add route',
  ADD_ROUTE_TO_SHOW_ROUTES: 'Add routes filtered by date range',
  ADDRESS: 'Address',
  ALERT_APPLIED: 'Alert applied',
  ALERT_APPLIES_TO_TEAMS: 'The alert applies to the following teams',
  ALERT_APPLIES_TO_USERS: 'The alert applies to the following users',
  ALERT_DETAILS: 'Alert Details',
  ALERT_FOR_ALL: 'The alert is for all users',
  ALERTS: 'Alerts',
  APP_VERSION: 'App version',
  APPLY: 'Apply',
  APPLY_FILTERS: 'Apply filters',
  ARE_SURE_REMOVE_FILTERS: 'Are you sure to remove all filters?',
  ARE_SURE_REMOVE_GEOFENCE: 'Are you sure to remove Geofence "{{geofence}}"?',
  ARE_SURE_REMOVE_ROUTES: 'Are you sure to reset all filters for user {{user}}?',
  ARE_SURE_SAVE_GEOFENCE:
    'Are you sure you want to save this geofence without assigning an alert to it?',
  ARRIVE_DATE: 'Entry date',
  ASSIGNMENTS: 'Tasks',
  AUXILIARY_OFFICE: 'Auxiliary Office',
  AVAILABLE: 'Available',
  AVERAGE_SPEED: 'Average Speed',
  BRANCH_OFFICE: 'Branch',
  CANCEL: 'Cancel',
  CHANGE_COLOR: 'Change color',
  CHARGING: 'Charging',
  CHECK_IN_OUT_DATE: 'Date of visit',
  CHECK_IN_OUTS: 'Visits',
  CHECK_IN_OUTS_TO_GEOFENCES: 'Visits to geofences',
  CLIENT: 'Client',
  CLIENTS: 'Clients',
  CONSTRUCTION_SITES: 'Construction sites',
  COORDINATES: 'Coordinates',
  COORDINATES_COPIED_TO_CLIPBOARD: 'Coordinates copied to clipboard',
  COPY: 'Copy',
  COPY_PASTE_EXCEL: 'Copy and paste your coordinates from an Excel workbook or spreadsheet',
  CREATE: 'Create',
  CREATE_A_NEW_ALERT: 'Create a new alert',
  CREATE_GEOFENCE_HERE: 'Create geofence here',
  CREATE_POINT_HERE: 'Create a point here',
  CREATED: 'Created',
  CREATION_DATE: 'Creation date',
  CUSTOMIZE_FILTERS: 'Customize filters',
  DAILY_RECORD: 'Daily record',
  DAILY_REPORT: 'Daily report',
  DATE: 'Date',
  DATE_CREATED: 'Date created',
  DATE_DONE: 'Date done',
  DATE_DUE: 'Due date',
  DATE_END: 'To date',
  DATE_EVENT: 'Event date',
  DATE_START: 'From date',
  DATE_STATUS: 'State change date',
  DELETE_FIGURE: 'delete figure',
  DELETE_POINT: 'Delete point',
  DEPARTURE_DATE: 'Departure date',
  DESCRIPTION: 'Description',
  DETAILS: 'Details',
  DISTANCE: 'Distance',
  DISTANCE_AUX_ONE: 'Auxiliary Distance',
  DISTANCE_TRAVELED: 'Distance traveled: {{distance}} kilometers',
  DISTANCE_TRAVELED_IN_THIS_POINT: 'At this point, user has traveled {{km}} Km that day',
  DISTANCE_TRAVELED_POINT: 'Distance traveled at this point',
  DONE_TODAY: 'Done today',
  DOWNLOAD_CSV_FILE: 'Download CSV file',
  DOWNLOAD_ERROR: 'Download Error',
  DOWNLOAD_XLSX_FILE: 'Download Excel file',
  DRAW_CIRCLE: 'draw circle',
  DRAW_HANDLERS_CIRCLE_RADIUS: 'Radius',
  DRAW_HANDLERS_CIRCLE_TOOLTIP_START: 'Click and drag to draw a circle.',
  DRAW_HANDLERS_POLYGON_TOOLTIP_CONT: 'Click to continue drawing the polygon',
  DRAW_HANDLERS_POLYGON_TOOLTIP_END: 'Click on the first point to close the polygon.',
  DRAW_HANDLERS_POLYGON_TOOLTIP_START: 'Click to start drawing the polygon.',
  DRAW_HANDLERS_SIMPLESHAPE_TOOLTIP_END: 'Release mouse to end circle.',
  DRAW_POLYGON: 'draw polygon',
  DRAW_TOOLBAR_ACTIONS_TEXT: 'Cancel',
  DRAW_TOOLBAR_ACTIONS_TITLE: 'Cancel drawing',
  DRAW_TOOLBAR_BUTTONS_CIRCLE: 'Draw a circle',
  DRAW_TOOLBAR_BUTTONS_POLYGON: 'Draw a polygon',
  DRAW_TOOLBAR_FINISH_TEXT: 'Finish',
  DRAW_TOOLBAR_FINISH_TITLE: 'Finish drawing',
  DRAW_TOOLBAR_UNDO_TEXT: 'Delete last point',
  DRAW_TOOLBAR_UNDO_TITLE: 'Delete last drawn point',
  DURATION: 'Duration',
  EDIT: 'Edit',
  EDITION_DATE: 'Edition date',
  EMAIL_VALID: 'Ingrese un correo electrónico válido',
  ENTRY_DATE: 'Entry date',
  ERROR_SEARCH:
    'Indicate what you want to search using the words "user" or "geofence". For example "user: Carl Sagan" or "user: carl.sagan@cosmos.com" or "geofence: name of a geofence"',
  FACTORY: 'Factory',
  FAKE_GPS: 'fake GPS',
  FILTER_ALERTS: 'Filter alerts',
  FILTER_ALERTS_MAX_SPEED: 'Maximum speed alerts will be filtered',
  FILTER_ASSIGNMENTS: 'Filter assignments',
  FILTER_BY_TAGS: 'Filter by tags',
  FILTER_CHECK_IN_OUTS: 'Filter visits',
  FILTER_DAILY_RECORD: 'Filter daily reports',
  FILTER_GEOFENCES_RECORDS: 'Filter inputs and outputs',
  FILTER_ROUTES: 'Filter routes',
  FILTER_TO_SHOW_ALERTS: 'Filter alerts by date range or user',
  FILTER_TO_SHOW_ASSIGNMENTS: 'Filter assignments by user, tags, date ranges and client',
  FILTER_TO_SHOW_DAILY_RECORD: 'Filter daily reports by date range and, user or group',
  FILTER_TO_SHOW_GEOFENCES_RECORDS: 'Filter by geofences, users and date to show entries and exits',
  FILTERS_APPLIED: 'Filters applied',
  FROM_DATE_ASSIGNMENTS: 'Assignments from {{date}}',
  FROM_DATE_CHECK_IN_OUTS: 'Visits from {{date}}',
  FROM_DATE_GEOFENCES: 'Geofences from {{date}}',
  FROM_DATE_REPORTS: 'Reports from {{date}}',
  FROM_DATE_USER_LOCATION_REGISTER: 'Travel on {{date}}',
  Fulfillments: 'Fulfillments',
  GEO_ALERT: 'Geographical alert',
  GEOFENCE: 'Geofence',
  GEOFENCE_NAME: 'Geofence name',
  GEOFENCE_WITHOUT_ALERT: 'Geofence without alert',
  GEOFENCES: 'Geofences',
  HEADQUARTERS: 'Headquarters',
  HIDE_FIGURE: 'Hide figure',
  HIDE_MARKER: 'Hide marker',
  HIDE_MEASUREMENT_POINT: 'Hide measurement points',
  HIDE_ON_MAP: 'Hide on map',
  HIDE_PATH: 'Hide tracking',
  HIDE_ROUTE_OF_THIS_DAY: 'Hide route of this day',
  IN_VEHICLE: 'Moving in vehicle',
  INPUTS_OUTPUTS: 'Inputs and outputs',
  INPUTS_OUTPUTS_GEOFENCES: 'Geofences inputs and outputs',
  INTERNAL_CODE: 'Internal code',
  INVALID_DATE: 'Invalid date',
  INVOICE_TAX: 'Tax code',
  IS_MOVING: 'Is moving',
  IS_STOPPED: 'Is stopped',
  JUST_MAP: 'Just the map',
  LATITUDE: 'Latitude',
  LEAVE_DATE: 'Leave date',
  LEVEL_BATTERY: 'Battery level: {{level}} %',
  LIST_POINT: 'List of points',
  LOCATE_ON_MAP: 'Locate it on the map',
  LOCATION: 'Location',
  LONGITUDE: 'Longitude',
  MANAGE_USERS_ON_THE_MAP: 'Manage users on the map',
  MAP: 'Map',
  MAP_SETTING: 'Map settings',
  MAP_TYPE: 'Map type',
  MAX_SPEED: 'Max speed: {{speed}} Km/h',
  MAXIMUM_SPEED: 'Maximum speed',
  MEASUREMENT_DATE: 'Measurement date',
  NAME: 'Name',
  NAME_CANNOT_EMPTY: 'El campo nombre no puede estar vacío',
  NO_ACTIVE_TODAY: 'No activity today',
  NO_ACTIVE_TWO_DAYS: 'No activity for more than two days',
  NO_ALERTS_FOUND: 'No alerts found',
  NO_ASSIGNMENTS_FOUND: 'Assignments not found',
  NO_CHECK_IN_OUTS_FOUND: 'No visits found',
  NO_CLIENT_SELECTED: 'No client selected',
  NO_CLIENTS_TO_SHOW: 'There are no clients to show',
  NO_GEOFENCES_FOUND: 'No geofences found',
  NO_GEOFENCES_TO_SHOW: 'no geofences to show',
  NO_MEASUREMENTS_FOR_THESE_DATES: 'There were no activities for these dates ({{dates}})',
  NO_OPTION: 'No options',
  NO_TRACKING: '{{name}} has not logged activity today',
  NO_USERS_FOUND: 'No users found',
  NO_USERS_TO_SHOW: 'There are no users to show',
  NO_VEHICLES_FOUND: 'No vehicles found',
  NOTIFICATION_BE_SEND_EMAILS: 'A notification will be sent to the following emails',
  NOTIFY_EMAIL: 'Notify by email',
  NOTIFY_PLATFORM: 'Notify in OptaCheck',
  OFF: 'Off',
  'OFF/ON': 'On',
  ON: 'On',
  ON_BICYCLE: 'Moving on a bicycle',
  ON_FOOT: 'Moving on foot',
  ONLY_DONE_ASSIGNMENT_LOCATED: 'Only tasks with status "Complete" can be located',
  OTHERS: 'Others',
  PARKING_LOT: 'Parking lot',
  PLACES: 'Places',
  POINT_TO_SHOW: 'Points to show on the map',
  REMOVE: 'Remove',
  REMOVE_FILTERS: 'Remove filters',
  REMOVE_GEOFENCE: 'Remove Geofence',
  REMOVE_ROUTES: 'Remove routes',
  ROUTES: 'Tracking',
  RUNNING: 'Running',
  SAVE: 'Save',
  SEARCH: 'Search',
  SEARCH_PLACE: 'Search places in the world',
  SEE_CLIENTS: 'See clients',
  SEE_CLIENTS_WITHOUT_LOCATION: 'See clients without location',
  SELECT_CLIENT: 'Select client',
  SELECT_USER_TO_SHOW_ROUTES: 'Select a user to show their routes',
  SELECT_USERS_GEOFENCES: 'Select users and geofences',
  SELLER: 'Seller',
  SHOW_CHECK_IN_OUT: 'Show visit',
  SHOW_CHECK_IN_OUTS: 'Show visits',
  SHOW_DETAILS: 'Show details',
  SHOW_FIGURE: 'Show figure',
  SHOW_HIDE_MEASUREMENT_POINT: 'Hide/Show measurement points',
  SHOW_MARKER: 'Show marker',
  SHOW_MEASUREMENT_POINT: 'Show measurement points',
  SHOW_ON_MAP: 'Show on map',
  SHOW_ROUTE_OF_THIS_DAY: 'Show route of this day',
  SPEED: 'Speed',
  STATUS: 'Status',
  STILL: 'Stopped',
  STORE: 'Store',
  TAGS: 'Tags',
  TEAM: 'Team',
  TEAMS: 'Teams',
  THIS_WEEK: 'This week',
  TIME_AGO: '{{time}} ago',
  TITLE: 'Title',
  TODAY: 'Today',
  TODAY_ASSIGNMENTS: "Today's Assignments",
  TODAY_CHECK_IN_OUTS: "Today's visits",
  TODAY_GEOFENCES: "Today's geofences",
  TODAY_REPORTS: "Today's reports",
  TODAY_USER_LOCATION_REGISTER: "Today's trips",
  TOOLS: 'Tools',
  TRACKING: 'Tracking',
  UPLOAD_KML: 'Upload KML file',
  USER: 'User',
  USER_LOCATION: 'User location',
  USERS: 'Users',
  VEHICLE_LOCATION: 'Vehicle location',
  VISITS_TODAY: " Today's visits ",
  WALKING: 'Walking',
  WAREHOUSE: 'Warehouse',
  WHAT_DO_MAP: 'What do you want to see on the map?',
  WITH_LOCATION: 'With location',
  WITHCOORDS_DESCR: 'These are the clients that have an assigned location',
  WITHOUT_LOCATION: 'Without location',
  WITHOUTCOORDS_DESCR:
    'These are the clients that do not have an assigned location, you can indicate their location in the edition of each one',
  YESTERDAY: 'Yesterday',
};

export default locale;
