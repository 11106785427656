import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

/** list of projects to be used by select forms */

const projectsAdapter = createEntityAdapter({});

export const { selectAll: selectProjects, selectById: selectProjectById } =
  projectsAdapter.getSelectors((state) => state.selectsData.projects);

const initialState = projectsAdapter.getInitialState({
  isProjectsLoaded: false,
});

/** get all the projects to select data */
export const getProjectsAll = createAsyncThunk(
  'selectsData/projects/getProjectsAll',
  /**
   * @param {string} wsId the workspace id
   */
  async (wsId, { dispatch, getState }) => {
    const { isProjectsLoaded } = getState().selectsData.projects;
    if (!isProjectsLoaded) {
      const { data } = await axios.get(`${ApiRoutes.projectsEndPoint(wsId)}all/`);

      dispatch(setIsProjectsLoaded(true));

      return data;
    }
    return [];
  }
);

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    /** change the indicator to know if the `projects` are loaded */
    setIsProjectsLoaded: (state, action) => {
      state.isProjectsLoaded = action.payload;
    },
    /** reset the `projects` state */
    resetProjectsState: () => initialState,
  },
  extraReducers: {
    [getProjectsAll.fulfilled]: projectsAdapter.upsertMany,
  },
});

export const { setIsProjectsLoaded, resetProjectsState } = projectsSlice.actions;

export default projectsSlice.reducer;
