import Button from '@mui/material/Button';
import CountrySelector from '@fuse/core/FuseCountry/CountrySelector';
import Divider from '@mui/material/Divider';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import _ from '@lodash';
import { Controller, useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { getCountriesAll } from 'app/store/selectsData/countrySlice';
import { getOrgBilling, selectOrganization, setOrgBilling } from 'app/store/organizationSlice';
import { roleChecker } from 'app/store/userSlice';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  height: '90%',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  overflowY: 'auto',
}));

const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  gap: 8,
  paddingLeft: 8,
  paddingRight: 8,
  width: '100%',
  height: '10%',
}));

const Outlinebox = styled(TextField)(() => ({
  '.MuiOutlinedInput-input': {
    width: 0,
  },
}));

/** component to display the billing information of a  organization */
function BillingTab() {
  /**
   * @constant {boolean} isLoading - boolean to check if the data is loading
   * @constant {function} checkRole - function to check the user role
   * @constant {function} dispatch - redux dispatch function
   * @constant {object} organization - organization object
   * @constant {function} t - i18n translation function
   */
  const [isLoading, setIsLoading] = useState(true);
  const checkRole = useSelector(roleChecker);
  const dispatch = useDispatch();
  const organization = useSelector(selectOrganization);
  const { t } = useTranslation('organizationApp');

  const methods = useForm({
    mode: 'onChange',
  });
  const { control, getValues, reset } = methods;

  /**  function to save the organization billing information */
  const saveOrganization = () => dispatch(setOrgBilling({ ...organization, billing: getValues() }));

  /** effect to load the data in the form, and get the billing information if is nessesary */
  useEffect(() => {
    const loadData = async () => {
      await dispatch(getCountriesAll());
      if (_.isEmpty(organization.billing)) {
        const {
          payload: { billing },
        } = await dispatch(getOrgBilling(organization));
        reset(billing);
      } else {
        reset(organization.billing);
      }
      setIsLoading(false);
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reset]);

  if (isLoading) {
    return (
      <div className="file-details flex flex-col flex-auto  h-fullVH w-fullVW sm:h-full sm:w-full">
        <FuseLoading />
      </div>
    );
  }

  return (
    <>
      <Content>
        <form
          name="organizationBillingForm"
          noValidate
          className="flex flex-col justify-center w-full pb-24 px-12"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Divider className="my-20" spacing={1}>
            <Typography variant="subtitle1">{t('TAX_INFORMATION')}</Typography>
          </Divider>
          <Controller
            control={control}
            name="country"
            render={({ field: _field }) => (
              <Outlinebox
                label={t('COUNTRY')}
                className="pb-24"
                nonce="true"
                InputProps={{
                  startAdornment: (
                    <CountrySelector
                      {..._field}
                      value={_field.value ? _field.value : organization.country}
                    />
                  ),
                  style: {
                    boxSizing: 'border-box',
                  },
                }}
              />
            )}
          />
          <Controller
            name="tax_identification"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="pb-24"
                label={t('TAX_IDENTIFICATION')}
                autoFocus
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Controller
            name="company_legal_name"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t('COMPANY_LEGAL_NAME')} variant="outlined" fullWidth />
            )}
          />
          <Divider className="my-20" spacing={1}>
            <Typography variant="subtitle1">{t('LEGAL_ADDRESS')}</Typography>
          </Divider>
          <Controller
            name="address_line_one"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="pb-24"
                label={t('ADDRESS_LINE_ONE')}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="address_line_two"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="pb-24"
                label={t('ADDRESS_LINE_TWO')}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="pb-24"
                label={t('CITY')}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="state_or_province"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="pb-24"
                label={t('STATE_OR_PROVINCE')}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="zip_code"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="pb-24"
                label={t('ZIP_CODE')}
                variant="outlined"
                fullWidth
              />
            )}
          />
          {checkRole('platform_support') && (
            <>
              <div className="py-8">
                <div className="w-full py-10">
                  <Divider />
                </div>
                {organization.billing?.date_created && (
                  <div>
                    <Typography variant="caption" className="font-medium">
                      {t('CREATION_DATE')}:
                    </Typography>
                    <Typography variant="caption">
                      {format(parseISO(organization.billing?.date_created), 'dd/MM/y hh:mm aaaa ')}
                    </Typography>
                  </div>
                )}
                {organization.billing?.date_edited && (
                  <div>
                    <Typography variant="caption" className="font-medium">
                      {t('EDITION_DATE')}:
                    </Typography>
                    <Typography variant="caption">
                      {format(parseISO(organization.billing?.date_edited), 'dd/MM/y hh:mm aaaa ')}
                    </Typography>
                  </div>
                )}
                <div>
                  <Typography variant="caption" className="font-medium">
                    ID:
                  </Typography>
                  <Typography variant="caption">{organization.billing?.id}</Typography>
                </div>
              </div>
            </>
          )}
        </form>
      </Content>
      <Footer>
        <Button
          className="flex-auto "
          color="secondary"
          variant="contained"
          onClick={saveOrganization}
        >
          {t('SAVE')}
        </Button>
      </Footer>
    </>
  );
}

export default BillingTab;
