import { combineReducers } from '@reduxjs/toolkit';
import users, { resetUserState } from './usersSlice';
import teams, { resetTeamState } from './teamsSlice';
import projects, { resetProjectsState } from './projectsSlice';
import countries from './countrySlice';
import roles, { resetRolesState } from './rolesSlice';
import permissions from './permissionsSlice';
import tags, { resetTagsState } from './tagsSlice';
import alerts, { resetAlertsState } from './alertsSlices';
import clients, { resetClientState } from './clientsSlice';
import forms, { resetFormsState } from './formsSlice';
import geofences, { resetGeofenceState } from './geofenceSlice';

/** combine all the select data reducers */
const fuseReducers = combineReducers({
  users,
  teams,
  projects,
  roles,
  tags,
  permissions,
  countries,
  clients,
  alerts,
  geofences,
  forms,
});

/** reset all the select data state */
export const resetSelectDataState = (dispatch) => {
  dispatch(resetUserState());
  dispatch(resetTeamState());
  dispatch(resetProjectsState());
  dispatch(resetRolesState());
  dispatch(resetTagsState());
  dispatch(resetAlertsState());
  dispatch(resetClientState());
  dispatch(resetGeofenceState());
  dispatch(resetFormsState());
  // country should'nt be reset, because country data are constant
  // optacheck permission should'nt be reset, because optacheck permission data are constant
};

export default fuseReducers;
