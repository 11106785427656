import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Rollbar from 'app/services/Rollbar';
import ApiRoutes from '../services/ApiRoutes';
import { changeMap } from './fuse/mapSlice';
import { getUserWorkspacePermissions } from './userWorkspacePermissionsSlice';
import Socket from '../services/Socket';
// eslint-disable-next-line import/no-cycle
import { changeLanguage } from './i18nSlice';
import { resetLocationState } from '../pages/modulesApp/locationApp/store';
// eslint-disable-next-line import/no-cycle
import { resetManagementState } from '../pages/modulesApp/managementApp/store';
import { resetSelectDataState } from './selectsData';
import { resetAssignmentsState } from '../pages/modulesApp/assignmentsApp/store';

export const accessToWorkspace = createAsyncThunk(
  'workspace/accessToWorkspace',
  async (ws, { dispatch, getState }) => {
    const { user } = getState();
    const { data } = await axios.get(ApiRoutes.workspaceEndPoint() + ws);

    if (!user.language && data.language) dispatch(changeLanguage(data.language));

    if (data.modules?.tracking?.map_types?.length > 0)
      dispatch(changeMap(data.modules.tracking.map_types[0]));

    await dispatch(getUserWorkspacePermissions(data.id)).unwrap();

    // reset map data
    resetLocationState(dispatch);
    resetManagementState(dispatch);
    resetSelectDataState(dispatch);
    resetAssignmentsState(dispatch);

    Rollbar.setWorkspace(data);

    Socket.connectWorkspace(data.shortid, user.id).then(console.log).catch(console.error);

    return data;
  }
);

export const getWorkspaceData = createAsyncThunk(
  'workspace/getWorkspaceData',
  async (ws, { dispatch, getState }) => {
    const { data } = await axios.get(ApiRoutes.workspaceEndPoint() + ws);
    return data;
  }
);

export const selectWorkspace = ({ workspace }) => workspace;

export const selectWorkspaceAux = ({ workspace }) => workspace?.aux_data;

const workspacesSlice = createSlice({
  name: 'workspace',
  initialState: {},
  reducers: {
    workspaceReset: (state, action) => ({}),
  },
  extraReducers: {
    [accessToWorkspace.fulfilled]: (state, action) => action.payload,
    [getWorkspaceData.fulfilled]: (state, action) => action.payload,
  },
});

export const moduleChecker =
  ({ workspace }) =>
  (module) =>
    Boolean(workspace?.modules[module]?.active);

export const { workspaceReset } = workspacesSlice.actions;

export default workspacesSlice.reducer;
