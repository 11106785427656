import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  closeDuplicateFormConfirmModal,
  duplicateForm,
  selectFormById,
  formSelector,
} from '../../store/formsSlice';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const DuplicateFormConfirm = () => {
  /**
   * @constant {boolean} isLoading - flag to show the loading spinner
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {function} navigate - the function to navigate to another page
   * @constant {function} t - the translation function
   * @constant {object} wsId - the workspace id
   */
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('assignmentsApp');
  const { wsId } = useParams();

  /**
   * @constant {object} duplicateFormConfirmProps - the props of the modal
   */
  const {
    duplicateFormConfirmProps: { open, formId },
  } = useSelector(formSelector);
  const form = useSelector((state) => selectFormById(state, formId));

  /** close the modal */
  const onClose = () => dispatch(closeDuplicateFormConfirmModal());

  /** duplicate the form and close the modal */
  const duplicateFormOnClick = async () => {
    setIsLoading(true);
    const newFormId = uuidv4();
    await dispatch(duplicateForm({ wsId, formId, newFormId }));

    await navigate(`/ws/${wsId}/forms/${newFormId}${window.location.search}`, {
      // replace: true,
    });

    setIsLoading(false);
    onClose();
  };

  if (isLoading) {
    return (
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <FuseLoading />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <div className="flex justify-end">
          <IconButton color="inherit" onClick={onClose}>
            <FuseSvgIcon size={18}>heroicons-solid:x</FuseSvgIcon>
          </IconButton>
        </div>
        <div className="mx-16">
          <Typography variant="subtitle1" color="inherit">
            {t('DUPLICATE_FORM')}
          </Typography>
          <Typography variant="caption" className="text-justify">
            {t('ARE_SURE_DUPLICATE_FORM', { name: form?.name })}
          </Typography>
        </div>
        <div className="flex justify-end my-8 mx-16 ">
          <Button
            size="small"
            className="
            mx-2"
            color="secondary"
            variant="contained"
            onClick={duplicateFormOnClick}
          >
            {t('ACCEPT')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DuplicateFormConfirm;
