import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

/** list of roles to be used by select forms */

const rolesAdapter = createEntityAdapter({});

/** funtion to select the roles by a search text */
export const selectSearchText = ({ selectsData }) => selectsData.roles.searchText;

export const selectRoles = ({
  selectsData: {
    roles: { entities },
  },
}) => Object.keys(entities).map((key) => entities[key]);

export const { selectById: selectRoleById } = rolesAdapter.getSelectors(
  ({ selectsData }) => selectsData.roles
);

const initialState = rolesAdapter.getInitialState({
  isRolesLoaded: false,
});

/** get all the roles to select data */
export const getRoles = createAsyncThunk(
  'selectsData/roles/getRoles',
  /**
   * @param {Object} params
   * @param {string} params.wsId the workspace id
   */
  async (params, { dispatch, getState }) => {
    const response = await axios.get(`${ApiRoutes.rolesListAll(params.wsId)}all/`);
    const data = await response.data;

    dispatch(setIsRolesLoaded(true));

    return data;
  }
);

const rolesSlice = createSlice({
  name: 'selectsData/roles',
  initialState,
  reducers: {
    /** change the indicator to know if the `roles` are loaded */
    setIsRolesLoaded: (state, action) => {
      state.isRolesLoaded = action.payload;
    },
    /** reset the `roles` state */
    resetRolesState: () => initialState,
  },
  extraReducers: {
    [getRoles.fulfilled]: rolesAdapter.setAll,
  },
});

export const { setIsRolesLoaded, resetRolesState } = rolesSlice.actions;

export default rolesSlice.reducer;
