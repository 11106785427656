import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SettingsTab from './tabs/SettingsTab';
import ContactsTab from './tabs/ContactsTab';
import BillingTab from './tabs/BillingTab';
import UsersTab from './tabs/UsersTab';

const Header = styled('div')(({ theme }) => ({
  width: '100%',
  borderBottomWidth: '1px',
  display: 'flex',
  justifyContent: 'space-between',
}));

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  height: '90%',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
}));

function OrganizationSidebarContent(props) {
  const navigate = useNavigate();
  const { t } = useTranslation('organizationApp');
  const { settings } = useParams();

  const onChangeTab = (e, tab) => navigate(`../${tab}`);
  const onClose = () => navigate('..');

  return (
    <div className=" file-details flex flex-col justify-around h-fullVH w-fullVW sm:h-full sm:w-full">
      <Header>
        <Tabs
          value={settings}
          onChange={onChangeTab}
          indicatorColor="secondary"
          textColor="secondary"
          className="h-full"
        >
          <Tab className="h-full" label={t('INFORMATION')} value="settings" />
          <Tab className="h-full" label={t('BILLING')} value="billing" />
          <Tab className="h-full" label={t('CONTACTS')} value="contact" />
          <Tab className="h-full" label={t('USERS')} value="users" />
        </Tabs>
        <IconButton onClick={onClose} className="text-48 mr-4 self-center">
          <FuseSvgIcon size={24} color="action">
            heroicons-solid:x
          </FuseSvgIcon>
        </IconButton>
      </Header>
      <Content>
        {settings === 'settings' && <SettingsTab />}

        {settings === 'billing' && <BillingTab />}

        {settings === 'contact' && <ContactsTab />}

        {settings === 'users' && <UsersTab />}
      </Content>
    </div>
  );
}

export default OrganizationSidebarContent;
