import ApiRoutes from 'src/app/services/ApiRoutes';
import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

const rolesAdapter = createEntityAdapter({});

export const { selectAll: selectRoles, selectById: selectRoleById } = rolesAdapter.getSelectors(
  (state) => state.managementApp.roles
);
export const selectSearchText = ({ managementApp }) => managementApp.roles.searchText;

export const selectFilteredRoles = createSelector(
  [selectRoles, selectSearchText],
  (roles, searchText) => {
    const metaRoles = roles.map((role) => ({
      id: role.id,
      name: role.name,
      permissionsCount: role.permissions.length,
      usersCount: role.users.length,
    }));
    if (searchText.length === 0) {
      return metaRoles;
    }
    return FuseUtils.filterArrayByString(metaRoles, searchText);
  }
);

/**
 * get roles from the server
 */
export const getRoles = createAsyncThunk(
  'managementApp/roles/getRoles',
  async ({ wsId }, { dispatch, getState }) => {
    const response = await axios.get(`${ApiRoutes.rolesEndPoint(wsId)}all-out/`);
    const { data: superUsers } = await axios.get(ApiRoutes.getSuperUsers(wsId));

    const superUsersItem = {
      id: 'superusers',
      users_data: superUsers,
      users: superUsers.map(({ id }) => id),
      permissions: [],
      permissions_data: [],
      name: 'SUPERUSERS',
      description: '',
    };

    const data = await response.data;

    return [superUsersItem, ...data];
  }
);

/**
 * set role to the server
 */
export const setRole = createAsyncThunk(
  'managementApp/roles/setRole',
  /**
   * @param {object} payload - the payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.role - the role object
   */
  async ({ wsId, role }, { dispatch, getState }) => {
    const response = await axios.put(`${ApiRoutes.rolesEndPoint(wsId) + role.id}/`, role);
    const data = await response.data;
    dispatch(
      showMessage({
        message: t('managementApp:ROLE_UPDATED', { name: data.name }),
        variant: 'success',
      })
    );

    return data;
  }
);

/**
 * add role to the server
 */
export const addRole = createAsyncThunk(
  'managementApp/roles/addRole',
  /**
   * @param {object} payload - the payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.role - the role object
   */
  async ({ wsId, role }, { dispatch, getState }) => {
    const response = await axios.post(ApiRoutes.rolesEndPoint(wsId), role);
    const data = await response.data;

    const { entities } = getState().managementApp.roles;
    const Roles = Object.keys(entities).map((key) => entities[key]);
    Roles.splice(1, 0, data);

    dispatch(
      showMessage({
        message: t('managementApp:ROLE_CREATED', { name: data.name }),
        variant: 'success',
      })
    );

    return Roles;
  }
);

/**
 * remove role from the server
 */
export const removeRole = createAsyncThunk(
  'managementApp/roles/removeRoles',
  /**
   * @param {object} payload - the payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.role - the role object to remove
   */
  async ({ wsId, role }, { dispatch, getState }) => {
    await axios.delete(`${ApiRoutes.rolesEndPoint(wsId) + role.id}/`);

    dispatch(setSidebarProps({ numOfTab: 0 }));

    dispatch(
      showMessage({
        message: t('managementApp:ROLE_DELETED', { name: role.name }),
        variant: 'success',
      })
    );

    return role.id;
  }
);

const initialState = rolesAdapter.getInitialState({
  isLoading: true,
  selectedRoleId: null,
  searchText: '',
  sidebarProps: {
    numOfTab: 0,
  },
  deleteModalProps: {
    open: false,
    roleId: null,
  },
});

const rolesSlice = createSlice({
  name: 'managementApp/roles',
  initialState,
  reducers: {
    setSidebarProps: (state, action) => {
      state.sidebarProps = { ...state.sidebarProps, ...action.payload };
    },
    setRolesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDeleteModalProps: (state, action) => {
      state.deleteModalProps = action.payload;
    },
    resetRolesState: () => initialState,
  },
  extraReducers: {
    [getRoles.fulfilled]: rolesAdapter.setAll,
    [setRole.fulfilled]: rolesAdapter.setOne,
    [addRole.fulfilled]: rolesAdapter.setAll,
    [removeRole.fulfilled]: rolesAdapter.removeOne,
  },
});

export const selectSelectedRole = ({ managementApp }) =>
  managementApp.roles.sidebarProps.selectedRoleId;

export const {
  setSidebarProps,
  setRolesSearchText,
  setIsLoading,
  setDeleteModalProps,
  resetRolesState,
} = rolesSlice.actions;

export default rolesSlice.reducer;
