/* eslint-disable camelcase */
import * as yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import _ from '@lodash';
import format from 'date-fns/format';
import { Controller, useForm } from 'react-hook-form';
import { getCountriesAll, selectCountryById } from 'src/app/store/selectsData/countrySlice';
import { parseISO } from 'date-fns';
import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { roleChecker, selectUser } from 'app/store/userSlice';
import { stringAvatar } from '@fuse/hooks/AvatarColor';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import CountryCodeSelector from '../../../../../../@fuse/core/FuseCountry/CountryCodeSelector';
import {
  getUserRoles,
  openRequestremoveUser,
  selectUserById,
  setSuperUser,
  setUser,
  setUserRoles,
} from '../../store/usersSlice';
import { selectRoles, getRoles } from '../../../../../store/selectsData/rolesSlice';

/**
 * the user view content
 * @returns {JSX.Element}
 */
const UserView = () => {
  /**
   * @constant {object} {wsId, userId} - the workspace id and the user id
   * @constant {function} {editMode, setEditMode} - the edit mode state and the function to set the edit mode
   * @constant {function} {isLoading, setIsLoading} - the loading state and the function to set the loading state
   * @constant {function} checkPermission - the function to check the permissions
   * @constant {function} checkRole - the function to check the roles
   * @constant {object} country - the country object
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {array} roles - the roles array
   * @constant {object} user - the user object
   * @constant {object} userItem - the user item object
   * @constant {boolean} isRolesLoaded - if the roles are loaded
   * @constant {function} t - the translation function
   */
  const { wsId, userId } = useParams();
  const userItem = useSelector((state) => selectUserById(state, userId));
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const checkPermission = useSelector(permissionChecker);
  const checkRole = useSelector(roleChecker);
  const country = useSelector((state) => selectCountryById(state, userItem?.country));
  const dispatch = useDispatch();
  const roles = useSelector(selectRoles) || [];
  const user = useSelector(selectUser);
  const { isRolesLoaded } = useSelector(({ selectsData }) => selectsData.roles);
  const { t } = useTranslation('managementApp');

  /**
   * schema for the form
   */
  const schema = yup.object().shape({
    email: yup.string().required(t('EMAIL_CANNOT_EMPTY')).email(t('EMAIL_VALID')),
    name: yup.string().required(t('NAME_CANNOT_EMPTY')),
    last_name: yup.string().required(t('LAST_NAME_CANNOT_EMPTY')),
  });
  const { control, reset, formState, getValues, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  /**
   * close the edit mode
   */
  const closeEditMode = () => setEditMode(false);

  /**
   * edit the user
   */
  const onEdit = async () => {
    reset({ ...userItem });
    setEditMode(true);
    if (!isRolesLoaded && checkPermission('superUser')) {
      setIsLoading(true);
      await dispatch(getRoles({ wsId }));
      setIsLoading(false);
    }
  };

  /**
   * save the user
   */
  const saveUser = async () => {
    const userData = getValues();
    const isChanged = {
      userItem: !_.isEqual(_.omit(userItem, ['roles']), _.omit(userData, ['roles'])),
      roles: !_.isEqual(userData.roles, userItem.roles),
    };

    setIsLoading(true);

    if (isChanged.userItem) await dispatch(setUser({ wsId, user: userData }));
    if (isChanged.roles) await dispatch(setUserRoles({ wsId, user: userData }));
    if (userItem.is_superuser !== userData.is_superuser)
      await setSuperUser({ wsId, user: userData });

    setEditMode(false);
    setIsLoading(false);
  };

  /**
   * delete the user
   */
  const deleteUser = () => dispatch(openRequestremoveUser(userItem.id));

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setEditMode(false);
      await dispatch(getCountriesAll());
      if (!userItem?.roles && userItem) {
        await dispatch(getUserRoles({ wsId, userId }));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userItem]);

  if (!userItem || isLoading || !userItem.roles) {
    return (
      <div className="file-details flex flex-col flex-auto  h-fullVH w-fullVW sm:h-full sm:w-full">
        <FuseLoading />
      </div>
    );
  }

  return (
    <div className="file-details flex flex-col flex-auto justify-around h-fullVH w-fullVW sm:h-full sm:w-full ">
      <Box
        className="relative w-full rounded-none"
        sx={{
          backgroundColor: 'primary.main',
          borderRadius: 0,
          height: 160,
        }}
      >
        <svg
          className="absolute w-full h-full rounded-none pointer-events-none"
          viewBox="0 0 960 600"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            className="text-gray-700 opacity-25"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="200" cx="196" cy="23" />
            <circle r="200" cx="790" cy="491" />
          </g>
        </svg>
      </Box>
      {editMode ? (
        <div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
          <div className="w-full">
            <div className="flex flex-auto items-end -mt-64">
              <Avatar
                {...stringAvatar(userItem, {
                  borderWidth: 4,
                  borderStyle: 'solid',
                  borderColor: 'background.paper',
                })}
                className="w-112 h-112 text-40 font-bold"
              />
            </div>
          </div>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                className="mt-32"
                {...field}
                label="Email"
                disabled
                id="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
                variant="outlined"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FuseSvgIcon size={20}>material-outline:email</FuseSvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {checkPermission('superUser') && (
            <>
              <Controller
                name="is_superuser"
                type="checkbox"
                control={control}
                render={({ field: { onChange, value, onBlur, ref } }) => (
                  <div className="w-full mt-16 ml-5">
                    <FormControlLabel
                      label={t('IS_SUPER_USER')}
                      control={
                        <Checkbox
                          checked={value}
                          onBlur={onBlur}
                          onChange={(e) => onChange(e.target.checked)}
                          inputRef={ref}
                          size="small"
                        />
                      }
                    />
                  </div>
                )}
              />
              {!watch('is_superuser') ? (
                <Controller
                  name="roles"
                  control={control}
                  render={({ field: { onChange, value, onBlur, ref } }) => (
                    <Autocomplete
                      className="mt-16"
                      multiple
                      openOnFocus
                      disableClearable
                      popupIcon={false}
                      noOptionsText={t('NO_OPTION')}
                      options={roles}
                      fullWidth
                      value={value}
                      // disabled={watch('is_superuser')}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          paddingRight: '10px!important',
                        },
                      }}
                      isOptionEqualToValue={(option, rol) => option.id === rol.id}
                      getOptionLabel={(option) => option.name}
                      renderTags={(rolesItems, getTagProps) =>
                        rolesItems.map((rol, index) => (
                          <Chip
                            sx={(theme) => ({
                              backgroundColor: theme.palette.secondary.main,
                              color: theme.palette.secondary.contrastText,
                              fontSize: 12,
                              '& .MuiChip-deleteIcon': {
                                color: theme.palette.secondary.contrastText,
                                fontSize: 15,
                              },
                            })}
                            {...getTagProps({ index })}
                            variant="outlined"
                            label={rol.name}
                          />
                        ))
                      }
                      onChange={(event, newValue) => onChange(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={onBlur}
                          inputRef={ref}
                          label="Roles"
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              ) : (
                <TextField
                  className="mt-16 text-sm"
                  label="Roles"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={t('USER_HAS_ALL_PERMISSIONS')}
                  inputProps={{
                    style: {
                      fontSize: 12.5,
                      textAlign: 'center',
                    },
                  }}
                />
              )}
            </>
          )}
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                className="mt-32"
                {...field}
                label={t('NAME')}
                // placeholder="Name"
                id="user-name"
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="outlined"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="last_name"
            render={({ field }) => (
              <TextField
                className="mt-32"
                {...field}
                label={t('LAST_NAME')}
                // placeholder="Name"
                id="last_name"
                error={!!errors.last_name}
                helperText={errors?.last_name?.message}
                variant="outlined"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-32"
                label={t('PHONE_NUMBER')}
                variant="outlined"
                fullWidth
                error={!!errors.phone}
                helperText={errors?.phone?.message}
                type="tel"
                InputProps={{
                  startAdornment: (
                    <Controller
                      control={control}
                      name="country"
                      render={({ field: _field }) => (
                        <InputAdornment position="start">
                          <CountryCodeSelector {..._field} />
                        </InputAdornment>
                      )}
                    />
                  ),
                }}
              />
            )}
          />
          <div className="fixed right-24 bottom-56">
            <Tooltip title={t('SAVE')} placement="top">
              <span>
                <Fab
                  color="secondary"
                  size="small"
                  aria-label="remove"
                  disabled={_.isEmpty(dirtyFields) || !isValid}
                  onClick={saveUser}
                >
                  <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
                </Fab>
              </span>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
          <div className="w-full max-w-3xl">
            <div className="flex flex-auto items-end -mt-64">
              <Avatar
                {...stringAvatar(userItem, {
                  borderWidth: 4,
                  borderStyle: 'solid',
                  borderColor: 'background.paper',
                })}
                className="w-112 h-112 text-40 font-bold"
              />
              {(checkPermission('superUser') || user.id === userItem.id) && (
                <div className="absolute right-24 bottom-24 flex gap-10">
                  <Tooltip title={t('EDIT')} placement="top">
                    <Fab color="secondary" size="small" aria-label="edit" onClick={onEdit}>
                      <FuseSvgIcon size={20}>heroicons-solid:pencil</FuseSvgIcon>
                    </Fab>
                  </Tooltip>
                </div>
              )}
            </div>

            <Typography className="mt-12 text-2xl sm:text-4xl font-bold ">
              {userItem.name || userItem.last_name
                ? `${userItem.name} ${userItem.last_name}`
                : userItem.email}
            </Typography>
            {userItem.is_superuser ? (
              <Chip
                label={t('SUPERUSER')}
                className="mr-12 mb-12 border-double border-4 border-sky-500"
                color="secondary"
                size="medium"
              />
            ) : (
              <div className="flex flex-wrap items-center mt-8">
                {userItem.roles.map((role) => (
                  <Chip key={role.id} label={role.name} className="mr-12 mb-12" size="small" />
                ))}
              </div>
            )}
            <div className="flex flex-col space-y-6">
              <Divider className="w-full py-4" />
              <div className="flex items-center">
                <FuseSvgIcon>material-solid:email</FuseSvgIcon>
                <div className="ml-24 leading-6">{userItem.email}</div>
              </div>
              {userItem.phone && (
                <div className="flex items-center">
                  <FuseSvgIcon>material-solid:local_phone</FuseSvgIcon>
                  <div className="ml-24 leading-6 flex items-center">
                    <Box
                      className="hidden sm:flex w-24 h-16 overflow-hidden"
                      sx={{
                        background: "url('/assets/images/flags/flags.png') no-repeat 0 0",
                        backgroundSize: '24px 3876px',
                        backgroundPosition: country?.imagenPosition,
                      }}
                    />

                    <div className="sm:ml-12 font-mono">{country?.calling_code}</div>

                    <div className="ml-10 font-mono">{userItem.phone}</div>
                  </div>
                </div>
              )}
              {checkRole('platform_support') && (
                <>
                  <div className="w-full py-4">
                    <Divider />
                  </div>
                  <div>
                    <Typography variant="caption" className="font-medium">
                      {t('CREATION_DATE')}:
                    </Typography>
                    <Typography variant="caption">
                      {format(parseISO(userItem.date_created), 'dd/MM/y hh:mm aaaa ')}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption" className="font-medium">
                      {t('EDITION_DATE')}:
                    </Typography>
                    <Typography variant="caption">
                      {format(parseISO(userItem.date_edited), 'dd/MM/y hh:mm aaaa ')}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption" className="font-medium">
                      ID:
                    </Typography>
                    <Typography variant="caption">{userItem.id}</Typography>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <Box
        className="flex items-center mt-40 py-14 px-16 border-t"
        sx={{ backgroundColor: 'background.default' }}
      >
        {(checkPermission('superUser') || user.id === userItem.id) && (
          <Button onClick={deleteUser} color="error">
            {t(user.id === userItem.id ? 'LEAVE_WS' : 'REMOVE_FROM_WS')}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default UserView;
