import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectWorkspace, workspaceReset } from 'app/store/workspaceSlice';
import { useNavigate } from 'react-router-dom';
import { removeWorkspace } from 'app/store/userSlice';

/** component to show the message when the user left a workspace */
function LeftWorkspace() {
  /**
   * @constant {function} t - translation function
   * @constant {object} workspace - the current workspace
   * @constant {function} dispatch - redux dispatch function
   * @constant {function} navigate - function to navigate to another page
   */
  const { t } = useTranslation('workspaceApp');
  const { id, name } = useSelector(selectWorkspace);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * effect to remove the workspace from the user and navigate to the workspaces page
   */
  useEffect(() => {
    setTimeout(async () => {
      await dispatch(removeWorkspace(id));
      await dispatch(workspaceReset());

      navigate(`/workspaces`);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="flex items-center w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img className="w-48 mx-auto" src="assets/images/logo/opta_logo.svg" alt="logo" />

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight text-center">
            {t('HAVE_LEFT_WS', { name })}
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

export default LeftWorkspace;
