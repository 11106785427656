import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

/** list of tags to be used by select forms */

const tagsAdapter = createEntityAdapter({});

export const { selectAll: selectTags, selectById: selectTagById } = tagsAdapter.getSelectors(
  (state) => state.selectsData.tags
);

const initialState = tagsAdapter.getInitialState({
  isTagsLoaded: false,
});

/** get all the tags to select data */
export const getTagsAll = createAsyncThunk(
  'selectsData/tags/getTagAll',
  /**
   * @param {string} wsId the workspace id
   */
  async (wsId, { dispatch, getState }) => {
    const { isTagsLoaded } = getState().selectsData.tags;
    if (!isTagsLoaded) {
      const response = await axios.get(`${ApiRoutes.tagsEndPoint(wsId)}all/`);
      const data = await response.data;
      dispatch(setIsTagsLoaded(true));
      return data;
    }
    return [];
  }
);

const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    /** change the indicator to know if the `tags` are loaded */
    setIsTagsLoaded: (state, action) => {
      state.isTagsLoaded = action.payload;
    },
    /** reset the `tags` state */
    resetTagsState: () => initialState,
  },
  extraReducers: {
    [getTagsAll.fulfilled]: tagsAdapter.addMany,
  },
});

export const { setIsTagsLoaded, resetTagsState } = tagSlice.actions;

export default tagSlice.reducer;
