import FuseUtils from '@fuse/utils/FuseUtils';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

const PointsAdapter = createEntityAdapter({});

export const { selectAll: selectPoints } = PointsAdapter.getSelectors(
  (state) => state.LocationApp.Points
);

const initialState = PointsAdapter.getInitialState({});

/**
 * load points specified by the user
 */
export const loadPoints = createAsyncThunk(
  'LocationApp/MapData/Points/loadPoints',
  /**
   * @param {Array} points - the points to load
   */
  async (points, { dispatch, getState }) => {
    const validPoints = [];
    points.forEach((i, index) => {
      if (FuseUtils.isLatitude(i.lat) && FuseUtils.isLongitude(i.lng)) {
        validPoints.push({
          ...i,
          id: `id-${new Date().getTime()}-${index}`,
          lat: parseFloat(i.lat),
          lng: parseFloat(i.lng),
          typeMarker: 'Point',
        });
      }
    });

    return validPoints;
  }
);

/**
 * delete a point from the map
 */
export const deletePoint = createAsyncThunk(
  'LocationApp/MapData/Points/deletePoint',
  /**
   * @param {string} id - the id of the point to delete
   */
  async (id, { dispatch, getState }) => id
);

const PointsSlice = createSlice({
  name: 'LocationApp/MapData/Points',
  initialState,
  reducers: {
    resetPointsState: () => initialState,
  },
  extraReducers: {
    [loadPoints.fulfilled]: PointsAdapter.setMany,
    [deletePoint.fulfilled]: PointsAdapter.removeOne,
  },
});

export const { resetPointsState } = PointsSlice.actions;

export default PointsSlice.reducer;
