import ApiRoutes from 'src/app/services/ApiRoutes';
import FuseUtils from '@fuse/utils/FuseUtils';
import Socket from 'src/app/services/Socket';
import axios from 'axios';
import history from '@history';
import jwtDecode from 'jwt-decode';
import { t } from 'i18next';

/* eslint-disable camelcase */

/**
 * JwtService
 * This class handles all login and logout processes for the web application.
 * It manages authentication, request and response interceptors,
 * and JWT token management.
 */
class JwtService extends FuseUtils.EventEmitter {
  /**
   * Initialize
   * This method will initialize the class.
   */
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  /**
   * Set Interceptors
   * This method will set the request and response interceptors for axios.
   */
  setInterceptors = () => {
    /**
     * Request Interceptor
     * This method will intercept all requests made by axios.
     * - add the Web-version header to the request.
     * - add the Authorization token to the request.
     */
    axios.interceptors.request.use(
      (config) => {
        config.headers['Web-version'] = '2.0.0';
        config.headers.Authorization = config.DjangoAuth;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    /**
     * Response Interceptor
     * This method will intercept all responses from axios.
     * - If the response is a 401, logout the user.
     * - If the response is a 403, show a forbidden message, is posoble that the user not have a permmision.
     * - If the response is a 0, show a server error message.
     */
    axios.interceptors.response.use(
      (config) => {
        return config;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            if (err.config.url === ApiRoutes.login.getToken) {
              this.emit('onLoginFailed', t('authApp:INVALID_USER_PASSWORD'));
            } else {
              this.emit('onAutoLogout', t('authApp:INVALID_TOKEN'));
            }
            this.setSession(null);
          } else if (err.response?.status === 403) {
            this.emit('Forbidden', err.response?.data?.detail);
          } else if (err.response?.status === 0) {
            this.emit('onServerError', t('authApp:SERVER_ERROR'));
            history.push('/');
          }

          throw err;
        });
      }
    );
  };

  /**
   * Handle Authentication
   * This method will handle the authentication process.
   * - Get the credentials from the local storage.
   * - If there is no access token, emit the onNoAccessToken event.
   * - If the access token is valid, set the session and emit the onAutoLogin event.
   * - If the access token is invalid, set the session to null and emit the onAutoLogout event.
   */
  handleAuthentication = () => {
    const credentials = this.getCredentials();

    if (!credentials.access) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(credentials.access)) {
      this.setSession(credentials);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  /**
   * Register User
   * This method will register a new user.
   * @param {object} data to create a new user
   * @param {string} recaptchaToken to verify the user is not a robot
   */
  registerUser = (data, recaptchaToken) => {
    return new Promise((resolve, reject) => {
      console.log({ recaptchaToken });
      axios
        .post(ApiRoutes.login.registerUser, data, {
          headers: {
            'Content-Type': 'application/json',
            'Recaptcha-Token': recaptchaToken,
          },
        })
        .then(resolve)
        .catch(
          ({
            response: {
              data: { error },
            },
          }) => {
            if (error?.email && error?.email[0]?.includes('Ya existe')) {
              this.emit('onSignUpFailed', t('authApp:ALREADY_EXIST_ACCOUNT'));
            } else {
              this.emit('onSignUpFailed', t('authApp:SIGN_UP_FAILED'));
            }
          }
        );
    });
  };

  /**
   * method to send a request to the server to get the user's token
   * and save it in the local storage
   * @param {*} email email of the user
   * @param {*} password password of the user
   */
  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ApiRoutes.login.getToken,
          {
            email,
            password,
          },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data.user) {
            this.setSession({
              access: response.data.access,
              refresh: response.data.refresh,
            });

            resolve(response.data.user);
            this.emit('onLogin', response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch(reject);
    });
  };

  /**
   * method to make login autatically with the token saved in the local storage
   * and without the need to enter the email and password
   */
  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      // verify if the token is valid and get the userData
      axios
        .get(ApiRoutes.login.getUserInfo, {
          headers: { Authorization: `Bearer ${this.getCredentials().access}` },
        })
        .then((response) => {
          if (response.data) {
            // response.data.role = ['platform_admin'];
            resolve(response.data);
          } else {
            this.logout();
            reject(new Error(t('authApp:INVALID_TOKEN')));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error(t('authApp:INVALID_TOKEN')));
        });
    });
  };

  /**
   * change the token in the local storage in the browser and axios default headers
   * and if send null in the credentials parameter, the token is removed from the local storage and axios default headers
   * @param {*} credentials token to refresh and access
   */
  setSession = (credentials) => {
    if (credentials) {
      // jwt
      localStorage.setItem('jwt_access_token', credentials.access);
      localStorage.setItem('jwt_refresh_token', credentials.refresh);
      // axios
      axios.defaults.DjangoAuth = `Bearer ${credentials.access}`;
    } else {
      // jwt
      localStorage.removeItem('jwt_access_token');
      localStorage.removeItem('jwt_refresh_token');
      // axios
      delete axios.defaults.headers.common.Authorization;
      delete axios.defaults.DjangoAuth;
    }
  };

  /**
   * logout the user and remove the token from the local storage , disconnect the socket and redirect to the login page
   */
  logout = () => {
    this.setSession(null);
    Socket.disconnectWorkspace();
    // deleteCookie('csrftoken');
    this.emit('onLogout', 'Logged out');
  };

  /**
   *  vefiry if the token is valid still and alert if the token is expired
   * @param {*} access_token token to verify
   * @returns {boolean} if the token is valid or not
   */
  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  /**
   * @returns {object} the token saved in the local storage in the browser
   */
  getCredentials = () => {
    return {
      access: window.localStorage.getItem('jwt_access_token'),
      refresh: window.localStorage.getItem('jwt_refresh_token'),
    };
  };
}

const instance = new JwtService();

export default instance;
