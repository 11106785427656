import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

/** list of teams to be used by select teams */

const teamsAdapter = createEntityAdapter({});

export const { selectAll: selectTeams, selectById: selectTeamById } = teamsAdapter.getSelectors(
  (state) => state.selectsData.teams
);

const initialState = teamsAdapter.getInitialState({
  isTeamsLoaded: false,
});

/** get all the teams to select data */
export const getTeamsAll = createAsyncThunk(
  'selectsData/teams/getTeamsAll',
  /**
   * @param {string} wsId the workspace id
   */
  async (wsId, { dispatch, getState }) => {
    const { isTeamsLoaded } = getState().selectsData.teams;
    if (!isTeamsLoaded) {
      const response = await axios.get(`${ApiRoutes.teamsEndPoint(wsId)}all/`);
      const data = await response.data;
      dispatch(setIsTeamsLoaded(true));

      return data;
    }
    return [];
  }
);

const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    /** change the indicator to know if the `teams` are loaded */
    setIsTeamsLoaded: (state, action) => {
      state.isTeamsLoaded = action.payload;
    },
    /** reset the `teams` state */
    resetTeamState: () => initialState,
  },
  extraReducers: {
    [getTeamsAll.fulfilled]: teamsAdapter.addMany,
  },
});

export const { setIsTeamsLoaded, resetTeamState } = teamSlice.actions;

export default teamSlice.reducer;
