/* eslint-disable camelcase */
import ApiRoutes from 'src/app/services/ApiRoutes';
import axios from 'axios';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  combineReducers,
} from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';

const ClientsWithCoordsAdapter = createEntityAdapter({});

export const selectClientsWithCoordsData = ({ LocationApp }) => LocationApp.Clients.withCoords;

export const { selectAll: selectClientsWithCoords } = ClientsWithCoordsAdapter.getSelectors(
  selectClientsWithCoordsData
);

const initialStateClientsWithCoords = ClientsWithCoordsAdapter.getInitialState({
  numOfPages: 0,
  page: null,
  count: null,
  isLoading: true,
});

/**
 * get the clients with coords to show in the map
 */
export const getClientsWithCoords = createAsyncThunk(
  'LocationApp/MapData/Clients/withCoords/getClientsWithCoords',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.params - the params to filter the clients
   */
  async ({ wsId, params }, { dispatch, getState }) => {
    const { page } = getState().LocationApp.Clients.withCoords;

    if (page === params.page) return [];
    dispatch(setLoadingWithCoords(true));

    const { data } = await axios.get(
      ApiRoutes.getClientsLocation(wsId, { page: params.page, withCoords: true })
    );

    if (data.length === 0)
      dispatch(showMessage({ message: t('locationApp:NO_ASSIGNMENTS_FOUND'), variant: 'warning' }));

    dispatch(setPageWithCoords(params.page));
    dispatch(setLoadingWithCoords(false));

    return data.map((e) => ({ ...e, typeMarker: 'Client', visible: true }));
  }
);

/**
 * delete a client with coords from the map
 */
export const deleteClientWithCoords = createAsyncThunk(
  'LocationApp/MapData/Clients/WithCoords/deleteClientWithCoords',
  /**
   * @param {string} id - the id of the client to delete
   */
  async (id, { dispatch, getState }) => id
);

/**
 * get the places of a client with many locations
 */
export const getClientPlaces = createAsyncThunk(
  'LocationApp/MapData/Clients/WithCoords/getClientPlaces',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.clientId - the id of the client
   */
  async ({ wsId, clientId }, { dispatch, getState }) => {
    const { data } = await axios.get(`${ApiRoutes.clientsEndPoint(wsId, null)}${clientId}/places/`);
    return {
      id: clientId,
      changes: { places_data: data.map((place) => ({ ...place, visible: true })) },
    };
  }
);

const ClientsWithCoordsSlice = createSlice({
  name: 'LocationApp/MapData/Clients/withCoords',
  initialState: initialStateClientsWithCoords,
  reducers: {
    setLoadingWithCoords: (state, action) => {
      state.isLoading = action.payload;
    },
    setPageWithCoords: (state, action) => {
      state.page = action.payload;
    },
    setCountWithCoords: (state, action) => {
      state.count = action.payload;
      state.numOfPages = Math.ceil(action.payload / 100 || 0);
    },
    setVisibilityClient: (state, action) => {
      const { clientId, visible } = action.payload;
      state.entities[clientId].visible = visible;
    },
    setVisibilityPlace: (state, action) => {
      const { clientId, placeIndex, visible } = action.payload;
      state.entities[clientId].places_data[placeIndex].visible = visible;
    },
    logicalDeleteClient: (state, action) => {
      state.entities[action.payload].delete = true;
    },
    resetClientsWithCoordsState: () => initialStateClientsWithCoords,
  },
  extraReducers: {
    [getClientsWithCoords.fulfilled]: ClientsWithCoordsAdapter.upsertMany,
    [deleteClientWithCoords.fulfilled]: ClientsWithCoordsAdapter.removeOne,
    [getClientPlaces.fulfilled]: ClientsWithCoordsAdapter.updateOne,
  },
});

export const {
  setLoading,
  setVisibilityClient,
  resetClientsWithCoordsState,
  setLoadingWithCoords,
  setPageWithCoords,
  setCountWithCoords,
  logicalDeleteClient,
  setVisibilityPlace,
} = ClientsWithCoordsSlice.actions;

// clients withooutCoords

const ClientsWithoutCoordsAdapter = createEntityAdapter({});

export const selectClientsWithoutCoordsData = ({ LocationApp }) =>
  LocationApp.Clients.withoutCoords;

export const { selectAll: selectClientsWithoutCoords } = ClientsWithoutCoordsAdapter.getSelectors(
  selectClientsWithoutCoordsData
);

const initialState = ClientsWithoutCoordsAdapter.getInitialState({
  numOfPages: 0,
  count: null,
  page: null,
  isLoading: true,
});

/**
 * get the clients without coords to show in the map
 */
export const getClientsWithoutCoords = createAsyncThunk(
  'LocationApp/MapData/Clients/withoutCoords/getClientsWithoutCoords',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.params - the params to filter the clients
   */
  async ({ wsId, params }, { dispatch, getState }) => {
    const { page } = getState().LocationApp.Clients.withoutCoords;

    if (page === params.page) return [];
    dispatch(setLoadingWithoutCoords(true));

    const { data } = await axios.get(
      ApiRoutes.getClientsLocation(wsId, { page: params.page, withCoords: false })
    );

    if (data.length === 0)
      dispatch(showMessage({ message: t('locationApp:NO_ASSIGNMENTS_FOUND'), variant: 'warning' }));

    dispatch(setPageWithoutCoords(params.page));

    dispatch(setLoadingWithoutCoords(false));

    return data;
  }
);

/**
 * delete a client without coords
 */
export const deleteClientWithoutCoords = createAsyncThunk(
  'LocationApp/MapData/Clients/WithoutCoords/deleteClientWithoutCoords',
  /**
   * @param {string} id - the id of the client to delete
   */
  async (id, { dispatch, getState }) => id
);

const ClientsWithoutCoordsSlice = createSlice({
  name: 'LocationApp/MapData/Clients/withoutCoords',
  initialState,
  reducers: {
    setLoadingWithoutCoords: (state, action) => {
      state.isLoading = action.payload;
    },
    setPageWithoutCoords: (state, action) => {
      state.page = action.payload;
    },
    setCountWithoutCoords: (state, action) => {
      state.count = action.payload;
      state.numOfPages = Math.ceil(action.payload / 100 || 0);
    },
    setVisibilityClient: (state, action) => {
      const { clientId, visible } = action.payload;
      state.entities[clientId].visible = visible;
    },
    resetClientsWithoutCoordsState: () => initialState,
  },
  extraReducers: {
    [getClientsWithoutCoords.fulfilled]: ClientsWithoutCoordsAdapter.upsertMany,
    [deleteClientWithoutCoords.fulfilled]: ClientsWithoutCoordsAdapter.removeOne,
  },
});

export const {
  resetClientsWithoutCoordsState,
  setLoadingWithoutCoords,
  setPageWithoutCoords,
  setCountWithoutCoords,
} = ClientsWithoutCoordsSlice.actions;

const reducer = combineReducers({
  withCoords: ClientsWithCoordsSlice.reducer,
  withoutCoords: ClientsWithoutCoordsSlice.reducer,
});

/**
 * get the count of clients with and without coords
 */
export const getCountClients = createAsyncThunk(
  'LocationApp/MapData/Clients/WithoutCoords/deleteClientWithoutCoords',
  /**
   * @param {string} wsId - the workspace id
   */
  async (wsId, { dispatch, getState }) => {
    const { withCoords, withoutCoords } = getState().LocationApp.Clients;

    if (withCoords.count !== null && withoutCoords.count !== null) return;

    const {
      data: { with_location, without_location },
    } = await axios.get(ApiRoutes.getClientsCount(wsId));
    dispatch(setCountWithCoords(with_location));
    dispatch(setCountWithoutCoords(without_location));
  }
);

export default reducer;
