import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';

const dailyRecordAdapter = createEntityAdapter({});

export const selectDailyRecord = ({ LocationApp }) => LocationApp.DailyRecords;

export const { selectAll: selectDailyRecordLists } =
  dailyRecordAdapter.getSelectors(selectDailyRecord);
const initialState = dailyRecordAdapter.getInitialState({
  numOfPages: 0,
  page: 1,
  loading: false,
  params: {},
});

/**
 * get the list of daily records to show in the map
 */
export const getDailyRecordList = createAsyncThunk(
  'LocationApp/MapData/DailyRecords/getDailyRecordList',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   * @param {object} payload.params - the params to filter the daily records
   */
  async ({ ws, params }, { dispatch, getState }) => {
    const { params: oldParams } = getState().LocationApp.DailyRecords;
    const currentParams = { ...oldParams, ...params };

    const { data } = await axios.get(ApiRoutes.dailyRecordEndPoint(ws, currentParams));

    if (data.length === 0)
      dispatch(showMessage({ message: t('locationApp:NO_ALERTS_FOUND'), variant: 'warning' }));

    dispatch(setPage(currentParams.page));
    dispatch(setParams(currentParams));
    dispatch(setNumOfPages(Math.ceil(data.count / 100 || 0)));

    return data.results;
  }
);

const dailyRecordSlice = createSlice({
  name: 'LocationApp/MapData/DailyRecords',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setParams: (state, action) => {
      state.params = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setReferenceRoute: (state, action) => {
      state.routeReference = action.payload;
    },
    resetDailyRecordState: () => initialState,
  },
  extraReducers: {
    [getDailyRecordList.fulfilled]: dailyRecordAdapter.setAll,
  },
});

export const {
  setLoading,
  setNumOfPages,
  setParams,
  setPage,
  setReferenceRoute,
  resetDailyRecordState,
} = dailyRecordSlice.actions;

const { reducer } = dailyRecordSlice;

export default reducer;
