import { createSlice } from '@reduxjs/toolkit';

/**
 * the congiguration of the map in all the web app
 * - google maps
 * - open street maps(leaflet)
 */

/**
 * @typedef {Object} MapState
 * @property {OpenStreetMap|GoogleMaps} mapType - the map type
 */
const mapSlice = createSlice({
  name: 'map',
  initialState: {
    mapType: 'OpenStreetMap',
  },
  reducers: {
    /**
     * change the map in the web app
     * @param {*} state dispatch state
     * @param {(OpenStreetMap|GoogleMaps)} action
     */
    changeMap: (state, action) => {
      state.mapType = action.payload;
    },
  },
});

export const { changeMap } = mapSlice.actions;

export default mapSlice.reducer;
