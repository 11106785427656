import ApiRoutes from 'src/app/services/ApiRoutes';
import axios from 'axios';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';

const alertAdapter = createEntityAdapter({});

export const { selectAll: selectAlerts, selectById: selectAlertById } = alertAdapter.getSelectors(
  (state) => state.managementApp.alerts
);

const initialState = alertAdapter.getInitialState({
  selectedAlertId: null,
  numOfPages: 0,
  page: 1,
  isLoading: true,
  FiltersDialog: {
    props: {
      open: false,
    },
  },
  deleteModalProps: {
    open: false,
    alertId: null,
  },
});

/**
 * get alerts from the server
 */
export const getAlerts = createAsyncThunk(
  'managementApp/alerts/getAlerts',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.paramsApi - the api params
   */
  async ({ wsId, paramsApi }, { dispatch, getState }) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(ApiRoutes.alertsEndPoint(wsId, paramsApi));
    dispatch(setIsLoading(false));
    dispatch(setNumOfPages(Math.ceil(response.data.count / 100 || 0)));
    const data = await response.data.results;

    return data;
  }
);

/**
 * set an alert
 */
export const setAlert = createAsyncThunk(
  'managementApp/alerts/setAlerts',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.alert - the alert object
   */
  async ({ wsId, alert }, { dispatch, getState }) => {
    const response = await axios.put(`${ApiRoutes.alertsEndPoint(wsId) + alert.id}/`, alert);
    const data = await response.data;

    dispatch(
      showMessage({
        message: t('managementApp:ALERT_UPDATED', { name: data.name }),
        variant: 'success',
      })
    );

    return data;
  }
);

/**
 * add an alert
 */
export const addAlert = createAsyncThunk(
  'managementApp/alerts/addAlerts',
  async ({ wsId, alert }, { dispatch, getState }) => {
    const response = await axios.post(ApiRoutes.alertsEndPoint(wsId), alert);
    const data = await response.data;

    const { entities } = getState().managementApp.alerts;
    const Alerts = Object.keys(entities).map((key) => entities[key]);

    dispatch(
      showMessage({
        message: t('managementApp:ALERT_CREATED', { name: data.name }),
        variant: 'success',
      })
    );

    return [data, ...Alerts];
  }
);

/**
 * remove an alert
 */
export const removeAlert = createAsyncThunk(
  'managementApp/alerts/removeAlerts',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.alert - the alert object
   */
  async ({ wsId, alert }, { dispatch, getState }) => {
    await axios.delete(`${ApiRoutes.alertsEndPoint(wsId) + alert.id}/`);

    dispatch(
      showMessage({
        message: t('managementApp:ALERT_DELETED', { name: alert.name }),
        variant: 'success',
      })
    );

    return alert.id;
  }
);

const alertSlice = createSlice({
  name: 'managementApp/alerts',
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    openFiltersDialog: (state, action) => {
      state.FiltersDialog = {
        props: {
          open: true,
        },
      };
    },
    closeFiltersDialog: (state, action) => {
      state.FiltersDialog = {
        props: {
          open: false,
        },
      };
    },
    setDeleteModalProps: (state, action) => {
      state.deleteModalProps = action.payload;
    },
    resetAlertsState: () => initialState,
  },
  extraReducers: {
    [getAlerts.fulfilled]: alertAdapter.setAll,
    [setAlert.fulfilled]: alertAdapter.setOne,
    [addAlert.fulfilled]: alertAdapter.setAll,
    [removeAlert.fulfilled]: alertAdapter.removeOne,
  },
});

export const selectSelectedAlert = ({ managementApp }) => managementApp.alerts.selectedAlertId;

export const {
  setNumOfPages,
  setIsLoading,
  setPage,
  openFiltersDialog,
  closeFiltersDialog,
  setDeleteModalProps,
  resetAlertsState,
} = alertSlice.actions;

export default alertSlice.reducer;
