import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import { selectUserById } from '../usersSlice';

/**
 * UserChip component to display a user chip from select data
 * @param {object} props
 * @param { String } props.id - the id of the user chip
 * @param { ('small'|'medium'|'large') } props.size - the size of the chip
 * @param { Object } props.chipProps - the props of the chip (MuiChip)
 * @param { String } props.className - the class name of the chip(TailwindCss)
 */
const UserChip = ({ id, chipProps }) => {
  const user = useSelector((state) => selectUserById(state, id));
  return (
    <Chip
      {...chipProps}
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        '& .MuiChip-deleteIcon': {
          color: theme.palette.secondary.contrastText,
          fontSize: 15,
        },
      })}
      variant="outlined"
      label={`${user?.name}(${user?.email})`}
      id={id}
      key={id}
    />
  );
};

export default UserChip;
