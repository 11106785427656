import Fab from '@mui/material/Fab';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { roleChecker } from 'app/store/userSlice';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { formSelector, getForms, selectForms, setPage } from '../store/formsSlice';
import FormItem from './FormItem';

/**
 * form list component
 * @returns {JSX.Element}
 */
function FormList() {
  /**
   * @constant {SearchParams} searchParams - search params from the url
   * @constant {function} checkPermission - the function to check the permissions of the user
   * @constant {function} checkRole - the function to check the role of the user
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {array} forms - the list of forms
   * @constant {function} navigate - the function to navigate to another page
   * @constant {object} scrollRef - the reference to the scroll element
   * @constant {object} formId - the form id currently selected in the url
   * @constant {object} wsId - the workspace id
   * @constant {boolean} isLoading - flag to show the loading spinner
   * @constant {function} t - the translation function
   */
  const [searchParams] = useSearchParams();
  const checkPermission = useSelector(permissionChecker);
  const checkRole = useSelector(roleChecker);
  const dispatch = useDispatch();
  const forms = useSelector(selectForms);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const { formId, wsId } = useParams();
  const { isLoading } = useSelector(formSelector);
  const { t } = useTranslation('assignmentsApp');

  /** open the sidebar to create a new form */
  const newForm = () => navigate(`./new${window.location.search}`);

  /** effect to get the forms when the component mounts */
  useEffect(() => {
    const urlPage = searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 1;

    dispatch(setPage(urlPage));
    dispatch(getForms({ wsId, paramsApi: { page: urlPage, search: searchParams.get('search') } }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, wsId]);

  // this useEfect is for reboot the scroll when change page , for start of begin of list
  useEffect(() => {
    scrollRef?.current?.scrollIntoView();
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="h-full" ref={scrollRef}>
        <FuseLoading />
      </div>
    );
  }

  if (!isLoading && forms.length === 0) {
    return (
      <div className="h-full w-full flex flex-col justify-center items-center" ref={scrollRef}>
        <FuseSvgIcon className="text-48" size={255} color="disabled">
          material-solid:sentiment_dissatisfied
        </FuseSvgIcon>
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="text-24 md:text-32  tracking-tight text-grey-400 font-800"
        >
          {t('NO_FORMS_SHOW')}
        </Typography>
        <div className="absolute right-24 bottom-36">
          {!formId && (checkRole('platform_admin') || checkRole('platform_support')) && (
            <Tooltip title={t('NEW_FORM')} placement="top">
              <Fab color="secondary" size="small" aria-label="remove" onClick={newForm}>
                <FuseSvgIcon size={20}>material-solid:add</FuseSvgIcon>
              </Fab>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col min-h-full min-w-full">
      <div className="overflow-y-auto flex-auto h-0 w-full p-32" ref={scrollRef}>
        <div
          className="flex flex-wrap -m-4 mt-4 "
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            justifyContent: 'space-evenly',
            justifyItems: 'center',
            alignContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <AnimatePresence>
            {forms.map((form) => (
              <motion.div
                key={form.id}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.02 }}
              >
                <FormItem form={form} />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
      <div className="absolute right-24 bottom-36">
        {!formId &&
          (checkRole('platform_admin') ||
            checkRole('platform_support') ||
            checkPermission('create_form')) && (
            <Tooltip title={t('NEW_FORM')} placement="top">
              <Fab color="secondary" size="small" aria-label="remove" onClick={newForm}>
                <FuseSvgIcon size={20}>material-solid:add</FuseSvgIcon>
              </Fab>
            </Tooltip>
          )}
      </div>
    </div>
  );
}

export default FormList;
