/* eslint-disable camelcase */
import ApiRoutes from 'src/app/services/ApiRoutes';
import axios from 'axios';
import { colorValidator, stringToColor } from '@fuse/hooks/AvatarColor';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { format, startOfToday } from 'date-fns';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';

import { setPosition, setSideBarData } from './mapDataSlice';

const usersAdapter = createEntityAdapter({});

export const { selectAll: selectUsers, selectById: selectUserById } = usersAdapter.getSelectors(
  (state) => state.LocationApp.Users
);

const initialState = usersAdapter.getInitialState({});

/**
 * get the list of users to show in the map
 */
export const getUsers = createAsyncThunk(
  'LocationApp/MapData/Users/getUsers',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   */
  async (ws, { dispatch, getState }) => {
    const response = await axios.get(
      ApiRoutes.usersLocationEndPoint(ws, null, 1, { listMap: true })
    );

    const data = await response.data.map((e) => ({
      ...e,
      typeMarker: 'user',
      visible: true,
    }));

    if (data.length > 0) {
      // dispatch(setPosition({ coords: data[0].point.coordinates, zoom: 12, setPosition: true }));
    } else {
      dispatch(showMessage({ message: t('locationApp:NO_USERS_FOUND'), variant: 'warning' }));
    }

    return data;
  }
);

/**
 * get the details of a user
 */
export const getUserDetail = createAsyncThunk(
  'LocationApp/MapData/Users/getUserDetail',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   * @param {string} payload.id - the user id
   */
  async ({ ws, user }, { dispatch, getState }) => {
    const { data } = await axios.get(ApiRoutes.getUserLocationDetail(ws, user.id));
    return { ...user, details: data };
  }
);

/**
 * get the tracking of a user to show in the map
 */
export const getUserTracking = createAsyncThunk(
  'LocationApp/MapData/Users/getUserTracking',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   * @param {string} payload.id - the user id
   */
  async ({ ws, id }, { dispatch, getState }) => {
    const {
      workspace: { aux_data },
      LocationApp,
    } = getState();
    const user = LocationApp.Users.entities[id];
    const accuracy_limit = aux_data.coordinates?.accuracy_limit || 20;

    const { data } = await axios.get(
      ApiRoutes.userLocationTrackingEndPoint(
        ws,
        { user: user.user_data.id, start: startOfToday() },
        1,
        {
          listMap: true,
        }
      )
    );

    const tracking = data.filter((e) => e.accuracy <= accuracy_limit);

    if (tracking.length === 0) {
      dispatch(
        showMessage({
          message: t('locationApp:NO_TRACKING', {
            name: `${user.user_data.name} ${user.user_data.last_name}`,
          }),
          variant: 'warning',
        })
      );
    }

    return { ...user, tracking, showTracking: true };
  }
);

/**
 * get the tracking of a user to show in the map
 */
export const getUserTrackingByDate = createAsyncThunk(
  'LocationApp/MapData/Users/getUserTrackingByDate',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   * @param {string} payload.id - the user id
   * @param {object} payload.dates - range of dates to get the tracking
   * @param {Date} payload.dates.start - the start date
   * @param {Date} payload.dates.end - the end date
   */
  async ({ ws, userID, dates }, { rejectWithValue, dispatch, getState }) => {
    const {
      workspace: { aux_data },
      LocationApp,
    } = getState();
    const user = LocationApp.Users.entities[userID];
    const accuracy_limit = aux_data.coordinates?.accuracy_limit || 20;

    let idRoute = '';
    let label = '';
    if (dates.start) {
      idRoute += Math.floor(dates.start.getTime() / 10000);
      label += format(dates.start, 'dd/MM/yyyy, h:mm a');
    }
    idRoute += '-';
    label += ' - ';
    if (dates.end) {
      idRoute += Math.floor(dates.end.getTime() / 10000);
      label += format(dates.end, 'dd/MM/yyyy, h:mm a');
    }
    const routes = user.routes ? [...user.routes] : [];
    if (routes.findIndex((x) => x.id === idRoute) === -1) {
      const { data } = await axios.get(
        ApiRoutes.userLocationTrackingEndPoint(ws, { user: user.user_data.id, ...dates }, 1, {
          listMap: true,
        })
      );

      const tracking = data.filter((e) => e.accuracy <= accuracy_limit);

      if (tracking.length === 0) {
        dispatch(
          showMessage({
            message: t('locationApp:NO_MEASUREMENTS_FOR_THESE_DATES', { dates: label }),
            variant: 'warning',
          })
        );
        throw rejectWithValue(t('locationApp:NO_MEASUREMENTS_FOR_THESE_DATES', { dates: label }));
      }

      routes.push({
        label,
        tracking,
        dateStart: dates.start,
        dateEnd: dates.end,
        id: idRoute,
        color: stringToColor(idRoute),
        show: true,
        visibilityMarkers: false,
      });

      dispatch(
        setPosition({
          coords: [tracking[0].lat, tracking[0].lng],
          zoom: 15,
          setPosition: true,
        })
      );

      if (window.innerWidth <= 600) dispatch(setSideBarData({ tabType: null }));
    }

    return { ...user, routes };
  }
);

/**
 * set the user for the socket listener
 */
export const setUserSocked = createAsyncThunk(
  'LocationApp/MapData/Users/setUserSocked',
  /**
   * @param {object} user - the user to set data
   */
  async (user, { dispatch, getState }) => {
    const userCurrent = getState().LocationApp.Users.entities[user.user_location_id];

    const newData = {
      ...userCurrent,
      ...user,
      id: user.user_location_id,
    };

    delete user.user_location_id;

    newData.details = user;

    if (newData.tracking) {
      newData.tracking = [user, ...userCurrent.tracking];
    }

    return newData;
  }
);

/**
 * set the visibility of the markers of the user path
 */
export const setUserPathMarkersVisility = createAsyncThunk(
  'LocationApp/MapData/Users/setUserPathMarkersVisility',
  /**
   * @param {string} userId - the user id
   */
  async (userId, { dispatch, getState }) => {
    const userCurrent = getState().LocationApp.Users.entities[userId];

    const newData = {
      ...userCurrent,
      visibilityMarkers: !userCurrent.visibilityMarkers,
    };
    return newData;
  }
);

/**
 * search users by a string
 */
export const searchUsers = createAsyncThunk(
  'LocationApp/MapData/Users/searchUsers',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   * @param {string} payload.search - the string to search
   */
  async ({ ws, search }, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.searchUsers(ws, search));
    const { ids, entities } = getState().LocationApp.Users;
    const newEntities = {};

    const data = await response.data.map((e) => ({ ...e, typeMarker: 'user' }));

    if (data.length > 0) {
      dispatch(setPosition({ coords: [data[0].lat, data[0].lng], zoom: 12, setPosition: true }));
      data.forEach((e) => {
        if (ids.includes(e.id)) {
          ids.push(e.id);
        }
        newEntities[e.id] = e;
      });
    } else {
      dispatch(showMessage({ message: t('locationApp:NO_USERS_FOUND'), variant: 'warning' }));
    }
    const results = { ...entities, ...newEntities };
    return Object.keys(results).map((key) => results[key]);
  }
);

const usersSlice = createSlice({
  name: 'LocationApp/MapData/Users',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      const { user, loading } = action.payload;
      state.entities[user].loading = loading;
    },
    setVisibilityUser: (state, action) => {
      const { user, visible } = action.payload;
      state.entities[user].visible = visible;
    },
    setVisibilityRoute: (state, action) => {
      const { user, routeIndex, show } = action.payload;
      state.entities[user].routes[routeIndex].show =
        show || !state.entities[user].routes[routeIndex].show;
    },
    setColorRoute: (state, action) => {
      const { user, routeIndex, color } = action.payload;
      if (colorValidator.test(color)) {
        state.entities[user].routes[routeIndex].color = color;
      }
    },
    setVisibilityMarkersRoute: (state, action) => {
      const { user, routeIndex } = action.payload;
      state.entities[user].routes[routeIndex].visibilityMarkers =
        !state.entities[user].routes[routeIndex].visibilityMarkers;
    },

    logicalDeleteRoute: (state, action) => {
      const { user, routeIndex } = action.payload;
      state.entities[user].routes[routeIndex].delete = true;
    },
    physicalDeleteRoute: (state, action) => {
      const { user, routeIndex } = action.payload;
      state.entities[user].routes.splice(routeIndex, 1);
    },
    setVisibilityPath: (state, action) => {
      const user = action.payload;
      state.entities[user].showTracking = !state.entities[user].showTracking;
    },
    resetUsersState: () => initialState,
  },
  extraReducers: {
    [getUsers.fulfilled]: usersAdapter.setAll,
    [searchUsers.fulfilled]: usersAdapter.setAll,
    [getUserDetail.fulfilled]: usersAdapter.setOne,
    [getUserTracking.fulfilled]: usersAdapter.setOne,
    [getUserTrackingByDate.fulfilled]: usersAdapter.setOne,
    [setUserSocked.fulfilled]: usersAdapter.setOne,
    [setUserPathMarkersVisility.fulfilled]: usersAdapter.setOne,
  },
});
export const {
  setVisibilityRoute,
  setColorRoute,
  setVisibilityPath,
  setVisibilityUser,
  logicalDeleteRoute,
  physicalDeleteRoute,
  setVisibilityMarkersRoute,
  setLoading,
  resetUsersState,
} = usersSlice.actions;

export default usersSlice.reducer;
