/** this file wil contain the translations in spanish for the authApp */
const locale = {
  ALREADY_EXIST_ACCOUNT: 'Ya existe una cuenta creada con este correo electrónico',
  ALREADY_HAVE_ACCOUNT: '¿Ya tienes una cuenta?',
  CREATE_ACCOUNT: 'Crear cuenta',
  DONT_HAVE_ACCOUNT: '¿No tienes una cuenta?',
  GO_TO: 'ir a',
  INVALID_TOKEN: 'Token inválido o expirado',
  INVALID_USER_PASSWORD: 'Usuario o contraseña invalida',
  LAST_NAME: 'Apellidos',
  LOG_IN: 'Iniciar sesión',
  MUST_ENTER_EMAIL: 'Debes ingresar un e-mail',
  MUST_ENTER_VALID_EMAIL: 'Debes ingresar un e-mail valido',
  NAME: 'Nombre',
  PASSWORD: 'Contraseña',
  PASSWORD_CONFIRMATION: 'Confirmación de contraseña',
  PASSWORDS_MUST_MATCH: 'Las contraseñas deben coincidir',
  PLEASE_ENTER_PASSWORD: 'Por favor ingresa tu contraseña',
  SERVER_ERROR: 'No es posible comunicarse con el servidor',
  SIGN_IN: 'Inicia sesión',
  SIGN_UP: 'Registrate',
  SIGN_UP_FAILED: 'Registro fallido',
  SUCCESSFUL_SIGN_UP: 'Registro exitoso',
  THIS_IS_REQUIRED: 'Este es un campo obligatorio',
  WE_ARE_TEAM: 'Somos parte de tu equipo',
  WE_ARE_TOOL: 'Somos la herramienta más flexible del mercado para administrar tu cadena de valor',
  WE_HAVE_SEND_EMAIL: 'Te hemos enviado un correo electrónico para que confirmes tu cuenta',
};

export default locale;
