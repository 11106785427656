/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from '../services/ApiRoutes';
import { authRoles } from '../auth';
import { checkAccess } from '../auth/authRoles';

/** user workspace permissions slice
 * contains the user permissions for the workspace
 * - user can be have permissions for the workspace
 * - user can be a superuser
 * - user can be have organization permissions
 */

const initialState = { is_superuser: false, permissions: [] };

export const selectuserWorkspacePermissions = ({ userWorkspacePermissions }) =>
  userWorkspacePermissions;

/**  get the user permission in the workspace */
export const getUserWorkspacePermissions = createAsyncThunk(
  'userWorkspacePermissions/getUserWorkspacePermissions',
  /**
   * @param {string} wsId - the id of workspace.
   */
  async (wsId, { dispatch, getState }) => {
    const {
      user: { role },
    } = getState();

    if (authRoles.org.includes(role) || authRoles.platform.includes(role)) {
      // the user does not need permissions
      return initialState;
    }

    const response = await axios.get(ApiRoutes.getMyPermissions(wsId));

    const data = await response.data;

    return data;
  }
);

/** check if the user has the permission */
export const permissionChecker =
  ({ userWorkspacePermissions, user: { role } }) =>
  /**
   * @param {string} permission the permission to check
   */
  (permission) =>
    checkAccess(permission, userWorkspacePermissions, role);

const userWorkspacePermissionsSlice = createSlice({
  name: 'userWorkspacePermissions',
  initialState,
  reducers: {
    /** reset the user workspace permissions */
    resetUserWorkspacePermissions: (state, action) => initialState,
  },
  extraReducers: {
    [getUserWorkspacePermissions.fulfilled]: (state, action) => action.payload,
  },
});

export const { resetUserWorkspacePermissions } = userWorkspacePermissionsSlice.actions;

export default userWorkspacePermissionsSlice.reducer;
