import Chip from '@mui/material/Chip';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { authRoles } from 'src/app/auth';
import { getOrgUsers, selectOrganization } from 'app/store/organizationSlice';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** component to display the users information of a  organization */
function UsersTab() {
  /**
   * @constant {boolean} isLoading - boolean to check if the data is loading
   * @constant {function} dispatch - redux dispatch function
   * @constant {object} organization - organization object
   * @constant {function} t - i18n translation function
   */
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const organization = useSelector(selectOrganization);
  const { t } = useTranslation('organizationApp');

  /** effect to get the users of the organization */
  useEffect(() => {
    if (!(organization.users?.length > 0))
      dispatch(getOrgUsers(organization)).then(() => setIsLoading(false));
    else setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="file-details flex flex-col flex-auto  h-fullVH w-fullVW sm:h-full sm:w-full">
        <FuseLoading />
      </div>
    );
  }

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        overflow: 'hidden',
        overflowY: 'scroll',
      }}
    >
      <div className="px-12">
        <Typography variant="caption" color="text.secondary">
          {t('USER_TAB_HELP')}
        </Typography>
      </div>
      {organization.users.map((user) => {
        return (
          <ListItem
            key={user.id}
            secondaryAction={
              <IconButton edge="end" aria-label="comments">
                <FuseSvgIcon className="text-48" size={24} color="action">
                  material-solid:person
                </FuseSvgIcon>
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton role={undefined} dense className="flex flex-col items-start">
              <ListItemText
                id={user.id}
                primary={`${user.name} ${user.last_name}`}
                secondary={user.email}
              />
              {!authRoles.user.includes(user.role) && (
                <Chip
                  className="font-semibold text-12"
                  label={t(`ROLE_${user.role.toUpperCase()}`)}
                  sx={{
                    color: '#FFF',
                    backgroundColor: '#540AF0',
                  }}
                  size="small"
                />
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
export default UsersTab;
