import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectFormById } from 'src/app/pages/modulesApp/assignmentsApp/store/formsSlice';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * the multi text field component to edit the details of the field
 * shows the title, max length, input type, values, required, and conditional fields
 * @returns {JSX.Element}
 */
export default function FieldMultiText() {
  /**
   * @constant {string} formId - the form id
   * @constant {object} form - the form data
   * @constant {object} formState - the form state
   * @constant {object} errors - the form errors
   * @constant {function} t - the translation function
   */
  const { formId } = useParams();
  const form = useSelector((state) => selectFormById(state, formId));
  const { control, formState, watch, getValues, setValue } = useFormContext();
  const { errors } = formState;
  const { t } = useTranslation('assignmentsApp');

  /**
   * function to change the value of the multi text field by index
   * @param {Event} event - the event
   * @param {number} index - the index of the value
   */
  const onChangeMultiText = ({ target: { value } }, index) => setValue(`values.${index}`, value);

  /**
   * function to add a new field to the multi text field in last position
   */
  const multiTextAddField = (index) => setValue(`values.${index}`, '');

  /**
   * remove a field from the multi text field by index
   * @param {number} index - the index of the value to remove
   */
  const multiTextRemoveField = (index) =>
    setValue(
      'values',
      getValues('values').filter((i, indexI) => indexI !== index)
    );

  return (
    <>
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full py-5 px-10">
          <div className="flex flex-col flex-1 w-full items-start justify-between">
            <Typography variant="subtitle1" color="inherit">
              <b>{t('FIELD_TYPE')}</b>:{t('MULTI_TEXT_TITLE')}
            </Typography>
            <Typography variant="caption">{t('MULTI_TEXT_DEPS')}</Typography>
          </div>
          <img className="h-48" src="assets/images/fields/multi-text.png" alt="text" />
        </Toolbar>
      </AppBar>
      <div className="flex flex-col px-10 py-16 gap-y-20 overflow-y-auto h-320">
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              size="small"
              variant="outlined"
              id={`textFieldTitle${field.id}`}
              label={t('TITLE')}
              error={!!errors.title}
              helperText={errors?.title?.message}
            />
          )}
        />

        <Controller
          name="max_length"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              size="small"
              variant="outlined"
              label={t('MAXIMUM_NUMBER_CHARACTERS')}
              id="maxLengthField"
              type="number"
              error={!!errors.max_length}
              helperText={errors?.max_length?.message}
            />
          )}
        />
        <div className="flex flex-col">
          <div>
            <Divider textAlign="left">
              <Typography variant="caption" color="GrayText">
                {t('KEYBOARD_TYPE')}
              </Typography>
            </Divider>
          </div>

          <div>
            <Controller
              name="input_type"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ButtonGroup size="small">
                  <Button
                    onClick={(e) => onChange('text')}
                    variant={value === 'text' ? 'contained' : 'outlined'}
                  >
                    {t('TEXTUAL')}
                  </Button>
                  <Button
                    onClick={(e) => onChange('number')}
                    variant={value === 'number' ? 'contained' : 'outlined'}
                  >
                    {t('NUMERICAL')}
                  </Button>
                  <Button
                    onClick={(e) => onChange('tel')}
                    variant={value === 'tel' ? 'contained' : 'outlined'}
                  >
                    {t('TELEPHONIC')}
                  </Button>
                </ButtonGroup>
              )}
            />
          </div>
        </div>
        <div>
          <Divider textAlign="left">
            <Typography variant="caption" color="GrayText">
              {t('FIELDS')}
            </Typography>
          </Divider>
        </div>
        {watch('values').map((valueField, index) => (
          <div className="flex py-1" key={index}>
            <TextField
              fullWidth
              id={`textFieldValues${index}`}
              size="small"
              variant="outlined"
              value={valueField}
              onChange={(newValue) => onChangeMultiText(newValue, index)}
            />
            {watch('values').length > 1 && (
              <IconButton
                className="min-w-0"
                variant="contained"
                color="error"
                size="small"
                onClick={() => multiTextRemoveField(index)}
              >
                <Tooltip title={t('REMOVE_FIELD')} placement="left">
                  <FuseSvgIcon className="text-15" size={18}>
                    heroicons-solid:x-circle
                  </FuseSvgIcon>
                </Tooltip>
              </IconButton>
            )}
          </div>
        ))}
        <div className="flex justify-end">
          <Button
            variant="contained"
            color="success"
            size="small"
            className="my-5"
            startIcon={<FuseSvgIcon size={18}>material-solid:add_circle</FuseSvgIcon>}
            onClick={() => multiTextAddField(getValues('values').length)}
          >
            {t('ADD_FIELD')}
          </Button>
        </div>

        <Controller
          name="required"
          control={control}
          render={({ field: { onChange, value, ref, onBlur } }) => (
            <FormControlLabel
              control={
                <Switch
                  id="requiredSwitch"
                  size="small"
                  checked={value}
                  onBlur={onBlur}
                  onChange={(ev) => onChange(ev.target.checked)}
                  inputRef={ref}
                />
              }
              label={t('REQUIRED')}
            />
          )}
        />
        <Divider />
        <Controller
          name="has_conditional"
          control={control}
          render={({ field: { onChange, value, ref, onBlur } }) => (
            <FormControlLabel
              control={
                <Switch
                  id="hasConditionalSwitch"
                  size="small"
                  checked={value || false}
                  onBlur={onBlur}
                  onChange={(ev) => onChange(ev.target.checked)}
                  inputRef={ref}
                />
              }
              label={t('CONDITIONALLY_SHOW_FIELD')}
            />
          )}
        />

        {watch('has_conditional') && (
          <Controller
            render={({ field }) => (
              <FormControl fullWidth size="small">
                {form.data.fields.filter(
                  (fieldI) => fieldI.type === 'select' || fieldI.type === 'ternary'
                ).length ? (
                  <>
                    <InputLabel id="conditional_info">{t('CONDITIONING_FIELD')}</InputLabel>
                    <Select
                      {...field}
                      variant="outlined"
                      fullWidth
                      labelId="conditional_info"
                      id="conditional_info"
                      name="conditional_info"
                      label={t('CONDITIONING_FIELD')}
                      value={field.value?.field_id || ''}
                      onChange={(e) => field.onChange({ field_id: e.target.value })}
                      defaultValue=""
                    >
                      {form.data.fields
                        .filter((fieldI) => fieldI.type === 'select' || fieldI.type === 'ternary')
                        .map((fieldI) => (
                          <MenuItem key={fieldI.id} value={fieldI.id}>
                            <div className="flex justify-between w-full">
                              <Typography variant="caption">{fieldI.title}</Typography>
                              <div className="flex h-20">
                                {fieldI.type === 'ternary' ? (
                                  <img src="assets/images/fields/ternary.png" alt="text" />
                                ) : (
                                  <img src="assets/images/fields/select.png" alt="text" />
                                )}
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                ) : (
                  <Typography variant="caption">{t('NO_CONDITIONING_FIELD')}</Typography>
                )}
              </FormControl>
            )}
            name="conditional_info"
            control={control}
          />
        )}
        {watch('conditional_info')?.field_id && watch('has_conditional') && (
          <Controller
            render={({ field }) => (
              <FormControl fullWidth size="small">
                <InputLabel id="conditional_info_field_id">{t('CONDITIONING_VALUE')}</InputLabel>
                {form.data.fields.find((fieldI) => fieldI.id === field.value?.field_id)?.type ===
                'ternary' ? (
                  <Select
                    {...field}
                    variant="outlined"
                    fullWidth
                    labelId="conditional_info_field_id"
                    id="conditional_info_field_id"
                    name="conditional_info_field_id"
                    label={t('CONDITIONING_VALUE')}
                    value={JSON.stringify(field.value?.value_id) || ''}
                    onChange={(e) =>
                      field.onChange({ ...field.value, value_id: JSON.parse(e.target.value) })
                    }
                  >
                    <MenuItem key="null" value="null">
                      {t('NO_RESPONSE')}
                    </MenuItem>
                    <MenuItem key="false" value="false">
                      {t('NO')}
                    </MenuItem>
                    <MenuItem key="true" value="true">
                      {t('YES')}
                    </MenuItem>
                  </Select>
                ) : (
                  <Select
                    {...field}
                    variant="outlined"
                    fullWidth
                    labelId="conditional_info_field_id"
                    id="conditional_info_field_id"
                    name="conditional_info_field_id"
                    label={t('CONDITIONING_VALUE')}
                    value={field.value?.value_id || ''}
                    onChange={(e) => field.onChange({ ...field.value, value_id: e.target.value })}
                  >
                    {form.data.fields
                      .find((fieldI) => fieldI.id === field.value?.field_id)
                      .select_values.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </FormControl>
            )}
            name="conditional_info"
            control={control}
          />
        )}
      </div>
    </>
  );
}
