import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import clsx from 'clsx';
import { ThemeProvider } from '@mui/material/styles';
import { getYear } from 'date-fns';
import { memo } from 'react';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import { selectWorkspace } from 'app/store/workspaceSlice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function FooterLayout1(props) {
  const footerTheme = useSelector(selectFooterTheme);
  const { name } = useSelector(selectWorkspace);
  const { t } = useTranslation('navigation');

  const goToWhatsApp = () => {
    let messagge = '';
    if (name) {
      messagge = t('SUPPORT_MESSAGE_WS', { name });
    } else {
      messagge = t('SUPPORT_MESSAGE');
    }
    window.open(
      `https://api.whatsapp.com/send?phone=${
        process.env.REACT_APP_WHATSAPP_NUMBER
      }&text=${messagge.replace(' ', '%20')}`,
      '_blank'
    );
  };

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className={clsx('relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? footerTheme.palette.background.paper
              : footerTheme.palette.background.default,
        }}
      >
        <Toolbar className="min-h-32 md:min-h-40 px-8 sm:px-12 py-0 flex items-center justify-between overflow-x-auto">
          <div>
            <Typography variant="caption" className="mr-24">
              Opta © {getYear(new Date())}{' '}
            </Typography>
            <Hidden lgDown>
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={goToWhatsApp}
                size="large"
              >
                <Typography variant="body1" className="mr-24">
                  Soporte &nbsp;
                  <WhatsAppIcon /> &nbsp;
                  {process.env.REACT_APP_WHATSAPP_NUMBER_HUMAN}
                </Typography>
              </IconButton>
            </Hidden>
          </div>
          <Typography variant="caption">Version {process.env.REACT_APP_VERSION_WEB}</Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(FooterLayout1);
