import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getPermissionsAll, selectPermissions } from 'app/store/selectsData/permissionsSlice';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';

const TextFieldSearch = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 50,
      },
    },
  },
})(TextField);

/**
 * tab to show the permissions of the role
 * @returns {JSX.Element}
 */
function PermissionsTab() {
  /**
   * @constant {string} searchText - the text to search
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {object} permissions - the permissions from the redux store
   * @constant {object} useFormContext - the context of the form
   * @constant {function} t - the translation function
   * @constant {object} wsId - the id of the workspace
   */
  const [searchText, setSearchtext] = useState('');
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  const { getValues, setValue, watch } = useFormContext();
  const { t } = useTranslation('managementApp');
  const { wsId } = useParams();

  /**
   * divide the permissions in groups
   */
  const sortPermissionGroup = [
    { key: 'client', title: 'CLIENTS', items: [], iconName: 'material-solid:person' },
    { key: 'assignment', title: 'ASSIGNMENTS', items: [], iconName: 'material-solid:assignment' },
    { key: 'form', title: 'FORMS', items: [], iconName: 'heroicons-solid:clipboard-list' },
    { key: 'geofence', title: 'GEOFENCES', items: [], iconName: 'material-solid:travel_explore' },
    { key: 'alert', title: 'ALERTS', items: [], iconName: 'feather:alert-triangle' },
    { key: 'dashboard', title: 'DASHBOARD', items: [], iconName: 'material-solid:dashboard' },
    { key: 'project', title: 'PROJECTS', items: [], iconName: 'heroicons-solid:light-bulb' },
    { key: 'user', title: 'USERS', items: [], iconName: 'material-solid:account_circle' },
    { key: 'team', title: 'TEAMS', items: [], iconName: 'material-solid:groups' },
    { key: 'access_key', title: 'ACCESS_KEYS', items: [], iconName: 'material-solid:vpn_key' },
    { key: 'others', title: 'OTHERS', items: [], iconName: 'material-solid:more_horiz' },
  ];

  const permissionsFiltered = useMemo(() => {
    const permissionsSorted = sortPermissionGroup;
    let permissionsUnsorted = [];
    if (!searchText) {
      permissionsUnsorted = permissions;
    } else {
      permissionsUnsorted = permissions.filter((item) =>
        t(item.name.toUpperCase()).toLowerCase().match(searchText.toLowerCase())
      );
    }

    permissionsUnsorted.forEach((p) => {
      const index = permissionsSorted.findIndex(({ key }) => p.name.match(key));
      if (index !== -1) {
        permissionsSorted[index].items.push(p.name);
      } else {
        permissionsSorted[permissionsSorted.length - 1].items.push(p.name);
      }
    });

    return permissionsSorted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, searchText]);

  const [isLoading, setLoading] = useState(true);

  /**
   * function to check/uncheck a permission
   */
  const onCheck = ({ target: { checked } }, permissionName) => {
    const permissionsIds = getValues('permissions');
    if (checked) {
      permissionsIds.push(permissionName);
    } else {
      const index = permissionsIds.indexOf(permissionName);
      if (index > -1) {
        permissionsIds.splice(index, 1);
      }
    }
    setValue('permissions', permissionsIds);
  };

  /**
   * function to check/uncheck all the permissions
   */
  const onCheckAll = ({ target: { checked } }) => {
    const permissionsList = [];
    if (checked) {
      permissionsFiltered.forEach(({ items }) =>
        items.forEach((name) => permissionsList.push(name))
      );
    }
    setValue('permissions', permissionsList);
  };

  useEffect(() => {
    dispatch(getPermissionsAll(wsId)).then(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="h-full flex-1 flex items-center">
        <FuseLoading />
      </div>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '10%', paddingX: 1 }}>
        <div className="w-5/12">
          <Typography className=" font-bold flex-1" variant="subtitle1">
            {t('PERMISSIONS_ASSIGNED_THIS_ROLE')}
          </Typography>
        </div>
        <div className="w-6/12">
          <TextFieldSearch
            onChange={({ target: { value } }) => setSearchtext(value)}
            value={searchText}
            size="small"
            placeholder={t('SEARCH_PERMISSIONS')}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FuseSvgIcon className="text-48" size={24} color="action">
                    material-solid:search
                  </FuseSvgIcon>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-1/12 flex justify-center">
          <Checkbox
            edge="start"
            checked={
              watch('permissions').length ===
              permissionsFiltered.reduce((len, { items }) => len + items.length, 0)
            }
            disableRipple
            size="small"
            onChange={onCheckAll}
          />
        </div>
      </Box>
      <List
        className="w-full overflow-hidden overflow-y-scroll pb-64"
        sx={{
          bgcolor: 'background.paper',
          height: '90%',
        }}
      >
        {permissionsFiltered.map(({ key, title, items, iconName }) => {
          if (items.length < 1) return <div key={key} />;
          return (
            <div key={key}>
              <div className="flex items-center justify-center">
                <Divider className="flex-1 px-8">
                  <Typography variant="bold caption">{t(title)}</Typography>
                </Divider>
                <FuseSvgIcon className="text-48 " size={20} color="action">
                  {iconName}
                </FuseSvgIcon>
              </div>
              {items.map((name) => {
                return (
                  <ListItem key={name} disablePadding>
                    <ListItemButton role={undefined} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={watch('permissions').includes(name)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': name }}
                          onChange={(e) => onCheck(e, name)}
                        />
                      </ListItemIcon>
                      <ListItemText id={name} primary={t(name.toUpperCase())} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </div>
          );
        })}
      </List>
    </>
  );
}

export default PermissionsTab;
