import { createSlice } from '@reduxjs/toolkit';

const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    open: true,
    mobileOpen: false,
  },
  reducers: {
    /** set the folded state */
    navbarToggleFolded: (state, action) => {
      state.foldedOpen = !state.foldedOpen;
    },
    /** open the folded in the navbar */
    navbarOpenFolded: (state, action) => {
      state.foldedOpen = true;
    },
    /** close the folded in the navbar */
    navbarCloseFolded: (state, action) => {
      state.foldedOpen = false;
    },
    /** set data in the navbar in mobiles screen */
    navbarToggleMobile: (state, action) => {
      state.mobileOpen = !state.mobileOpen;
    },
    /** open the navbar in mobiles screen */
    navbarOpenMobile: (state, action) => {
      state.mobileOpen = true;
    },
    /** close the navbar in mobiles screen */
    navbarCloseMobile: (state, action) => {
      state.mobileOpen = false;
    },
    /** set the data in the navbar */
    navbarClose: (state, action) => {
      state.open = false;
    },
    /** open the navbar */
    navbarOpen: (state, action) => {
      state.open = true;
    },
    /** close the navbar */
    navbarToggle: (state, action) => {
      state.open = !state.open;
    },
  },
});

export const {
  navbarToggleFolded,
  navbarOpenFolded,
  navbarCloseFolded,
  navbarOpen,
  navbarClose,
  navbarToggle,
  navbarOpenMobile,
  navbarCloseMobile,
  navbarToggleMobile,
} = navbarSlice.actions;

export const selectFuseNavbar = ({ fuse }) => fuse.navbar;

export default navbarSlice.reducer;
