import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getClientPlaces,
  selectClientById,
  setDeleteAllPlacesModalProps,
  setRightSidebarProps,
} from '../../store/clientsSlice';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
/**
 * modal to confirm the deletion of all places
 * @returns {JSX.Element}
 */
function DeleteAllPlaces() {
  /**
   * @constant {function} client - the client object
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {function} setValue - the setValue function from the react-hook-form
   * @constant {function} t - the translation function
   * @constant {object} wsId - the workspace id
   * @constant {object} clientId - the client id
   * @constant {object} open - if the modal is open
   */
  const { wsId, clientId } = useParams();
  const client = useSelector((state) => selectClientById(state, clientId));
  const dispatch = useDispatch();
  const { setValue } = useFormContext();
  const { t } = useTranslation('managementApp');
  const { open } = useSelector(
    ({ managementApp }) => managementApp.clients.deleteAllPlacesModalProps
  );

  /** close the delete modal */
  const closeDeleteModal = async () =>
    dispatch(setDeleteAllPlacesModalProps({ open: false }));

  /** delete all places */
  const deletePlaces = async () => {
    let placesData;
    if (!client.places_data) {
      placesData = (await dispatch(getClientPlaces({ wsId, clientId }))).payload
        .changes.places_data;
    } else {
      placesData = client.places_data;
    }

    setValue(
      'places_data',
      placesData.map((place) => ({ ...place, delete: true }))
    );

    setValue(
      'location_mode',
      client.latitude === 0 && client.longitude === 0
        ? ''
        : 'through-client-coordinate'
    );

    dispatch(setRightSidebarProps({ tab: 'map' }));

    closeDeleteModal();
  };

  return (
    <Modal
      open={open}
      onClose={closeDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <Typography variant="subtitle1" className="mb-5 text-justify">
          {t('ARE_SURE_THIS_ACTION')}
        </Typography>
        <Typography variant="caption" color="inherit">
          {t('ALL_PLACES_WILL_REMOVED', { clientName: client?.name })}
        </Typography>
        <div className="flex justify-end">
          <Button onClick={closeDeleteModal} className="flex-auto mx-2">
            {t('CANCEL')}
          </Button>

          <Button
            className="flex-auto mx-2"
            color="secondary"
            variant="contained"
            onClick={deletePlaces}
          >
            {t('ACCEPT')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default DeleteAllPlaces;
