import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { t } from 'i18next';

const projectsAdapter = createEntityAdapter({});

export const { selectAll: selectProjects, selectById: selectProjectById } =
  projectsAdapter.getSelectors((state) => state.managementApp.projects);

const initialState = projectsAdapter.getInitialState({
  numOfPages: 0,
  page: 1,
  isLoading: true,
  isEditing: false,
  deleteModalProps: {
    open: false,
    projectId: null,
  },
});

/**
 * get projects from the server
 */
export const getProjects = createAsyncThunk(
  'managementApp/projects/getProjects',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.paramsApi - the api params
   */
  async ({ wsId, paramsApi }, { dispatch, getState }) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(ApiRoutes.projectsEndPoint(wsId, paramsApi));
    dispatch(setIsLoading(false));
    dispatch(setNumOfPages(Math.ceil(response.data.count / 100 || 0)));
    const data = await response.data.results;

    return data;
  }
);

/**
 * set a project
 */
export const setProject = createAsyncThunk(
  'managementApp/projects/setProjects',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.project - the project object
   */
  async ({ wsId, project }, { dispatch, getState }) => {
    const response = await axios.put(`${ApiRoutes.projectsEndPoint(wsId) + project.id}/`, project);
    const data = await response.data;
    dispatch(
      showMessage({
        message: t('managementApp:PROJECT_UPDATED', { name: data.name }),
        variant: 'success',
      })
    );

    return data;
  }
);

/**
 * add a project
 */
export const addProject = createAsyncThunk(
  'managementApp/projects/addProjects',
  async ({ wsId, project }, { dispatch, getState }) => {
    const response = await axios.post(ApiRoutes.projectsEndPoint(wsId), project);
    const data = await response.data;

    const { entities } = getState().managementApp.projects;
    const Projects = Object.keys(entities).map((key) => entities[key]);

    dispatch(
      showMessage({
        message: t('managementApp:PROJECT_CREATED', { name: data.name }),
        variant: 'success',
      })
    );

    return [data, ...Projects];
  }
);

export const removeProject = createAsyncThunk(
  'managementApp/projects/removeProjects',
  async ({ wsId, project }, { dispatch, getState }) => {
    await axios.delete(`${ApiRoutes.projectsEndPoint(wsId) + project.id}/`);

    dispatch(
      showMessage({
        message: t('managementApp:PROJECT_DELETED', { name: project.name }),
        variant: 'success',
      })
    );

    return project.id;
  }
);

const projectsSlice = createSlice({
  name: 'managementApp/projects',
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setDeleteModalProps: (state, action) => {
      state.deleteModalProps = action.payload;
    },
    resetProjectsState: () => initialState,
  },
  extraReducers: {
    [getProjects.fulfilled]: projectsAdapter.setAll,
    [setProject.fulfilled]: projectsAdapter.setOne,
    [addProject.fulfilled]: projectsAdapter.setAll,
    [removeProject.fulfilled]: projectsAdapter.removeOne,
  },
});

export const {
  setNumOfPages,
  setIsLoading,
  setIsEditing,
  setPage,
  setDeleteModalProps,
  resetProjectsState,
} = projectsSlice.actions;

export default projectsSlice.reducer;
