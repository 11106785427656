import FuseUtils from '@fuse/utils/FuseUtils';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
// eslint-disable-next-line import/no-cycle
import { updateUser } from 'app/store/userSlice';
import axios from 'axios';
import { t } from 'i18next';
import ApiRoutes from 'src/app/services/ApiRoutes';

const usersAdapter = createEntityAdapter({});

export const selectSearchText = ({ managementApp }) => managementApp.users.searchText;

export const { selectAll: selectUsers, selectById: selectUserById } = usersAdapter.getSelectors(
  (state) => state.managementApp.users
);

const initialState = usersAdapter.getInitialState({
  isLoading: true,
  searchText: '',
  newUserDialog: {
    props: {
      open: false,
    },
  },
  removeUserDialog: {
    props: {
      open: false,
    },
    userToDelete: null,
  },
  changePasswordDialog: {
    props: {
      open: false,
    },
    userToChangePassword: null,
  },
});

/**
 * get the users from the api
 */
export const getUsers = createAsyncThunk(
  'managementApp/users/getUsers',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   */
  async ({ wsId }, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.usersEndPoint(wsId));

    const data = await response.data;

    return data;
  }
);

/**
 * get the roles of a user
 */
export const getUserRoles = createAsyncThunk(
  'managementApp/users/getUserRoles',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.userId - the user id
   */
  async ({ wsId, userId }, { dispatch, getState }) => {
    const user = getState().managementApp.users.entities[userId];
    const response = await axios.get(ApiRoutes.getUserRole(wsId, userId));

    const data = await { ...response.data, ...user };
    return data;
  }
);

/**
 * set the roles of a user
 */
export const setUserRoles = createAsyncThunk(
  'managementApp/users/setUserRoles',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.user - the user object
   */
  async ({ wsId, user }, { dispatch, getState }) => {
    const response = await axios.put(ApiRoutes.setUserRole(wsId), {
      user: user.id,
      roles: user.roles.map((rol) => rol.id),
    });

    const data = await { roles: response.data, ...user };

    dispatch(
      showMessage({
        message: t('managementApp:USER_UPDATED', { email: data.email }),
        variant: 'success',
      })
    );

    return data;
  }
);

/**
 * set the user
 * @param {object} payload
 * @param {string} payload.wsId - the workspace id
 * @param {object} payload.user - the user object
 */
export const setUser = createAsyncThunk(
  'managementApp/users/setUser',
  async ({ wsId, user }, { dispatch, getState }) => {
    const { id } = getState().user;

    const response = await axios.put(ApiRoutes.updateUser(wsId, user.id), user);
    const data = await { ...user, ...response.data };

    if (data.id === id) dispatch(updateUser(data));

    dispatch(
      showMessage({
        message: t('managementApp:USER_UPDATED', { email: data.email }),
        variant: 'success',
      })
    );
    return data;
  }
);

/**
 * add a user
 * @param {object} payload
 * @param {string} payload.wsId - the workspace id
 */
export const addUser = createAsyncThunk(
  'managementApp/users/addUsers',
  async ({ wsId, user }, { dispatch, getState }) => {
    delete user.code;
    delete user.method;
    const response = await axios.post(ApiRoutes.AddUser(wsId), user);
    const userData = await response.data.user;

    dispatch(
      showMessage({
        message: t('managementApp:USER_ADDED', { email: user.email }),
        variant: 'success',
      })
    );
    return userData;
  }
);

/**
 * invite a user
 * @param {object} payload
 * @param {string} payload.wsId - the workspace id
 */
export const inviteUser = async ({ wsId, user }) => {
  const response = await axios.post(ApiRoutes.inviteUser(wsId), user);
  const userData = await response.data;

  return userData;
};

/**
 * set the super user
 * @param {object} payload
 * @param {string} payload.wsId - the workspace id
 * @param {object} payload.user - the user object
 */
export const setSuperUser = async ({ wsId, user }) => {
  await axios.put(ApiRoutes.setSuperUser(wsId), {
    user: user.id,
    is_superuser: user.is_superuser,
  });
};

/**
 * remove a user
 */
export const removeUser = createAsyncThunk(
  'managementApp/users/removeUsers',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   */
  async ({ wsId, user }, { dispatch, getState }) => {
    await axios.put(ApiRoutes.removeUser(wsId), { user: user.id });

    // dispatch(setSelectedUser(null));

    dispatch(
      showMessage({
        message: t('managementApp:USER_REMOVED', { email: user.email }),
        variant: 'success',
      })
    );

    const userData = await user.id;

    return userData;
  }
);

export const selectFilteredUsers = createSelector(
  [selectUsers, selectSearchText],
  (users, searchText) => {
    if (searchText.length === 0) {
      return users;
    }
    const metaUsers = users.map((user) => ({
      id: user.id,
      email: user.email,
      name: user.name,
      last_name: user.last_name,
      phone: user.phone,
    }));
    return FuseUtils.filterArrayByString(metaUsers, searchText);
  }
);

export const selectGroupedFilteredUsers = createSelector([selectFilteredUsers], (users) => {
  return users
    .sort((a, b) => a.email.localeCompare(b.email, 'es', { sensitivity: 'base' }))
    .reduce((r, e) => {
      // get first letter of email of current element
      const group = e.email[0].toLowerCase();
      // if there is no property in accumulator with this letter create it
      if (!r[group]) r[group] = { group, children: [e] };
      // if there is push current element to children array for that letter
      else r[group].children.push(e);
      // return accumulator
      return r;
    }, {});
});

const usersSlice = createSlice({
  name: 'managementApp/users',
  initialState,
  reducers: {
    openUserDialog: (state, action) => {
      state.newUserDialog = {
        props: {
          open: true,
        },
      };
    },
    closeUserDialog: (state, action) => {
      state.newUserDialog = {
        props: {
          open: false,
        },
      };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUsersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
    },
    openRequestremoveUser: (state, action) => {
      state.removeUserDialog = {
        props: {
          open: true,
        },
        userToDelete: action.payload,
      };
    },
    closeRequestRemoveUser: (state, action) => {
      state.removeUserDialog = {
        props: {
          open: false,
        },
        userToDelete: null,
      };
    },
    openChangePassword: (state, action) => {
      state.changePasswordDialog = {
        props: {
          open: true,
        },
        userToChangePassword: action.payload,
      };
    },
    closeChangePassword: (state, action) => {
      state.changePasswordDialog = {
        props: {
          open: false,
        },
        userToChangePassword: null,
      };
    },
    resetUsersState: () => initialState,
  },
  extraReducers: {
    [getUsers.fulfilled]: usersAdapter.setAll,
    [getUserRoles.fulfilled]: usersAdapter.setOne,
    [setUserRoles.fulfilled]: usersAdapter.setOne,
    [setUser.fulfilled]: usersAdapter.setOne,
    [addUser.fulfilled]: usersAdapter.setOne,
    [removeUser.fulfilled]: usersAdapter.removeOne,
  },
});

export const selectSelectedUser = ({ managementApp }) => managementApp.users.selectedUserId;

export const {
  setUsersSearchText,
  setIsLoading,
  closeUserDialog,
  openUserDialog,
  openRequestremoveUser,
  closeRequestRemoveUser,
  openChangePassword,
  closeChangePassword,
  resetUsersState,
} = usersSlice.actions;

export default usersSlice.reducer;
