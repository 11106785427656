/* eslint-disable camelcase */
/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import Rollbar from 'app/services/Rollbar';

import jwtService from '../auth/services/jwtService';
import { resetUserWorkspacePermissions } from './userWorkspacePermissionsSlice';
import { workspaceReset } from './workspaceSlice';
import ApiRoutes from '../services/ApiRoutes';
import { changeLanguage } from './i18nSlice';

/** change the user data */
export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
  if (user.language) dispatch(changeLanguage(user.language));
  Rollbar.setUser(user);
  /*
    You can redirect the logged-in user to a specific route depending on his role
    */
  // if (user.workspaces.length === 1) {
  //   user.loginRedirectUrl = `ws/${user.workspaces[0].id}/dashboard/`;
  //   settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
  // }
  return user;
});

/** update the user settings */
export const updateUserSettings = createAsyncThunk(
  'user/updateSettings',
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    dispatch(updateUserData(newUser));

    return newUser;
  }
);
/** update the user Shortcuts */
export const updateUserShortcuts = createAsyncThunk(
  'user/updateShortucts',
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

/** logout the user and reset the state */
export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  // if (!user.role || user.role.length === 0) {
  //   // is guest
  //   return null;
  // }

  history.push({
    pathname: '/',
  });
  // window.location.reload();

  dispatch(setInitialSettings());
  dispatch(resetUserWorkspacePermissions());
  dispatch(workspaceReset());
  dispatch(updateUserData(null));

  return dispatch(userLoggedOut());
};

/** callaback when the login failed */
export const loginUserFailed = () => async (dispatch, getState) => {
  return dispatch(userLoggedOut());
};

/** update the user data */
export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user?.role || user?.role.length === 0) {
    // is guest
    return;
  }

  Rollbar.setUser(user);

  jwtService
    .updateUserData(user)
    .then(() => {
      dispatch(showMessage({ message: 'User data saved with api' }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

/** create a new workspace */
export const addWorkspace = createAsyncThunk(
  'user/addWorkspace',
  async (workspace, { rejectWithValue, getState }) => {
    const { user } = getState();
    try {
      const response = await axios.post(ApiRoutes.workspaceEndPoint(), workspace);
      const data = await response.data;
      return { ...user, workspaces: [data, ...user.workspaces] };
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

/** join a user to a workspace */
export const JoinWorkspace = createAsyncThunk(
  'user/JoinWorkspace',
  async (code, { dispatch, getState }) => {
    const { user } = getState();

    const response = await axios.post(ApiRoutes.confirmCodeWS(), { code });
    const data = await response.data;
    return { ...user, workspaces: [data, ...user.workspaces] };
  }
);

// this funtion is only for a specific role
export const roleChecker =
  ({ user: { role } }) =>
  (roleCheck) =>
    role === roleCheck;

const initialState = {
  role: 'guest',
  workspaces: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /** reset the user state and logout */
    userLoggedOut: (state, action) => {
      Rollbar.setUser(null);

      return initialState;
    },
    /** remove a user from a workspace */
    removeWorkspace: (state, action) => {
      const newState = state;
      newState.workspaces = state.workspaces.filter((ws) => ws.id !== action.payload);
      return newState;
    },
    /** update the user data in redux */
    updateUser: (state, { payload: { name, last_name } }) => {
      state.name = name;
      state.last_name = last_name;
      return state;
    },
  },
  extraReducers: {
    [updateUserSettings.fulfilled]: (state, action) => action.payload,
    [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
    [setUser.fulfilled]: (state, action) => action.payload,
    [addWorkspace.fulfilled]: (state, action) => action.payload,
    [JoinWorkspace.fulfilled]: (state, action) => action.payload,
  },
});

export const { userLoggedOut, removeWorkspace, updateUser } = userSlice.actions;

export const selectUser = ({ user }) => user;

// export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export default userSlice.reducer;
