/* eslint-disable camelcase */
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  addClient,
  getClientPlaces,
  selectClientById,
  selectClientData,
  setClient,
  setDeleteAllPlacesModalProps,
  setlDeletePlaceModalProps,
  setlocatePlaceModalProps,
  setRightSidebarProps,
  syncClientPlaces,
} from '../../../store/clientsSlice';

/**
 * diferent types of places
 */
const placesTypes = [
  'branch_office',
  'warehouse',
  'headquarters',
  'auxiliary_office',
  'factory',
  'store',
  'parking_lot',
  'construction_sites',
  'other',
];

/**
 * tab to show the places of the client
 * @returns {JSX.Element}
 */
function PlacesClient() {
  /**
   * @constant {object} wsId - the workspace id
   * @constant {object} clientId - the client id
   * @constant {function} checkPermission - the permission checker function
   * @constant {object} client - the client object
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {function} navigate - the navigate function from the react-router-dom
   * @constant {object} useFormContext - the useFormContext from the react-hook-form
   * @constant {boolean} editMode - if the user is in edit mode
   * @constant {boolean} IsLoading - if the component is loading
   * @constant {boolean} isValid - if the form is valid
   * @constant {object} errors - the errors of the form
   * @constant {function} t - the translation function
   */
  const { wsId, clientId } = useParams();
  const checkPermission = useSelector(permissionChecker);
  const client = useSelector((state) => selectClientById(state, clientId));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control, formState, getValues, reset, watch, setValue } = useFormContext();
  const { editMode, IsLoading } = useSelector(selectClientData).rightSiderbar;
  const { isValid, errors } = formState;
  const { t } = useTranslation('managementApp');

  /**
   * open the edit mode
   */
  const onEdit = () => {
    reset(client);

    dispatch(setRightSidebarProps({ editMode: true }));
  };

  /**
   * callback to change the value of the item and set the update flag
   */
  const onChangeItem = (value, index, onChange) => {
    onChange(value);

    const place = getValues(`places_data.${index}`);

    if (place.create) return;
    if (place.delete) return;
    if (place.update) return;

    setValue(`places_data.${index}.update`, true);
  };

  /**
   * save the client
   */
  const saveClient = async () => {
    dispatch(setRightSidebarProps({ IsLoading: true }));

    const clientData = getValues();

    await dispatch(setClient({ wsId, client: clientData })).unwrap();
    await dispatch(
      syncClientPlaces({ wsId, clientId: clientData.id, places_data: clientData.places_data })
    ).unwrap();

    dispatch(setRightSidebarProps({ IsLoading: false, editMode: false }));
  };

  /**
   * create the client
   */
  const createClient = async () => {
    const clientData = getValues();
    dispatch(setRightSidebarProps({ IsLoading: true }));

    try {
      const [{ id }] = await dispatch(addClient({ wsId, client: clientData })).unwrap();
      await dispatch(
        syncClientPlaces({ wsId, clientId: id, places_data: clientData.places_data })
      ).unwrap();
      navigate(`../${id}${window.location.search}`, { replace: true });

      dispatch(setRightSidebarProps({ IsLoading: false, editMode: false }));
    } catch (error) {
      dispatch(setRightSidebarProps({ IsLoading: false, editMode: true }));
    }
  };

  /**
   * add a new place to the client
   */
  const addPlace = () => {
    const newPlace = {
      id: uuidv4(),
      name: '',
      type: 'branch_office',
      description: '',
      address: '',
      plus_code: '',
      geofence_data: null,
      latitude: 0,
      longitude: 0,
      client: client?.id,
      create: true,
    };

    setValue(`places_data.${getValues(`places_data`).length}`, newPlace, { shouldValidate: true });
  };

  /**
   * open the delete confirmation modal
   * @param {number} placeIndex - the index of the place to delete
   */
  const openDeletePlaceModal = (placeIndex) =>
    dispatch(setlDeletePlaceModalProps({ open: true, placeIndex }));

  /**
   * open a modal to locate the place
   * @param {number} placeIndex - the index of the place to locate
   */
  const openLocatePlaceModal = (placeIndex) =>
    dispatch(setlocatePlaceModalProps({ open: true, placeIndex }));

  /**
   * change the location mode of the client
   */
  const onChangeLocationMode = () => {
    if (client?.location_mode === 'through-client-places') {
      dispatch(setDeleteAllPlacesModalProps({ open: true }));
      return 'through-client-places';
    }

    dispatch(setRightSidebarProps({ tab: 'map' }));
    const { latitude, longitude } = getValues();
    if (latitude === 0 && longitude === 0) return '';

    return 'through-client-coordinate';
  };

  /**
   * change the tab to map
   * @param {object} e - the event
   * @param {string} newTab - the new tab
   */
  const onChangeTabMap = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'map' }));
    setValue('location_mode', 'through-client-coordinate');
  };

  /**
   * change the tab to places
   * @param {object} e - the event
   * @param {string} newTab - the new tab
   */
  const onChangeTabPlaces = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'places' }));
    setValue('location_mode', 'through-client-places');
  };

  /**
   * change the tab to unknown
   * @param {object} e - the event
   * @param {string} newTab - the new tab
   */
  const onChangeTabUnknown = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'no_location' }));
    setValue('location_mode', '');
  };

  useEffect(() => {
    if (clientId !== 'new' && !client.places_data) {
      const getPlacesClient = async () => {
        dispatch(setRightSidebarProps({ IsLoading: true }));

        const { payload } = await dispatch(getClientPlaces({ wsId, clientId }));
        if (editMode) setValue('places_data', payload.changes.places_data);

        dispatch(setRightSidebarProps({ IsLoading: false }));
      };
      getPlacesClient();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (IsLoading || (!client?.places_data && clientId !== 'new')) {
    return (
      <div className="h-full flex-1 flex items-center">
        <FuseLoading />
      </div>
    );
  }

  if (
    (editMode && watch('places_data')?.length === 0) ||
    (!editMode && client?.places_data?.length === 0)
  )
    return (
      <div className="h-full flex-1 flex flex-col justify-center items-center">
        {editMode && (
          <div>
            <ButtonGroup disableElevation color="secondary" size="small">
              <Button
                variant="outlined"
                className="gap-5 flex justify-start"
                onClick={onChangeTabUnknown}
              >
                <FuseSvgIcon size={18}>material-solid:location_off</FuseSvgIcon>
                <Typography className="text-12">{t('UNKNOWN')}</Typography>
              </Button>

              <Button
                variant="outlined"
                className="gap-5 flex justify-start"
                onClick={onChangeTabMap}
              >
                <FuseSvgIcon size={18}>material-solid:location_on</FuseSvgIcon>
                <Typography className="text-12">{t('SINGLE_LOCATION')}</Typography>
              </Button>

              <Button
                variant="contained"
                className="gap-5 flex justify-start"
                onClick={onChangeTabPlaces}
              >
                <FuseSvgIcon size={18}>material-solid:location_city</FuseSvgIcon>
                <Typography className="text-12">{t('MANY_LOCATION')}</Typography>
              </Button>
            </ButtonGroup>
          </div>
        )}

        <div className="flex flex-1 flex-col justify-around px-20">
          {/* {editMode && (
              <Controller
                name="location_mode"
                control={control}
                render={({ field: { onChange, value, onBlur, ref } }) => (
                  <FormControl>
                    <FormControlLabel
                      label={t('CLIENT_MORE_ONE_LOCATION')}
                      control={
                        <Checkbox
                          checked={value === 'through-client-places'}
                          onBlur={onBlur}
                          onChange={(ev) => onChange(onChangeLocationMode())}
                          inputRef={ref}
                        />
                      }
                    />
                    <FormHelperText>{t('IF_CLIENT_SINGLE_LOCATION')}</FormHelperText>
                  </FormControl>
                )}
              />
            )} */}

          <div className="flex flex-col items-center">
            <Typography className="text-24 md:text-32  tracking-tight text-grey-400 font-800">
              {t('THERE_NO_PLACES')}
            </Typography>
            <FuseSvgIcon className="text-48" size={50} color="secondary">
              material-solid:sentiment_dissatisfied
            </FuseSvgIcon>
          </div>
          {editMode && (
            <Button color="secondary" variant="contained" size="small" onClick={addPlace}>
              {t('ADD_NEW_PLACE')}
            </Button>
          )}
        </div>
      </div>
    );

  if (editMode) {
    return (
      <div className="flex flex-col flex-1 h-full w-full">
        <div className="overflow-scroll flex-auto h-0  p-12">
          <div className="py-5">
            <ButtonGroup disableElevation color="secondary" size="small">
              <Button
                variant="outlined"
                className="gap-5 flex justify-start"
                onClick={onChangeTabUnknown}
              >
                <FuseSvgIcon size={18}>material-solid:location_off</FuseSvgIcon>
                <Typography className="text-12">{t('UNKNOWN')}</Typography>
              </Button>

              <Button
                variant="outlined"
                className="gap-5 flex justify-start"
                onClick={onChangeTabMap}
              >
                <FuseSvgIcon size={18}>material-solid:location_on</FuseSvgIcon>
                <Typography className="text-12">{t('SINGLE_LOCATION')}</Typography>
              </Button>

              <Button
                variant="contained"
                className="gap-5 flex justify-start"
                onClick={onChangeTabPlaces}
              >
                <FuseSvgIcon size={18}>material-solid:location_city</FuseSvgIcon>
                <Typography className="text-12">{t('MANY_LOCATION')}</Typography>
              </Button>
            </ButtonGroup>
          </div>

          {/* <Controller
            name="location_mode"
            control={control}
            render={({ field: { onChange, value, onBlur, ref } }) => (
              <FormControl>
                <FormControlLabel
                  label={t('CLIENT_MORE_ONE_LOCATION')}
                  control={
                    <Checkbox
                      checked={value === 'through-client-places'}
                      onBlur={onBlur}
                      onChange={(ev) => onChange(onChangeLocationMode())}
                      inputRef={ref}
                    />
                  }
                />
                <FormHelperText>{t('IF_CLIENT_SINGLE_LOCATION')}</FormHelperText>
              </FormControl>
            )}
          /> */}
          {watch('places_data').map((place, index) => {
            if (place.delete) return null;
            return (
              <div key={place.id}>
                <div
                  className="rounded overflow-hidden shadow-lg p-4 pb-6"
                  style={{ backgroundColor: '#f1f5f9' }}
                >
                  <div className="">
                    <div className="flex w-full">
                      <div className="px-4 py-6  flex-1">
                        <Controller
                          name={`places_data.${index}.name`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              onChange={(e) => onChangeItem(e.target.value, index, field.onChange)}
                              label={t('NAME')}
                              disabled={!checkPermission('edit_client')}
                              error={!!errors?.places_data?.[index]?.name}
                              helperText={errors?.places_data?.[index]?.name?.message}
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                      <div className="px-4 py-6  ">
                        <Controller
                          name={`places_data.${index}.type`}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <Select
                                {...field}
                                onChange={(e) =>
                                  onChangeItem(e.target.value, index, field.onChange)
                                }
                                variant="outlined"
                                fullWidth
                                size="small"
                              >
                                {placesTypes.map((placeType) => (
                                  <MenuItem key={placeType} value={placeType} className="gap-5">
                                    <div className="flex gap-5 items-center">
                                      <img
                                        src={`assets/images/places/${placeType}.png`}
                                        alt=""
                                        height={20}
                                        width={20}
                                      />
                                      {t(placeType.toUpperCase())}
                                    </div>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="px-4 py-6  flex-1">
                      <Controller
                        name={`places_data.${index}.description`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            onChange={(e) => onChangeItem(e.target.value, index, field.onChange)}
                            label={t('DESCRIPTION')}
                            variant="outlined"
                            disabled={!checkPermission('edit_client')}
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </div>
                    <div className="px-4 py-6  flex-1">
                      <Controller
                        name={`places_data.${index}.address`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            onChange={(e) => onChangeItem(e.target.value, index, field.onChange)}
                            label={t('ADDRESS')}
                            variant="outlined"
                            disabled={!checkPermission('edit_client')}
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </div>
                    <div className="flex items-center w-full gap-5">
                      <div className="px-4 py-6  flex-1">
                        <Controller
                          name={`places_data.${index}.plus_code`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              onChange={(e) => onChangeItem(e.target.value, index, field.onChange)}
                              label={t('PLUS_CODE')}
                              variant="outlined"
                              disabled
                              fullWidth
                              size="small"
                            />
                          )}
                        />
                      </div>
                      <Tooltip title={t('LOCATION')}>
                        <Fab
                          onClick={() => openLocatePlaceModal(index)}
                          size="small"
                          color="secondary"
                          aria-label="location"
                          disabled={!!errors.places_data?.[index]?.name}
                        >
                          <Badge
                            badgeContent="!"
                            color="error"
                            invisible={
                              !!errors.places_data?.[index]?.name ||
                              (!errors.places_data?.[index]?.latitude &&
                                !errors.places_data?.[index]?.longitude)
                            }
                          >
                            <FuseSvgIcon className="text-white" size={20}>
                              material-solid:edit_location_alt
                            </FuseSvgIcon>
                          </Badge>
                        </Fab>
                      </Tooltip>
                      <Tooltip title={t('DELETE')}>
                        <Fab
                          onClick={() => openDeletePlaceModal(index)}
                          size="small"
                          color="error"
                          aria-label="delete"
                        >
                          <FuseSvgIcon className="text-white" size={20}>
                            material-solid:delete
                          </FuseSvgIcon>
                        </Fab>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="w-full py-16 px-8">
                  <Divider />
                </div>
              </div>
            );
          })}
          {checkPermission('edit_client') && (
            <div className="flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                disabled={!isValid}
                size="small"
                onClick={addPlace}
              >
                {t('ADD_NEW_PLACE')}
              </Button>
            </div>
          )}
        </div>
        <div className="flex  w-full py-8">
          {clientId === 'new' && checkPermission('create_client') ? (
            <Button
              className="flex-auto col-span-2"
              color="secondary"
              variant="contained"
              disabled={!isValid}
              size="small"
              onClick={createClient}
            >
              {t('CREATE')}
            </Button>
          ) : (
            <>
              {checkPermission('edit_client') && (
                <Button
                  className="flex-auto"
                  color="secondary"
                  variant="contained"
                  disabled={!isValid}
                  size="small"
                  onClick={saveClient}
                >
                  {t('SAVE')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-scroll flex-auto h-0  p-12">
      <div className="flex justify-end ">
        {!editMode && checkPermission('edit_client') && (
          <Fab
            className="absolute bottom-10"
            onClick={onEdit}
            size="small"
            color="secondary"
            aria-label="add"
          >
            <FuseSvgIcon className="text-white" size={20}>
              heroicons-solid:pencil
            </FuseSvgIcon>
          </Fab>
        )}
      </div>
      {client.places_data.map((place, index) => (
        <div
          key={place.id}
          className="rounded overflow-hidden shadow-lg p-4 pb-6 my-5"
          style={{ backgroundColor: '#f1f5f9' }}
        >
          <div className="flex justify-center items-center gap-5">
            <Typography className="text-center font-semibold" color="InfoText" variant="body2">
              {place.name}
            </Typography>
            <img
              className="justify-self-end"
              src={`assets/images/places/${place.type}.png`}
              alt=""
              height={20}
              width={20}
            />
          </div>

          <div className="flex flex-col ">
            <Typography color="InfoText" variant="caption">
              <b>{t('DESCRIPTION')}</b>:{place.description}
            </Typography>
            <Typography color="InfoText" variant="caption">
              <b>{t('ADDRESS')}</b>:{place.address}
            </Typography>

            <div className="flex justify-between w-full">
              <Typography color="InfoText" variant="caption">
                <b>{t('PLUS_CODE')}</b>:{place.plus_code}
              </Typography>
              <IconButton size="small" onClick={() => openLocatePlaceModal(index)}>
                <FuseSvgIcon>material-solid:location_on</FuseSvgIcon>
              </IconButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PlacesClient;
