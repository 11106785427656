/* eslint-disable camelcase */

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { setRightSidebarProps } from '../../../store/clientsSlice';

/**
 * tab to show the location info
 * @returns {JSX.Element}
 */
function LocationInfo() {
  /**
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {function} setValue - the setValue function from the react-hook-form
   * @constant {function} t - the translation function
   */
  const dispatch = useDispatch();
  const { setValue } = useFormContext();
  const { t } = useTranslation('managementApp');

  /**
   * change the tab to map
   * @param {object} e - the event
   * @param {string} newTab - the new tab
   */
  const onChangeTabMap = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'map' }));
    setValue('location_mode', 'through-client-coordinate');
  };

  /**
   * change the tab to places
   * @param {object} e - the event
   * @param {string} newTab - the new tab
   */
  const onChangeTabPlaces = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'places' }));
    setValue('location_mode', 'through-client-places');
  };

  /**
   * change the tab to unknown
   * @param {object} e - the event
   * @param {string} newTab - the new tab
   */
  const onChangeTabUnknown = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'no_location' }));
    setValue('location_mode', '');
  };

  return (
    <div className="flex flex-col flex-1 w-full h-full">
      <div>
        <div className="flex justify-center py-5">
          <ButtonGroup disableElevation color="secondary" size="small">
            <Button
              variant="outlined"
              className="gap-5 flex justify-start"
              onClick={onChangeTabUnknown}
            >
              <FuseSvgIcon size={18}>material-solid:location_off</FuseSvgIcon>
              <Typography className="text-12">{t('UNKNOWN')}</Typography>
            </Button>

            <Button
              variant="outlined"
              className="gap-5 flex justify-start"
              onClick={onChangeTabMap}
            >
              <FuseSvgIcon size={18}>material-solid:location_on</FuseSvgIcon>
              <Typography className="text-12">{t('SINGLE_LOCATION')}</Typography>
            </Button>

            <Button
              variant="outlined"
              className="gap-5 flex justify-start"
              onClick={onChangeTabPlaces}
            >
              <FuseSvgIcon size={18}>material-solid:location_city</FuseSvgIcon>
              <Typography className="text-12">{t('MANY_LOCATION')}</Typography>
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className=" my-8 mx-4">
        <Typography className=" text-grey-800 text-16 font-600">
          {t('LOCATION_INFO_TITLE')}
        </Typography>
        <div className="px-8">
          <div className="py-6">
            <Typography className=" text-grey-800 text-12">
              <b>{t('UNKNOWN')}</b> : {t('UNKNOWN_INFO')}
            </Typography>
          </div>
          <div className="py-6">
            <Typography className=" text-grey-800 text-12">
              <b>{t('SINGLE_LOCATION')}</b> : {t('SINGLE_LOCATION_INFO')}
            </Typography>
          </div>
          <div className="py-6">
            <Typography className=" text-grey-800 text-12">
              <b>{t('MANY_LOCATION')}</b> : {t('MANY_LOCATION_INFO')}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationInfo;
