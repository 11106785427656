import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { t } from 'i18next';

const tagsAdapter = createEntityAdapter({});

export const { selectAll: selectTags, selectById: selectTagById } = tagsAdapter.getSelectors(
  (state) => state.managementApp.tags
);

const initialState = tagsAdapter.getInitialState({
  numOfPages: 0,
  page: 1,
  isLoading: true,
  deleteModalProps: {
    open: false,
    tagId: null,
  },
});

/**
 * get tags from the api
 */
export const getTags = createAsyncThunk(
  'managementApp/tags/getTags',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.paramsApi - the params to send to the api
   */
  async ({ wsId, paramsApi }, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.tagsEndPoint(wsId, paramsApi));
    dispatch(setNumOfPages(Math.ceil(response.data.count / 100 || 0)));
    const data = await response.data.results;

    return data;
  }
);

/**
 * set a tag
 */
export const setTag = createAsyncThunk(
  'managementApp/tags/setTags',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.tag - the tag object
   */
  async ({ wsId, tag }, { dispatch, getState }) => {
    try {
      const response = await axios.put(`${ApiRoutes.tagsEndPoint(wsId) + tag.id}/`, tag);
      const data = await response.data;
      dispatch(
        showMessage({
          message: t('managementApp:TAG_UPDATED', { name: data.name }),
          variant: 'success',
        })
      );
      return data;
    } catch ({ response }) {
      if (response.status === 400) {
        dispatch(
          showMessage({
            message: t('managementApp:TAG_NAME_REPEATED', { name: tag.name }),
            variant: 'warning',
          })
        );
      }
      throw isRejectedWithValue('error');
    }
  }
);

/**
 * add a tag
 */
export const addTag = createAsyncThunk(
  'managementApp/tags/addTags',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.tag - the tag object
   */
  async ({ wsId, tag }, { dispatch, getState }) => {
    const { entities } = getState().managementApp.tags;
    const isRepeated = Object.entries(entities).some(([key, e]) => e.name === tag.name.trim());

    if (!isRepeated) {
      const response = await axios.post(ApiRoutes.tagsEndPoint(wsId), tag);
      const data = await response.data;

      const Tags = Object.keys(entities).map((key) => entities[key]);

      dispatch(
        showMessage({
          message: t('managementApp:TAG_CREATED', { name: data.name }),
          variant: 'success',
        })
      );

      return [data, ...Tags];
    }
    dispatch(
      showMessage({
        message: t('managementApp:TAG_NAME_REPEATED', { name: tag.name }),
        variant: 'warning',
      })
    );
    throw isRejectedWithValue('error');
  }
);

/**
 * remove a tag
 */
export const removeTag = createAsyncThunk(
  'managementApp/tags/removeTags',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.tag - the tag object
   */
  async ({ wsId, tag }, { dispatch, getState }) => {
    await axios.delete(`${ApiRoutes.tagsEndPoint(wsId) + tag.id}/`);

    dispatch(
      showMessage({
        message: t('managementApp:TAG_DELETED', { name: tag.name }),
        variant: 'success',
      })
    );

    return tag.id;
  }
);

const tagsSlice = createSlice({
  name: 'managementApp/tags',
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDeleteModalProps: (state, action) => {
      state.deleteModalProps = action.payload;
    },
    resetTagsState: () => initialState,
  },
  extraReducers: {
    [getTags.fulfilled]: tagsAdapter.setAll,
    [setTag.fulfilled]: tagsAdapter.setOne,
    [addTag.fulfilled]: tagsAdapter.setAll,
    [removeTag.fulfilled]: tagsAdapter.removeOne,
  },
});

export const selectSelectedTag = ({ managementApp }) => managementApp.tags.selectedTagId;

export const { setNumOfPages, setIsLoading, setDeleteModalProps, resetTagsState } =
  tagsSlice.actions;

export default tagsSlice.reducer;
