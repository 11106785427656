/** this file contains the translations for the AssignmentsApp module in english */
const locale = {
  ACCEPT: 'Accept',
  ACCURACY: 'Accuracy',
  ACTIVE_STATUS: 'Active',
  ADD_FIELD: 'Add field',
  ADDRESS: 'Address',
  ALL: 'Any',
  ANY_ClIENT: 'Any client',
  APPLIED_FILTERS: 'Applied Filters',
  APPLY_FILTERS: 'Apply filters',
  ARE_SURE_DELETE_FORM: 'Are you sure to delete the form "{{name}}"?',
  ARE_SURE_DUPLICATE_ASSIGNMENT: 'Are you sure to duplicate this task ({{title}})?',
  ARE_SURE_DUPLICATE_FORM: 'Are you sure to duplicate this form ({{name}})?',
  ARE_SURE_REMOVE_ASSIGNMENT: 'Are you sure to delete this task ({{title}})?',
  ARE_SURE_REMOVE_FILTERS: 'Are you sure to remove all filters?',
  ASSIGN_TO: 'Assign to',
  ASSIGNED_STATUS: 'Assigned',
  ASSIGNMENT_DONE_DUE_DATE: 'The task was done outside the due date',
  ASSIGNMENT_NOT_FOUND: '404 task not found',
  ASSIGNMENTS: 'Tasks',
  BARCODE_DEPS:
    'A button will be displayed and pressing it will open the camera with special functionality to detect QR codes and barcodes. The result will be displayed in a text.',
  BARCODE_TITLE: 'Scanner for barcodes or QR codes',
  BOOKMARK: 'Marked',
  CANCEL: 'Cancel',
  CANCEL_STATUS: 'Cancelled',
  CHECK_BY_DEFAULT: 'Checked by default',
  CLIENT: 'Client',
  CLIENTS: 'Clients',
  CLOSE: 'Close',
  CONDITIONALLY_SHOW_FIELD: 'Conditionally show',
  CONDITIONING_FIELD: 'Conditioning Field',
  CONDITIONING_VALUE: 'Conditioning Value',
  CREATE_ASSIGNMENT: 'Create task',
  CREATED_BY: 'Created By',
  CREATION_DATE: 'Creation date',
  DATE: 'Date',
  DATE_CREATED: 'Date created',
  DATE_DEPS: 'The user will be shown a calendar where they can choose a date as a response.',
  DATE_DUE: 'Due date',
  DATE_FIELD_VALUE: '{{dayWeek}}, {{month}} {{dayMonth}}, {{year}}',
  DATE_STATUS: 'State change date',
  DATE_TITLE: 'Date',
  DATES: 'Dates',
  DEFAULT_VALUE: 'Default Value',
  DELETE: 'Delete',
  DELETE_ASSIGNMENT: 'Delete task',
  DELETE_FIELD: 'Delete field',
  DELETE_FIELD_FORM_DESP:
    'Are you sure you want to delete the field "{{titleField}}" from the form?',
  DELETE_FIELD_FORM_TITLE: 'Delete form field',
  DELETE_FORM: 'Delete form',
  DESCRIPTION: 'Description',
  DETAILS: 'Details',
  DONE_STATUS: 'Done',
  DUPLICATE_ASSIGNMENT: 'Duplicate Assignment',
  DUPLICATE_FORM: 'Duplicate form',
  DURATION_BETWEEN_STATUS: 'Time between {{beforeStatus}} to {{afterStatus}}',
  EDIT: 'Edit',
  EDIT_ASSIGNMENT: 'Edit task',
  EDIT_FIELD: 'Edit field',
  EDIT_FORM: 'Edit form',
  EDITION_DATE: 'Edition date',
  ERROR_LOADING_IMAGE: 'Error loading image',
  FAILED_STATUS: 'Failed',
  FIELD_REQUIRED: 'This field are required',
  FIELD_TYPE: 'Field type',
  FIELDS: 'Fields',
  FILTER_BY: 'Filter by',
  FILTER_BY_DATE_CREATED: 'Filter by creation date',
  FORM_DESC:
    'Use the forms to group the most common questions. You can use these forms when creating assingments.',
  FORM_MAXIMUM_FIELD: 'The form has reached the maximum number of fields allowed',
  FORMS: 'Forms',
  FROM_DATE: 'From',
  GENERATE_PDF: 'Generate PDF',
  GPS_DEPS:
    "The user will be shown a button, which when pressed will activate the device's GPS function, the result will be a pair of geographic coordinates of the user's current location.",
  GPS_TITLE: 'Location (GPS)',
  INFORMATION: 'Information',
  INVALID_VALUE: 'Invalid value',
  INVOICE_NAME: 'Name to use in the invoice',
  INVOICE_TAX: 'Tax code',
  JUSTIFICATION: 'Justification',
  KEYBOARD_TYPE: 'Keyboard type',
  LAST_EDITION: 'Last edition',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  MAXIMUM_NUMBER_CHARACTERS: 'Maximum number of characters',
  MOVE_DOWN_OPTION: 'Move Option Down',
  MOVE_FIELD: 'Move field',
  MOVE_UP_OPTION: 'Move Option Up',
  MULTI_TEXT_DEPS:
    'The user will be able to write several separate answers to the same question. For example to enter a list of products of interest to the customer.',
  MULTI_TEXT_TITLE: 'Text with multiple answers',
  NAME: 'Name',
  NAME_CANNOT_EMPTY: 'The name cannot be empty',
  NEW_FIELD_DESP: 'Select the type of field you need',
  NEW_FIELD_TITLE: 'New field',
  NEW_FORM: 'New Form',
  NO: 'No',
  NO_BARCODE_YET: 'A code has not been captured yet',
  NO_CLIENTS_SHOW: 'There are no customers to show',
  NO_CONDITIONING_FIELD:
    'There are no conditioning fields, Add Selection type or yes/no type fields to be able to indicate the rules to display this Field',
  NO_DATE_YET: 'The date has not been taken yet',
  NO_FORMS_SHOW: 'No forms to show',
  NO_LOCATION_YET: 'No location yet',
  NO_OPTION: 'No options',
  NO_PHOTO_YET: 'Photo has not been taken yet',
  NO_RESPONSE: 'Without Response',
  NO_SIGNATURE_YET: 'Not signed yet',
  NO_TASKS_TO_SHOW: 'No tasks to show',
  NUMERICAL: 'Numeric',
  OBSERVATION: 'Observation',
  ONCHANGE_FILTERS_MESSEAGE: 'When you\'re ready press "Apply Filters"',
  OPEN_CAMERA: 'Open camera',
  OPTION_TEXT_REQUIRED: 'Option text is required',
  OPTIONAL: 'Optional',
  OPTIONALY_WRITE_DETAILS_SECTION:
    'Optionally, you can enter more details here about what you want to show users.',
  OPTIONS_FIELD_SELECTED:
    'This field will be displayed when the "{{field_title}}" field has the "{{field_value}}" option selected',
  OTHER_DATA: 'Other data',
  PAGINATION_LABEL: '{{from}} - {{to}} of {{count}}',
  PHOTO_DEPS:
    'The user will be asked to take a photo with the cameras available on the device. You are not allowed to choose a photo from the gallery, only the ones you take at that moment.',
  PHOTO_TITLE: 'Photo',
  PLACE: 'Place',
  PLUS_CODE: 'Plus code',
  PREVIEW: 'Preview',
  PREVIEW_FIELD: 'Field preview in the App',
  PRINT: 'Print',
  PRINT_TICKET_DEPS:
    'This functionality allows you to send the indicated text to a portable Bluetooth printer.',
  PRINT_TICKET_TITLE: 'Printer',
  PROJECT: 'Project',
  REASING_USER: 'Reassign user',
  REFRESH: 'Refresh',
  REMOVE: 'Remove',
  REMOVE_DATES: 'Remove Dates',
  REMOVE_FIELD: 'Remove Field',
  REMOVE_FILTERS: 'Remove filters',
  REMOVE_OPTION: 'Remove Option',
  REQUIRED: 'Required',
  SAVE_ASSINGMENT: 'Save Task',
  SAVE_FORM: 'Save form',
  SCAN: 'Scan',
  SEARCH: 'Search',
  SEARCH_ASSIGNMENTS_BY: 'Search in title, description and observation',
  SEARCH_CLIENT_BY: 'Search by name, internal code, address, tax code or contact',
  SEARCH_FORM: 'Search form',
  SECTION_DEPS:
    'This field simply allows you to indicate text that will be displayed below a line to indicate the separation of a section of the form. The user does not interact with the field, it only serves to notice a separation or to read instructions to follow.',
  SECTION_TITLE: 'Separator',
  SEE_APPLIED_FILTERS: 'See applied filters',
  SELECT_DATE: 'Select Date',
  SELECT_DEPS:
    'The user will be shown a list of options from which they can only choose one as an answer.',
  SELECT_MULTIPLE_DEPS:
    'The user will be shown a list of options from which they can choose several answers.',
  SELECT_MULTIPLE_TITLE: 'Multiple Selection',
  SELECT_TITLE: 'Selection',
  SELECT_USER: 'Select a user',
  SELECTED_CLIENT: 'Selected Client',
  SELECTED_USER: 'Selected User',
  SELLER: 'Seller',
  SHORTID: 'Short ID',
  SIGNATURE_DEPS:
    'A blank canvas will be displayed on the device screen where you can draw a signature using a finger.',
  SIGNATURE_TITLE: 'Digital signature',
  STATE_CHANGES: 'State changes',
  STATUS: 'State',
  TAGS: 'Tags',
  TAKE_MY_LOCATION: 'Take my location',
  TEAM: 'Team',
  TEAMS: 'Teams',
  TELEPHONIC: 'Telephonic',
  TERNARY_DEPS:
    'With this field you can display a question whose answer is simply Yes or No. If the field is mandatory then the user must press one of the buttons, if it is not mandatory then the user can continue without answering.',
  TERNARY_FIELD_NOT: 'This field will be displayed when the field "{{field_title}}" is Affirmative',
  TERNARY_FIELD_NULL:
    'This field will be displayed when the field "{{field_title}}" is not answered',
  TERNARY_FIELD_YES: 'This field will be displayed when the field "{{field_title}}" is Negative',
  TERNARY_TITLE: 'Yes/No',
  TEXT_DEPS:
    'The user will be able to write a response to what is requested in the title, three types of keyboard can be shown: numeric, telephone and any text.',
  TEXT_TITLE: 'Text',
  TEXT_TO_PRINT: 'Text to print',
  TEXTUAL: 'Textual',
  TITLE: 'Title',
  TITLE_CANNOT_EMPTY: 'Title cannot be empty',
  TITLE_REQUIRED: 'A task must have a title',
  TO_DATE: 'To',
  UNASSIGNED: 'Unassigned',
  UPLOAD_BY_CSV: 'Load by CSV',
  USER: 'User',
  USER_IN_CHARGE: 'User in charge',
  VALUE: 'Value',
  WRITE_HERE_THE_OPTION: 'Write the option title here',
  WRITE_TEXT_TO_PRINT: 'Write the text here that you want to send to the printer',
  YES: 'Yes',
};

export default locale;
