/* eslint-disable camelcase */
import ApiRoutes from 'src/app/services/ApiRoutes';
import axios from 'axios';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';
import { upsertAlert } from 'app/store/selectsData/alertsSlices';

import { setGeofenceFiguresStatus, setPosition } from './mapDataSlice';

const geofencesAdapter = createEntityAdapter({});

export const { selectAll: selectGeofences, selectById: selectGeofence } =
  geofencesAdapter.getSelectors((state) => state.LocationApp.Geofences);

export const selectGeofenceData = ({ LocationApp }) => LocationApp.Geofences;

const initialState = geofencesAdapter.getInitialState({
  numOfPages: 0,
  page: 0,
  loading: false,
});

/**
 * return a figure with the correct format
 * @param {object} e - the geofence object
 * @param {boolean} showFigure - if the figure is visible
 * @param {boolean} showMarker - if the marker is visible
 * @param {boolean} reverseCoords - invert the lat and lng
 */
const figureSerializer = (e, showFigure = false, showMarker = false, reverseCoords = false) => {
  const figure = { ...e, showFigure, showMarker, typeMarker: 'Geofence', polygonCenter: [0, 0] };

  if (e.geometry.type === 'Polygon' && e.geometry.coordinates[0]) {
    if (reverseCoords)
      figure.geometry.coordinates = [e.geometry.coordinates[0].map(([lng, lat]) => [lat, lng])];
    const xMax = Math.max(...e.geometry.coordinates[0].map((coords) => coords[0]));
    const xMin = Math.min(...e.geometry.coordinates[0].map((coords) => coords[0]));
    const yMax = Math.max(...e.geometry.coordinates[0].map((coords) => coords[1]));
    const yMin = Math.min(...e.geometry.coordinates[0].map((coords) => coords[1]));
    const x = (xMax + xMin) / 2;
    const y = (yMax + yMin) / 2;
    figure.polygonCenter = [x, y];
    if (!figure.alerts) figure.alerts = null;
    figure.haveToUpdateAlerts = false;
  }

  return figure;
};

/**
 * get the list of geofences to show in the map
 */
export const getGeofences = createAsyncThunk(
  'LocationApp/MapData/geofences/getGeofences',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   * @param {object} payload.params - the params to filter the geofences
   */
  async ({ ws, params }, { dispatch, getState }) => {
    const { page } = getState().LocationApp.Geofences;
    if (page === params.page) return [];

    const { data } = await axios.get(ApiRoutes.geofenceEndPoint(ws, params, 2));

    const geofences = data.results.features.map((geofence) =>
      figureSerializer(geofence, false, true, true)
    );

    if (geofences.length === 0)
      dispatch(showMessage({ message: t('locationApp:NO_GEOFENCES_FOUND'), variant: 'warning' }));

    dispatch(setNumOfPages(Math.ceil(data.count / 100 || 0)));

    return geofences;
  }
);

/**
 * get the alerts active in the geofence
 */
export const getGeofenceAlerts = createAsyncThunk(
  'LocationApp/MapData/geofences/getGeofenceAlerts',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.geofenceId - the geofence id to get the alerts
   */
  async ({ wsId, geofenceId }, { dispatch, getState }) => {
    const geofence = getState().LocationApp.Geofences.entities[geofenceId];

    const { data: alerts_data } = await axios.get(ApiRoutes.getGeofenceAlerts(wsId, geofenceId));
    return { ...geofence, alerts_data, alerts: alerts_data.map((alert) => alert.id) };
  }
);

/**
 * get the report of inputs and outputs of the geofence
 */
export const getGeofenceReport = createAsyncThunk(
  'LocationApp/MapData/geofences/getGeofenceReport',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.geofenceId - the geofence id to get the report
   */
  async ({ wsId, geofenceId }, { dispatch, getState }) => {
    const geofence = getState().LocationApp.Geofences.entities[geofenceId];
    const {
      data: { results: records_data },
    } = await axios.get(ApiRoutes.geofenceRecordEndPoint(wsId, { geofence: geofenceId }));
    return { ...geofence, records_data, records: records_data.map((record) => record.id) };
  }
);

/**
 * add an alert to the geofence
 */
export const setGeofenceAlerts = createAsyncThunk(
  'LocationApp/MapData/geofences/setGeofenceAlerts',
  async ({ alert, geofence }, { dispatch, getState }) => {
    return { ...geofence, alerts: [alert] };
  }
);

/**
 * search the geofences by a string
 */
export const searchGeofences = createAsyncThunk(
  'LocationApp/MapData/geofences/searchGeofences',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   * @param {string} payload.search - the string to search
   */
  async ({ ws, search }, { dispatch, getState }) => {
    const { data } = await axios.get(ApiRoutes.searchGeofence(ws, search));
    const geofences = data.features.map((geofence) =>
      figureSerializer(geofence, false, true, true)
    );

    if (geofences.length === 0) {
      dispatch(showMessage({ message: t('locationApp:NO_GEOFENCES_FOUND'), variant: 'warning' }));
    } else {
      dispatch(setPosition({ coords: geofences[0].polygonCenter, zoom: 15, setPosition: true }));
    }

    return geofences;
  }
);

/**
 * upsert a list of geofences
 */
export const upsertGeofences = createAsyncThunk(
  'LocationApp/MapData/geofences/upsertGeofences',
  /**
   * @param {array} geofences - the list of geofences to upsert
   */
  async (geofences, { dispatch, getState }) =>
    geofences.map((geofence) => figureSerializer(geofence, false, true, true))
);

/**
 *  create a new geofence
 */
export const createGeofence = createAsyncThunk(
  'LocationApp/MapData/geofences/createGeofence',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   * @param {object} payload.geofence - the geofence to create
   */
  async ({ ws, geofence }, { dispatch, getState }) => {
    const newGeofence = geofence;
    await geofence.alerts_data.forEach(async (alert) => {
      if (alert.isNew) await axios.post(`${ApiRoutes.alertsEndPoint(ws, null)}`, alert);
      if (alert.isUpdate)
        await axios.put(`${ApiRoutes.alertsEndPoint(ws, null)}${alert.id}/`, alert);
    });
    const { data } = await axios.post(ApiRoutes.createGeofence(ws), newGeofence);

    return figureSerializer(
      { ...data, alerts_data: geofence.alerts_data, alerts: geofence.alerts },
      false,
      true,
      true
    );
  }
);

/**
 * update a geofence
 */
export const updateGeofence = createAsyncThunk(
  'LocationApp/MapData/geofences/updateGeofence',
  /**
   * @param {object} payload
   * @param {string} payload.ws - the workspace id
   * @param {object} payload.geofence - the geofence to update
   */
  async ({ ws, geofence }, { dispatch, getState }) => {
    await dispatch(logicalDeleteGeofence(geofence.id));

    await geofence.alerts_data.forEach(async (alert) => {
      if (alert.isNew) {
        const { data } = await axios.post(`${ApiRoutes.alertsEndPoint(ws, null)}`, alert);
        dispatch(upsertAlert(data));
      }
      if (alert.isUpdate) {
        const { data } = await axios.put(
          `${ApiRoutes.alertsEndPoint(ws, null)}${alert.id}/`,
          alert
        );
        dispatch(upsertAlert(data));
      }
    });

    const { data } = await axios.put(ApiRoutes.updateGeofence(ws, geofence.id), geofence);

    return figureSerializer(
      { ...data, alerts_data: geofence.alerts_data, alerts: geofence.alerts },
      false,
      true,
      true
    );
  }
);

/**
 * add a temporary geofence to the map to create a new one
 */
export const addTemporaryGeofence = createAsyncThunk(
  'LocationApp/MapData/geofences/addTemporaryGeofence',
  /**
   * @param {object} payload
   * @param {object} payload.geofence - the geofence to add
   * @param {string} payload.status - the status of the editor of figures
   */
  async ({ geofence, status }, { dispatch, getState }) => {
    await dispatch(removeTemporaryGeofence());
    await dispatch(setGeofenceFiguresStatus(status));
    return figureSerializer({ ...geofence, id: 'temp' }, true, true, false);
  }
);

/**
 * remove the temporary geofence from the map after create it or cancel the action
 */
export const removeTemporaryGeofence = createAsyncThunk(
  'LocationApp/MapData/geofences/removeTemporaryGeofence',
  async (params, { dispatch, getState }) => {
    dispatch(setGeofenceFiguresStatus(null));
    dispatch(logicalDeleteGeofence('temp'));
  }
);

/**
 * delete a geofence from the map (logical delete)
 */
export const deleteGeofence = createAsyncThunk(
  'LocationApp/MapData/Geofence/deleteGeofence',
  /**
   * @param {string} id - the id of the geofence to delete
   */
  async (id, { dispatch, getState }) => id
);

/**
 * remove a geofence from the map (physical delete)
 */
export const removeGeofence = createAsyncThunk(
  'LocationApp/MapData/Geofence/removeGeofence',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.geofenceId - the geofence id to delete
   */
  async ({ wsId, geofenceId }, { dispatch, getState }) => {
    await axios.delete(`${ApiRoutes.geofenceEndPoint(wsId) + geofenceId}/`);
    dispatch(logicalDeleteGeofence(geofenceId));

    return null;
  }
);

const geofencesSlice = createSlice({
  name: 'LocationApp/geofences',
  initialState,
  reducers: {
    setVisibilityGeofence: (state, action) => {
      const { id, visible } = action.payload;
      if (visible === undefined) state.entities[id].showFigure = !state.entities[id].showFigure;
      else state.entities[id].showFigure = visible;
    },
    setVisibilityMarkerGeofence: (state, action) => {
      const { id, visible } = action.payload;
      if (visible === undefined) state.entities[id].showMarker = !state.entities[id].showMarker;
      else state.entities[id].showMarker = visible;
    },
    setAnimationMarkerGeofence: (state, action) => {
      const { id, animation } = action.payload;
      if (animation === undefined) state.entities[id].animation = !state.entities[id].animation;
      else state.entities[id].animation = animation;
    },
    resetGeofenceState: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    logicalDeleteGeofence: (state, action) => {
      state.entities[action.payload].delete = true;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: {
    [getGeofences.fulfilled]: geofencesAdapter.upsertMany,
    [searchGeofences.fulfilled]: geofencesAdapter.upsertMany,
    [upsertGeofences.fulfilled]: geofencesAdapter.upsertMany,
    [createGeofence.fulfilled]: geofencesAdapter.addOne,
    [updateGeofence.fulfilled]: geofencesAdapter.setOne,
    [deleteGeofence.fulfilled]: geofencesAdapter.removeOne,
    [addTemporaryGeofence.fulfilled]: geofencesAdapter.upsertOne,
    [getGeofenceAlerts.fulfilled]: geofencesAdapter.upsertOne,
    [setGeofenceAlerts.fulfilled]: geofencesAdapter.upsertOne,
    [getGeofenceReport.fulfilled]: geofencesAdapter.upsertOne,
  },
});

export const {
  setVisibilityGeofence,
  setVisibilityMarkerGeofence,
  resetGeofenceState,
  setLoading,
  setNumOfPages,
  logicalDeleteGeofence,
  setPage,
  setAnimationMarkerGeofence,
} = geofencesSlice.actions;

export default geofencesSlice.reducer;
