import i18next from 'i18next';
import { lazy } from 'react';

import en from '../i18n/en';
import es from '../i18n/es';

const LogIn = lazy(() => import('./LoginInPage'));

i18next.addResourceBundle('en', 'authApp', en);
i18next.addResourceBundle('es', 'authApp', es);

const logInConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  roleRequired: ['guest'],
  routes: [
    {
      path: 'login',
      element: <LogIn />,
    },
  ],
};

export default logInConfig;
