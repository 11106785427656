/* eslint-disable camelcase */
import ApiRoutes from 'src/app/services/ApiRoutes';
import axios from 'axios';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const eventsAdapter = createEntityAdapter({});

export const { selectAll: selectEvents, selectById: selectEventById } = eventsAdapter.getSelectors(
  ({ managementApp }) => managementApp.events
);

export const selectEventData = ({ managementApp }) => managementApp.events;

const initialState = eventsAdapter.getInitialState({
  numOfPages: 0,
  count: 0,
  page: 1,
  isLoading: true,
  FiltersDialogProps: {
    open: false,
  },
});

/**
 * get events from the server
 */
export const getEvents = createAsyncThunk(
  'managementApp/events/getEvents',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.params - the api params
   */
  async ({ wsId, params }, { dispatch, getState }) => {
    dispatch(setIsLoading(true));

    const response = await axios.get(ApiRoutes.eventsEndPoint(wsId, params));

    dispatch(setIsLoading(false));
    dispatch(setPage(params.page));

    dispatch(setNumOfPages(Math.ceil(response.data.count / 100 || 0)));
    dispatch(setCount(response.data.count));

    const data = await response.data.results;

    return data;
  }
);

/**
 * get an event
 */
export const getEvent = createAsyncThunk(
  'managementApp/event/getEvent',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.id - the event id
   */
  async ({ wsId, id }, { getState, dispatch }) => {
    const { entities } = getState().managementApp.event;

    if (entities[id]) return entities[id];

    const { data } = await axios.get(ApiRoutes.eventsEndPoint(wsId, null) + id);

    return data;
  }
);

/**
 * set an event
 */
export const setEvent = createAsyncThunk(
  'managementApp/events/setEvent',
  /**
   * @param {object} data - the event data
   */
  async (data) => {
    return { id: data.id, changes: data };
  }
);

const eventsSlice = createSlice({
  name: 'managementApp/events',
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    openFiltersDialog: (state, action) => {
      state.FiltersDialogProps = {
        open: true,
      };
    },
    closeFiltersDialog: (state, action) => {
      state.FiltersDialogProps = {
        open: false,
      };
    },
    resetEventsState: () => initialState,
  },
  extraReducers: {
    [getEvents.fulfilled]: eventsAdapter.setAll,
    [setEvent.fulfilled]: eventsAdapter.updateOne,
    [getEvent.fulfilled]: eventsAdapter.upsertOne,
  },
});

export const selectSelectedEvent = ({ managementApp }) => managementApp.events.selectedEventId;

export const {
  setNumOfPages,
  setIsLoading,
  setPage,
  resetEventsState,
  setCount,
  openFiltersDialog,
  closeFiltersDialog,
} = eventsSlice.actions;

export default eventsSlice.reducer;
