import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

/** clients list to be used by select forms */

const clientsAdapter = createEntityAdapter({});

export const selectClientSelector = (state) => state.selectsData.clients;

export const { selectAll: selectClients, selectById: selectClientById } =
  clientsAdapter.getSelectors(selectClientSelector);

const initialState = clientsAdapter.getInitialState({
  isClientsLoading: true,
  count: 0,
  page: 1,
  selectedClient: null,
  seller: null,
});

/**
 * get clients in paginated form
 */
export const getClientsPaginated = createAsyncThunk(
  'selectsData/clients/getClientsPaginated',
  /**
   * @param {Object} param0
   * @param {string} param0.wsId - the id of workspace.
   * @param {number} param0.page - the num of page.
   * @param {string} param0.search - the search string.
   */
  async ({ wsId, page, search }, { dispatch }) => {
    await dispatch(setIsClientsLoading(true));

    const {
      data: { results, count },
    } = await axios.get(ApiRoutes.clientsEndPoint(wsId, { page, search }));

    await dispatch(setClientProps({ page, count }));
    await dispatch(setIsClientsLoading(false));

    return results;
  }
);

/**
 * get a list of clients per user or seller(not paginated)
 */
export const getClientsPerUsers = createAsyncThunk(
  'selectsData/clients/getClientsPerUsers',
  /**
   * @param {Object} param0
   * @param {string} param0.wsId - the id of workspace.
   * @param {string} param0.seller - the seller or user id.
   */
  async ({ wsId, seller }, { dispatch }) => {
    await dispatch(setIsClientsLoading(true));

    const { data } = await axios.get(ApiRoutes.clientsEndPoint(wsId, { seller }));

    await dispatch(setClientProps({ seller }));

    await dispatch(setIsClientsLoading(false));

    return data;
  }
);

/**
 * get a client by id
 */
export const getClient = createAsyncThunk(
  'selectsData/clients/getClient',
  /**
   * @param {Object} param0
   * @param {string} param0.wsId - the id of workspace.
   * @param {string} param0.clientId - the client id.
   */
  async ({ wsId, clientId }, { dispatch, getState }) => {
    await dispatch(setIsClientsLoading(true));
    const { selectedClient } = getState().selectsData.clients;
    if (selectedClient?.id === clientId || !clientId) {
      await dispatch(setIsClientsLoading(false));
      return;
    }

    await dispatch(setIsClientsLoading(true));

    const { data } = await axios.get(`${ApiRoutes.clientsEndPoint(wsId, {}) + clientId}/`);
    dispatch(setClientSelected(data));

    await dispatch(setIsClientsLoading(false));
  }
);

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    /** set the loading state of the clients list */
    setIsClientsLoading: (state, action) => {
      state.isClientsLoading = action.payload;
    },
    /** set the clients list props */
    setClientProps: (state, action) => {
      const { page = 1, count = 0, seller = null } = action.payload;
      state.page = page;
      state.count = count;
      state.seller = seller;
    },
    /** set the selected `clients` */
    setClientSelected: (state, action) => {
      state.selectedClient = action.payload;
    },
    /** reset the `clients` state */
    resetClientState: () => initialState,
  },
  extraReducers: {
    [getClientsPaginated.fulfilled]: clientsAdapter.setAll,
    [getClientsPerUsers.fulfilled]: clientsAdapter.setAll,
  },
});

export const { setIsClientsLoading, resetClientState, setClientProps, setClientSelected } =
  clientSlice.actions;

export default clientSlice.reducer;
